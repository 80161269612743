// React & Next
import { useMemo } from 'react';

// 3rd
import { Flex, Stack, Textarea, useToken } from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';

// App - Types
import { RequirementCountermeasureSnippetTypes } from '@/types/security-framework/requirement';
import type { RequirementCountermeasure } from '../../../types/requirement-countermeasure';

// App - Other
import Locale from '@/locale/en.json';
import { InfoIcon } from '@/components/atoms/icon';
import { Field, Input, Select } from '@/components/molecules/form';
import { Button } from '@/components/molecules/button';
import { Tooltip } from '@/components/molecules/tooltips';
import { translateRequirementCountermeasureSnippetType } from '@/components/translators/security-framework/requirement';

const locale = Locale.features.frameworks['frameworks-requirement-countermeasure-form'];

type RequirementCountermeasureFormProps = {
  onSubmit: (countermeasure: RequirementCountermeasure) => void;
  isSubmitting?: boolean;
  didError?: boolean;
};

export const RequirementCountermeasureForm = ({
  onSubmit,
  isSubmitting,
  didError,
}: RequirementCountermeasureFormProps) => {
  const [defaultOutlineColor] = useToken('colors', ['border.primary']);
  const { register, handleSubmit, formState, control } = useForm<RequirementCountermeasure>();

  const snippetTypesOptions = useMemo(
    () =>
      RequirementCountermeasureSnippetTypes.filter((type) => type !== 'UNKNOWN').flatMap((type) => {
        const translatedType = translateRequirementCountermeasureSnippetType(type);

        if (translatedType) {
          return [
            {
              value: type,
              label: translatedType,
            },
          ];
        }

        return [];
      }),
    []
  );

  return (
    <Stack
      as="form"
      direction="column"
      alignItems="flex-start"
      gap="none"
      position="relative"
      width="100%"
      height="fit-content"
      outline={`1px solid ${defaultOutlineColor}`}
      borderRadius="sm"
      transition="150ms all ease-in-out"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Flex
        alignItems="center"
        gap="md"
        py="8px"
        ps="12px"
        pe="12px"
        w="100%"
        borderBottom="1px"
        borderColor="border.primary"
      >
        <Field htmlFor="title" error={formState.errors['title']?.message}>
          <Input
            variant="ghost"
            size="old-sm"
            bg="surface.secondary"
            placeholder={locale['Approved pattern title']}
            {...register('title', {
              required: 'Required',
            })}
            isDisabled={isSubmitting}
            sx={{
              _placeholder: {
                color: 'text.mid-tone',
              },
            }}
          />
        </Field>

        <Controller
          control={control}
          name="snippetType"
          rules={{ required: 'Required' }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <Field htmlFor="snippetType" error={error?.message} w="fit-content">
              <Select
                id={name}
                useBasicStyles
                variant="old.outline"
                size="sm"
                options={snippetTypesOptions}
                value={snippetTypesOptions.find(({ value: type }) => type === value)}
                selectedOptionColor="surface.brand.primary"
                isDisabled={isSubmitting}
                onChange={(newValue) => {
                  onChange(newValue?.value);
                }}
                onBlur={onBlur}
                ref={ref}
                chakraStyles={{
                  container: (styles) => ({
                    ...styles,
                    maxW: '250px',
                    minW: '115px',
                    w: 'fit-content',
                  }),
                  menuList: (styles) => ({
                    ...styles,
                    maxW: '250px',
                    minW: '115px',
                    w: '100%',
                  }),
                }}
              />
            </Field>
          )}
        />

        <Flex alignItems="center" gap="md">
          {didError ? (
            <Tooltip content={locale['Something isn’t right, let’s try again.']}>
              <InfoIcon size="xs" color="text.error" aria-label={locale['Error info tip']} />
            </Tooltip>
          ) : null}

          <Button
            variant="old.solid"
            size="sm"
            isLoading={isSubmitting}
            type="submit"
            aria-label={locale['Create']}
          >
            {locale['Create']}
          </Button>
        </Flex>
      </Flex>

      <Flex py="md" px="md" w="100%">
        <Field htmlFor="content" error={formState.errors['content']?.message}>
          <Textarea
            variant="ghost"
            size="sm"
            bg="surface.secondary"
            rows={4}
            resize="vertical"
            isDisabled={isSubmitting}
            placeholder={
              locale[
                'We will reference this snippet whenever a violation of this requirement will be detected'
              ]
            }
            {...register('content', {
              required: 'Required',
            })}
          />
        </Field>
      </Flex>
    </Stack>
  );
};
