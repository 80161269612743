// App - Types
import type { SecurityControlImportanceLevel } from '../security-control-importance-level';

export const SecurityControlImportanceLevelsDto = ['High', 'Medium', 'Low', 'UNKNOWN'] as const;

export type SecurityControlImportanceLevelDto = (typeof SecurityControlImportanceLevelsDto)[number];

export const castSecurityControlImportanceLevelDtoToSecurityControlImportanceLevel = (
  dto: SecurityControlImportanceLevelDto
): SecurityControlImportanceLevel => {
  if (dto === 'High') return 'high';

  if (dto === 'Medium') return 'medium';

  if (dto === 'Low') return 'low';

  return 'UNKNOWN';
};

export const castSecurityControlImportanceLevelToSecurityControlImportanceLevelDto = (
  importanceLevel: SecurityControlImportanceLevel
): SecurityControlImportanceLevelDto => {
  if (importanceLevel === 'high') return 'High';

  if (importanceLevel === 'medium') return 'Medium';

  if (importanceLevel === 'low') return 'Low';

  return 'UNKNOWN';
};
