// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const CancelOutlineIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 256 256" {...props} ref={ref}>
      <path
        d="M165.66,101.66,139.31,128l26.35,26.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const CancelOutlineIconMetadata = {
  name: 'Cancel Outline',
  importName: 'CancelOutlineIcon',
  component: <CancelOutlineIcon aria-label="Cancel Outline Icon" />,
  tags: 'cancel, exit, close, cancelled, dismissed, round, system, circle, remove, delete, clear, x',
};
