// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const LockIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 12 12" {...props} ref={ref} fill="none">
      <path
        d="M9.75 3.75H8.25V2.625C8.25 2.02826 8.01295 1.45597 7.59099 1.03401C7.16903 0.612053 6.59674 0.375 6 0.375C5.40326 0.375 4.83097 0.612053 4.40901 1.03401C3.98705 1.45597 3.75 2.02826 3.75 2.625V3.75H2.25C2.05109 3.75 1.86032 3.82902 1.71967 3.96967C1.57902 4.11032 1.5 4.30109 1.5 4.5V9.75C1.5 9.94891 1.57902 10.1397 1.71967 10.2803C1.86032 10.421 2.05109 10.5 2.25 10.5H9.75C9.94891 10.5 10.1397 10.421 10.2803 10.2803C10.421 10.1397 10.5 9.94891 10.5 9.75V4.5C10.5 4.30109 10.421 4.11032 10.2803 3.96967C10.1397 3.82902 9.94891 3.75 9.75 3.75ZM6.375 7.43578V8.625C6.375 8.72446 6.33549 8.81984 6.26517 8.89017C6.19484 8.96049 6.09946 9 6 9C5.90054 9 5.80516 8.96049 5.73484 8.89017C5.66451 8.81984 5.625 8.72446 5.625 8.625V7.43578C5.37479 7.34732 5.16392 7.17326 5.02964 6.94435C4.89536 6.71545 4.84632 6.44644 4.8912 6.18488C4.93608 5.92332 5.07198 5.68605 5.27488 5.51499C5.47778 5.34394 5.73462 5.25012 6 5.25012C6.26538 5.25012 6.52222 5.34394 6.72512 5.51499C6.92802 5.68605 7.06392 5.92332 7.1088 6.18488C7.15367 6.44644 7.10464 6.71545 6.97036 6.94435C6.83608 7.17326 6.62521 7.34732 6.375 7.43578ZM7.5 3.75H4.5V2.625C4.5 2.22718 4.65804 1.84564 4.93934 1.56434C5.22064 1.28304 5.60218 1.125 6 1.125C6.39782 1.125 6.77936 1.28304 7.06066 1.56434C7.34196 1.84564 7.5 2.22718 7.5 2.625V3.75Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const LockIconMetadata = {
  name: 'Lock',
  importName: 'LockIcon',
  component: <LockIcon aria-label="Lock Icon" />,
  tags: 'lock, not-accessible',
};
