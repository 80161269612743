// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const MondayIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 -50 256 256" {...props} ref={ref} fill="none">
      <g>
        <path
          d="M31.8458633,153.488694 C20.3244423,153.513586 9.68073708,147.337265 3.98575204,137.321731 C-1.62714067,127.367831 -1.29055839,115.129325 4.86093879,105.498969 L62.2342919,15.4033556 C68.2125882,5.54538256 79.032489,-0.333585033 90.5563073,0.0146553508 C102.071737,0.290611552 112.546041,6.74705604 117.96667,16.9106216 C123.315033,27.0238906 122.646488,39.1914174 116.240607,48.6847625 L58.9037201,138.780375 C52.9943022,147.988884 42.7873202,153.537154 31.8458633,153.488694 L31.8458633,153.488694 Z"
          fill="#F62B54"
        />

        <path
          d="M130.25575,153.488484 C118.683837,153.488484 108.035731,147.301291 102.444261,137.358197 C96.8438154,127.431292 97.1804475,115.223704 103.319447,105.620522 L160.583402,15.7315506 C166.47539,5.73210989 177.327374,-0.284878136 188.929728,0.0146553508 C200.598885,0.269918151 211.174058,6.7973526 216.522421,17.0078646 C221.834319,27.2183766 221.056375,39.4588356 214.456008,48.9278699 L157.204209,138.816842 C151.313487,147.985468 141.153618,153.5168 130.25575,153.488484 Z"
          fill="#FFCC00"
        />

        <ellipse fill="#00CA72" cx="226.465527" cy="125.324379" rx="29.5375538" ry="28.9176274" />
      </g>
    </Icon>
  );
});

export const MondayIconMetadata = {
  name: 'Monday Logo',
  importName: 'MondayIcon',
  component: <MondayIcon aria-label="Monday Icon" />,
  tags: 'monday, logo, design, mockup',
};
