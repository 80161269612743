// App - Types
import type { EntityType } from '../entity-type';

export const EntityTypesDto = [
  'AzureDevOpsPullRequest',
  'AzureDevOpsWikiPage',
  'AzureDevOpsWorkItem',
  'BitbucketPullRequest',
  'ConfluencePage',
  'GithubIssue',
  'GithubPullRequest',
  'GoogleDrive',
  'JiraIssue',
  'NotionPage',
  'SharePoint',
  'TrelloCard',
  'ServiceNowTask',
  'UNKNOWN',
] as const;

export type EntityTypeDto = (typeof EntityTypesDto)[number];

export const castEntityTypeDtoToEntityType = (dto: EntityTypeDto): EntityType => {
  if (dto === 'AzureDevOpsPullRequest') return 'azure_devops_pull_request';

  if (dto === 'AzureDevOpsWikiPage') return 'azure_devops_wiki_page';

  if (dto === 'AzureDevOpsWorkItem') return 'azure_devops_work_item';

  if (dto === 'BitbucketPullRequest') return 'bitbucket_pull_request';

  if (dto === 'ConfluencePage') return 'confluence_page';

  if (dto === 'GithubIssue') return 'github_issue';

  if (dto === 'GithubPullRequest') return 'github_pull_request';

  if (dto === 'GoogleDrive') return 'google_drive';

  if (dto === 'JiraIssue') return 'jira_issue';

  if (dto === 'NotionPage') return 'notion_page';

  if (dto === 'SharePoint') return 'share_point';

  if (dto === 'TrelloCard') return 'trello_card';

  if (dto === 'ServiceNowTask') return 'service_now_task';

  return 'UNKNOWN';
};

export const castEntityTypeToEntityTypeDto = (type: EntityType): EntityTypeDto => {
  if (type === 'azure_devops_pull_request') return 'AzureDevOpsPullRequest';

  if (type === 'azure_devops_wiki_page') return 'AzureDevOpsWikiPage';

  if (type === 'azure_devops_work_item') return 'AzureDevOpsWorkItem';

  if (type === 'bitbucket_pull_request') return 'BitbucketPullRequest';

  if (type === 'confluence_page') return 'ConfluencePage';

  if (type === 'github_issue') return 'GithubIssue';

  if (type === 'github_pull_request') return 'GithubPullRequest';

  if (type === 'google_drive') return 'GoogleDrive';

  if (type === 'jira_issue') return 'JiraIssue';

  if (type === 'notion_page') return 'NotionPage';

  if (type === 'share_point') return 'SharePoint';

  if (type === 'trello_card') return 'TrelloCard';

  if (type === 'service_now_task') return 'ServiceNowTask';

  return 'UNKNOWN';
};
