// 3rd
import { z } from 'zod';

// App - Types
import { ZodEntityIdDto, castEntityIdToEntityIdDto } from '@/types/entity/dtos';
import { isAllowedToSendCommentFeedbackForSource } from '../../../types/feedback';
import type { SendMissingInformationCommentFeedback } from '../../../types/missing-information-feedback';

export const ZodSendMissingInformationCommentFeedbackDto = z.object({
  missingInformationIds: z.string().array(),
  securityStoryId: z.string(),
  entityId: ZodEntityIdDto,
});

export type SendMissingInformationCommentFeedbackDto = z.infer<
  typeof ZodSendMissingInformationCommentFeedbackDto
>;

export const castSendMissingInformationCommentFeedbackToSendMissingInformationCommentFeedbackDto = (
  storyId: string,
  payload: SendMissingInformationCommentFeedback
): SendMissingInformationCommentFeedbackDto => {
  if (!isAllowedToSendCommentFeedbackForSource(payload.recipientEntityId.source))
    throw new Error(
      `Feedback creation is not allowed for this source => ${payload.recipientEntityId.source}.`
    );

  return {
    entityId: castEntityIdToEntityIdDto(payload.recipientEntityId),
    securityStoryId: storyId,
    missingInformationIds: payload.missingInformationIds || [],
  };
};
