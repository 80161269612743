// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IllustrationProps } from './illustration';

// App - Other
import Locale from '@/locale/en.json';
import { Illustration } from './illustration';

const locale = Locale.components.atoms.icon.illustrations;

export const ParagraphFeedbackIllustration = forwardRef((props: IllustrationProps, ref) => {
  return (
    <Illustration
      viewBox="0 0 60 54"
      {...props}
      aria-label={props['aria-label'] || locale['Paragraph Feedback Illustration']}
      ref={ref}
      fill="none"
    >
      <rect x="0.5" y="0.5" width="59" height="53" rx="3.5" fill="white" />

      <rect x="0.5" y="0.5" width="59" height="53" rx="3.5" stroke="#E2E8F0" />

      <rect x="4" y="4" width="42" height="12" rx="1" fill="#E2E8F0" fillOpacity="0.7" />

      <rect x="4.5" y="18.5" width="51" height="15" rx="0.5" fill="#F2FCFA" stroke="#21A9AB" />

      <rect x="4" y="36" width="48" height="6" rx="1" fill="#E2E8F0" fillOpacity="0.7" />

      <rect x="4" y="44" width="52" height="6" rx="1" fill="#E2E8F0" fillOpacity="0.7" />
    </Illustration>
  );
});

export const ParagraphFeedbackIllustrationMetadata = {
  name: 'Paragraph Feedback',
  importName: 'ParagraphFeedbackIllustration',
  component: (
    <ParagraphFeedbackIllustration
      w="156px"
      h="86px"
      aria-label="Paragraph Feedback Illustration"
    />
  ),
  tags: 'feedback, paragraph',
};
