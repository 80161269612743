// App - Types
import type { KnowledgeItemSecurityLevel } from '../knowledge-item-security-level';

export const KnowledgeItemSecurityLevelsDto = ['Severe', 'Moderate', 'Mild', 'All'] as const;

export type KnowledgeItemSecurityLevelDto = (typeof KnowledgeItemSecurityLevelsDto)[number];

export const castKnowledgeItemSecurityLevelDtoToKnowledgeItemSecurityLevel = (
  dto: KnowledgeItemSecurityLevelDto
): KnowledgeItemSecurityLevel => {
  if (dto === 'Severe') return 'severe';

  if (dto === 'Moderate') return 'moderate';

  if (dto === 'Mild') return 'mild';

  return 'UNKNOWN';
};

export const castKnowledgeItemSecurityLevelToKnowledgeItemSecurityLevelDto = (
  securityLevel: KnowledgeItemSecurityLevel
): KnowledgeItemSecurityLevelDto => {
  if (securityLevel === 'severe') return 'Severe';

  if (securityLevel === 'moderate') return 'Moderate';

  if (securityLevel === 'mild') return 'Mild';

  return 'All';
};

export const castScoreToKnowledgeItemSecurityLevelDto = (
  score: number
): KnowledgeItemSecurityLevelDto => {
  if (!score || score === -1) return 'All';

  if (score <= 3) return 'Mild';

  if (score <= 6) return 'Moderate';

  return 'Severe';
};

export const castScoreToKnowledgeItemSecurityLevel = (
  score: number
): KnowledgeItemSecurityLevel => {
  if (!score || score === -1) return 'UNKNOWN';

  if (score <= 3) return 'mild';

  if (score <= 6) return 'moderate';

  return 'severe';
};
