// App - Types
import type { RequirementSeverity } from '@/types/security-framework/requirement';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators['security-framework'].severity;

export const translateRequirementSeverity = (severity: RequirementSeverity): string => {
  if (severity === 'high') return locale['High'];

  if (severity === 'medium') return locale['Medium'];

  if (severity === 'low') return locale['Low'];

  return locale['Unknown'];
};
