// App - Types
import type { KnowledgeItemSignificanceLevel } from '../knowledge-item-significance-level';

export const KnowledgeItemSignificanceLevelsDto = ['Major', 'Notable', 'Minor', 'All'] as const;

export type KnowledgeItemSignificanceLevelDto = (typeof KnowledgeItemSignificanceLevelsDto)[number];

export const castKnowledgeItemSignificanceLevelDtoToKnowledgeItemSignificanceLevel = (
  dto: KnowledgeItemSignificanceLevelDto
): KnowledgeItemSignificanceLevel => {
  if (dto === 'Major') return 'major';

  if (dto === 'Notable') return 'notable';

  if (dto === 'Minor') return 'minor';

  return 'UNKNOWN';
};

export const castKnowledgeItemSignificanceLevelDtoToScore = (
  dto: KnowledgeItemSignificanceLevelDto
): number => {
  if (dto === 'Minor') return 3;

  if (dto === 'Notable') return 6;

  if (dto === 'Major') return 10;

  return -1;
};

export const castKnowledgeItemSignificanceLevelToKnowledgeItemSignificanceLevelDto = (
  significanceLevel: KnowledgeItemSignificanceLevel
): KnowledgeItemSignificanceLevelDto => {
  if (significanceLevel === 'major') return 'Major';

  if (significanceLevel === 'notable') return 'Notable';

  if (significanceLevel === 'minor') return 'Minor';

  return 'All';
};

export const castScoreToKnowledgeItemSignificanceLevelDto = (
  score: number
): KnowledgeItemSignificanceLevelDto => {
  if (!score || score === -1) return 'All';

  if (score <= 3) return 'Minor';

  if (score <= 6) return 'Notable';

  return 'Major';
};

export const castScoreToKnowledgeItemSignificanceLevel = (
  score: number
): KnowledgeItemSignificanceLevel => {
  if (!score || score === -1) return 'UNKNOWN';

  if (score <= 3) return 'minor';

  if (score <= 6) return 'notable';

  return 'major';
};
