// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const PencilOutlineIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref} fill="none">
      <path
        d="M14.2069 4.58561L11.4144 1.79249C11.3215 1.6996 11.2113 1.62592 11.0899 1.57565C10.9686 1.52539 10.8385 1.49951 10.7072 1.49951C10.5759 1.49951 10.4458 1.52539 10.3245 1.57565C10.2031 1.62592 10.0929 1.6996 10 1.79249L2.29313 9.49999C2.19987 9.59251 2.12593 9.70265 2.0756 9.824C2.02528 9.94535 1.99959 10.0755 2.00001 10.2069V13C2.00001 13.2652 2.10536 13.5196 2.2929 13.7071C2.48043 13.8946 2.73479 14 3.00001 14H5.79313C5.9245 14.0004 6.05464 13.9747 6.17599 13.9244C6.29735 13.8741 6.40748 13.8001 6.50001 13.7069L14.2069 5.99999C14.2998 5.90712 14.3734 5.79687 14.4237 5.67553C14.474 5.55419 14.4999 5.42414 14.4999 5.2928C14.4999 5.16146 14.474 5.0314 14.4237 4.91006C14.3734 4.78872 14.2998 4.67847 14.2069 4.58561ZM5.79313 13H3.00001V10.2069L8.50001 4.70686L11.2931 7.49999L5.79313 13ZM12 6.79249L9.20688 3.99999L10.7069 2.49999L13.5 5.29249L12 6.79249Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const PencilOutlineIconMetadata = {
  name: 'Pencil Outline',
  importName: 'PencilOutlineIcon',
  component: <PencilOutlineIcon aria-label="Pencil Outline Icon" />,
  tags: 'edit, pencil, outline, write, create, update, change, modify',
};
