// App - Types
import type { RequirementAntiPatternType } from '../requirement-anti-pattern-type';

export const RequirementAntiPatternTypesDto = [
  'Architecture',
  'Code',
  'Configuration',
  'Documentation',
  'UNKNOWN',
] as const;

export type RequirementAntiPatternTypeDto = (typeof RequirementAntiPatternTypesDto)[number];

export const castRequirementAntiPatternTypeDtoToRequirementAntiPatternType = (
  dto: RequirementAntiPatternTypeDto
): RequirementAntiPatternType => {
  if (dto === 'Architecture') return 'architecture';

  if (dto === 'Code') return 'code';

  if (dto === 'Configuration') return 'configuration';

  if (dto === 'Documentation') return 'documentation';

  return 'UNKNOWN';
};

export const castRequirementAntiPatternTypeToRequirementAntiPatternTypeDto = (
  type: RequirementAntiPatternType
): RequirementAntiPatternTypeDto => {
  if (type === 'architecture') return 'Architecture';

  if (type === 'code') return 'Code';

  if (type === 'configuration') return 'Configuration';

  if (type === 'documentation') return 'Documentation';

  return 'UNKNOWN';
};
