// 3rd
import { Skeleton } from '@chakra-ui/react';
import type { StyleProps } from '@chakra-ui/react';

// App - Types
import type { Requirement } from '../../../types/requirement';

// App - Other
import { Text } from '@/components/atoms/typography';
import { Tag } from '@/components/molecules/tag';
import {
  translateRequirementSeverity,
  translateRequirementSeverityToColor,
} from '@/components/translators/security-framework/requirement';

type RequirementSeverityTagProps = StyleProps & {
  requirement: Requirement;
};

export const RequirementSeverityTag = ({
  requirement,
  ...styleProps
}: RequirementSeverityTagProps) => {
  const title = translateRequirementSeverity(requirement.severity);
  const bgColor = translateRequirementSeverityToColor(requirement.severity);

  return (
    <Tag {...styleProps} size="old-md" maxW="fit-content" color="text.invert-primary" bg={bgColor}>
      <Text variant="detail-semibold">{title}</Text>
    </Tag>
  );
};

type LoadingProps = StyleProps;

const Loading = ({ ...styleProps }: LoadingProps) => {
  return <Skeleton {...styleProps} h="22px" w="44px" borderRadius="4px" />;
};

RequirementSeverityTag.Loading = Loading;
