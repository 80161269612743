// App - Types
import type { SecurityFrameworkSource } from '@/types/security-framework';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators['security-framework']['security-framework-source'];

export const translateSecurityFrameworkSource = (source: SecurityFrameworkSource): string => {
  if (source === 'azure') return locale['Azure'];

  if (source === 'fed_ramp') return locale['Fed Ramp'];

  if (source === 'owasp') return locale['OWASP'];

  if (source === 'nist') return locale['NIST'];

  if (source === 'hitrust') return locale['HITRUST'];

  if (source === 'aws') return locale['AWS'];

  if (source === 'clover') return locale['Clover'];

  if (source === 'pci') return locale['PCI'];

  if (source === 'cis') return locale['CIS'];

  if (source === 'custom') return locale['Custom'];

  return locale['Custom'];
};
