// App - Types
import type { RequirementCountermeasureSnippetType } from '@/types/security-framework/requirement';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators['security-framework']['countermeasure-snippet-type'];

export const translateRequirementCountermeasureSnippetType = (
  snippetType: RequirementCountermeasureSnippetType
): string => {
  if (snippetType === 'architecture') return locale['Architecture'];

  if (snippetType === 'code') return locale['Code'];

  if (snippetType === 'configuration') return locale['Configuration'];

  if (snippetType === 'documentation') return locale['Documentation'];

  if (snippetType === 'other') return locale['Other'];

  return locale['Unknown'];
};
