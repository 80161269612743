// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { ZodEntityIdDto, castEntityIdToEntityIdDto } from '@/types/entity/dtos';
import type { EntityId } from '@/types/entity';
import type { CreateSecurityWorkItemFeedback } from '../../types/security-feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  content: z.string(),
  knowledgeSourceConfigurationId: z.string(),
  projectName: z.string(),
  title: z.string(),
  relatedKnowledgeItem: ZodEntityIdDto.optional(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// #######
// Request
// #######

export const createSecurityWorkItemFeedback = async (
  model: CreateSecurityWorkItemFeedback,
  seedKnowledgeItemId?: EntityId
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      content: model.content,
      knowledgeSourceConfigurationId: model.recipientIntegrationConfigurationId,
      projectName: model.projectName,
      title: model.title,
      ...(seedKnowledgeItemId !== undefined
        ? { relatedKnowledgeItem: castEntityIdToEntityIdDto(seedKnowledgeItemId) }
        : {}),
    });

    return await apiClient.post(`/AzureDevOps/CreateWorkItem`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type MutationPayload = {
  seedKnowledgeItemId?: EntityId;
  ticketCreationPayload: CreateSecurityWorkItemFeedback;
};

type UseCreateSecurityWorkItemFeedback = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useCreateSecurityWorkItemFeedback = ({
  onStart,
  onSuccess,
  onError,
}: UseCreateSecurityWorkItemFeedback = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (payload: MutationPayload) =>
      await createSecurityWorkItemFeedback(
        payload.ticketCreationPayload,
        payload.seedKnowledgeItemId
      ),
    onMutate: () => onStart?.(),
    onSuccess: () => onSuccess?.(),
    onError: (error) => onError?.(error),
  });

  return {
    createSecurityWorkItem: mutate,
    isCreatingSecurityWorkItem: isPending,
    didCreateSecurityWorkItemErrored: isError,
  };
};
