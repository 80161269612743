// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SecurityFramework } from '../../types/security-framework';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityRequirementId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodDeleteRequirementRequestPayloadDto };
export type { RequestPayloadDto as DeleteRequirementRequestPayloadDto };

// #######
// Request
// #######

export const deleteRequirement = async (requirementId: string): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      securityRequirementId: requirementId,
    });

    return await apiClient.post('/SecurityFramework/DeleteSecurityRequirement', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseDeleteRequirement = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useDeleteRequirement = ({
  onStart,
  onSuccess,
  onError,
}: UseDeleteRequirement = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (requirementId: string) => await deleteRequirement(requirementId),
    onMutate: async () => {
      onStart?.();
    },
    onSuccess: async (data, model) => {
      await queryClient.cancelQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      });

      const previousFrameworks = queryClient.getQueryData(
        SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks() || []
      ) as SecurityFramework[];
      const newFrameworks = previousFrameworks.flatMap((framework) => {
        const updatedControl = framework.controls.map((control) => {
          const updatedRequirements = control.requirements.filter(
            (requirement) => requirement.id !== model
          );

          return {
            ...control,
            requirements: updatedRequirements,
          };
        });

        return {
          ...framework,
          controls: updatedControl,
        };
      });

      queryClient.setQueryData(SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(), newFrameworks);

      onSuccess?.();
    },
    onError: (error) => {
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      });

      onError?.(error);
    },
  });

  return {
    deleteRequirement: mutate,
    isDeletingRequirement: isPending,
    didDeleteRequirementErrored: isError,
  };
};
