// App - Types
import type { StoryStatusDto } from '@/types/story/dtos';
import type { ThreatStatusDto } from '@/types/story/threats-analysis/dtos';
import type { RequirementStatusDto } from '@/types/story/requirements-analysis/dtos';

export const PUBLIC_ROUTES = {
  root: '/public',

  unexpected: () => `${PUBLIC_ROUTES.root}/unexpected`,

  updateReviewStatus: (reviewId: string, status: StoryStatusDto) =>
    `${PUBLIC_ROUTES.root}/reviews/${reviewId}/update/status/${status}`,

  updateReviewThreatStatus: (reviewId: string, threatId: string, status: ThreatStatusDto) =>
    `${PUBLIC_ROUTES.root}/reviews/${reviewId}/update/threats/${threatId}/status/${status}`,

  updateReviewRequirementStatus: (
    reviewId: string,
    requirementId: string,
    status: RequirementStatusDto
  ) =>
    `${PUBLIC_ROUTES.root}/reviews/${reviewId}/update/requirements/${requirementId}/status/${status}`,
};
