// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const DotsHorizontalOutlineIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 256 256" {...props} ref={ref}>
      <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm12-88a12,12,0,1,1-12-12A12,12,0,0,1,140,128Zm44,0a12,12,0,1,1-12-12A12,12,0,0,1,184,128Zm-88,0a12,12,0,1,1-12-12A12,12,0,0,1,96,128Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const DotsHorizontalOutlineIconMetadata = {
  name: 'Dots Horizontal Outline',
  importName: 'DotsHorizontalOutlineIcon',
  component: <DotsHorizontalOutlineIcon aria-label="Dots Horizontal Outline Icon" />,
  tags: 'dots, horizontal, more, menu, options, overflow, circles, shapes, polygons, ellipsis, 3, system, three, circle',
};
