// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IllustrationProps } from './illustration';

// App - Other
import Locale from '@/locale/en.json';
import { Illustration } from './illustration';

const locale = Locale.components.atoms.icon.illustrations;

// INSIGHT this illustration is from Adam
export const WarningIllustration = forwardRef((props: IllustrationProps, ref) => {
  return (
    <Illustration
      viewBox="0 0 256 256"
      {...props}
      aria-label={props['aria-label'] || locale['Warning Illustration']}
      ref={ref}
      fill="none"
    >
      <path
        d="M224.58 123.946C225.374 123.946 226.135 123.63 226.696 123.069C227.257 122.508 227.572 121.747 227.572 120.954C227.572 120.16 227.257 119.399 226.696 118.838C226.135 118.277 225.374 117.962 224.58 117.962C223.786 117.962 223.025 118.277 222.464 118.838C221.903 119.399 221.588 120.16 221.588 120.954C221.588 121.747 221.903 122.508 222.464 123.069C223.025 123.63 223.786 123.946 224.58 123.946Z"
        fill="#3782C0"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.773 31.511C151.773 30.7546 152.386 30.1413 153.143 30.1413H191.001C191.758 30.1413 192.371 30.7546 192.371 31.511C192.371 32.2675 191.758 32.8808 191.001 32.8808H153.143C152.386 32.8808 151.773 32.2675 151.773 31.511Z"
        fill="#1E293B"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.6512 235.465C83.2266 233.89 85.3633 233.005 87.5913 233.005C89.8193 233.005 91.956 233.89 93.5314 235.465C95.1068 237.041 95.9919 239.177 95.9919 241.405C95.9919 243.633 95.1068 245.77 93.5314 247.345C91.956 248.921 89.8193 249.806 87.5913 249.806C85.3633 249.806 83.2266 248.921 81.6512 247.345C80.0758 245.77 79.1907 243.633 79.1907 241.405C79.1907 239.177 80.0758 237.041 81.6512 235.465ZM87.5913 235.939C86.1415 235.939 84.7511 236.515 83.7259 237.54C82.7007 238.565 82.1248 239.955 82.1248 241.405C82.1248 242.855 82.7007 244.246 83.7259 245.271C84.751 246.296 86.1415 246.872 87.5913 246.872C89.0411 246.872 90.4315 246.296 91.4567 245.271C92.4819 244.246 93.0578 242.855 93.0578 241.405C93.0578 239.955 92.4819 238.565 91.4567 237.54C90.4315 236.515 89.0411 235.939 87.5913 235.939Z"
        fill="#1E293B"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M224.58 67.4391C225.347 67.4391 225.968 68.0606 225.968 68.8273V105.571C225.968 106.338 225.347 106.959 224.58 106.959C223.813 106.959 223.192 106.338 223.192 105.571V68.8273C223.192 68.0606 223.813 67.4391 224.58 67.4391Z"
        fill="#1E293B"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6881 46.1631C40.2993 46.4379 40.572 47.1562 40.2972 47.7675L26.0175 79.5257H51.4408C51.8524 79.5257 52.2359 79.7343 52.4595 80.0798C52.6831 80.4253 52.7164 80.8606 52.5479 81.2361L37.5446 114.662C37.2702 115.273 36.5521 115.547 35.9407 115.272C35.3292 114.998 35.0561 114.28 35.3305 113.668L49.566 81.9527H24.1413C23.7296 81.9527 23.346 81.7439 23.1224 81.3982C22.8988 81.0525 22.8658 80.617 23.0346 80.2415L38.0837 46.7722C38.3586 46.161 39.0769 45.8883 39.6881 46.1631Z"
        fill="#1E293B"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.286 62.6053C132.515 54.3419 120.588 54.3419 115.817 62.6053L47.4618 180.999C42.6909 189.263 48.6545 199.592 58.1963 199.592H194.906C204.448 199.592 210.412 189.263 205.641 181L137.286 62.6053ZM126.733 175.163C129.252 175.163 131.294 173.12 131.294 170.601C131.294 168.082 129.252 166.039 126.733 166.039C124.213 166.039 122.171 168.082 122.171 170.601C122.171 173.12 124.213 175.163 126.733 175.163Z"
        fill="#DB3B21"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.221 63.2201C131.923 55.7764 121.179 55.7764 116.882 63.2201L48.5267 181.614C44.2291 189.058 49.6011 198.363 58.1964 198.363H194.906C203.502 198.363 208.874 189.058 204.576 181.614L136.221 63.2201ZM114.752 61.9905C119.996 52.9074 133.107 52.9074 138.351 61.9905L206.706 180.385C211.95 189.468 205.395 200.822 194.906 200.822H58.1964C47.7081 200.822 41.1529 189.468 46.397 180.385L114.752 61.9905ZM126.733 167.269C124.893 167.269 123.401 168.761 123.401 170.601C123.401 172.441 124.893 173.933 126.733 173.933C128.573 173.933 130.065 172.441 130.065 170.601C130.065 168.761 128.573 167.269 126.733 167.269ZM120.942 170.601C120.942 167.403 123.534 164.81 126.733 164.81C129.931 164.81 132.524 167.403 132.524 170.601C132.524 173.799 129.931 176.392 126.733 176.392C123.534 176.392 120.942 173.799 120.942 170.601Z"
        fill="#1E293B"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.508 164.962C127.259 164.946 128.005 165.08 128.703 165.356C129.402 165.632 130.038 166.045 130.574 166.57C131.111 167.095 131.537 167.722 131.828 168.415C132.12 169.107 132.27 169.85 132.27 170.601C132.27 171.352 132.12 172.095 131.828 172.787C131.537 173.48 131.111 174.107 130.574 174.632C130.038 175.157 129.402 175.57 128.703 175.846C128.005 176.122 127.259 176.256 126.508 176.24C125.034 176.208 123.63 175.6 122.599 174.546C121.567 173.492 120.99 172.076 120.99 170.601C120.99 169.126 121.567 167.71 122.599 166.656C123.63 165.602 125.034 164.994 126.508 164.962ZM127.917 167.346C127.483 167.174 127.02 167.091 126.554 167.101C125.639 167.121 124.768 167.498 124.128 168.153C123.488 168.807 123.129 169.686 123.129 170.601C123.129 171.516 123.488 172.395 124.128 173.049C124.768 173.704 125.639 174.081 126.554 174.101C127.02 174.111 127.483 174.028 127.917 173.856C128.35 173.685 128.745 173.429 129.078 173.103C129.411 172.777 129.676 172.388 129.856 171.958C130.037 171.528 130.13 171.067 130.13 170.601C130.13 170.135 130.037 169.674 129.856 169.244C129.676 168.814 129.411 168.425 129.078 168.099C128.745 167.773 128.35 167.517 127.917 167.346Z"
        fill="#1E293B"
      />

      <path
        d="M121.556 96.0692C121.556 93.2885 123.81 91.0343 126.591 91.0343C129.371 91.0343 131.626 93.2885 131.626 96.0692V154.2C131.626 156.981 129.371 159.235 126.591 159.235C123.81 159.235 121.556 156.981 121.556 154.2V96.0692Z"
        fill="#F8F8F8"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.486 154.2V96.0692C129.486 94.47 128.19 93.1736 126.591 93.1736C124.991 93.1736 123.695 94.47 123.695 96.0692V154.2C123.695 155.799 124.991 157.096 126.591 157.096C128.19 157.096 129.486 155.799 129.486 154.2ZM126.591 91.0343C123.81 91.0343 121.556 93.2885 121.556 96.0692V154.2C121.556 156.981 123.81 159.235 126.591 159.235C129.371 159.235 131.626 156.981 131.626 154.2V96.0692C131.626 93.2885 129.371 91.0343 126.591 91.0343Z"
        fill="#1E293B"
      />
    </Illustration>
  );
});

export const WarningIllustrationMetadata = {
  name: 'Warning',
  importName: 'WarningIllustration',
  component: <WarningIllustration w="156px" h="86px" aria-label="Warning Illustration" />,
  tags: 'warning',
};
