// App - Types
import type { SecurityControlImportanceLevel } from '@/types/security-framework';

export const translateSecurityControlImportanceLevelToColor = (
  importanceLevel: SecurityControlImportanceLevel
): string => {
  if (importanceLevel === 'high') return '#EC8C7E';

  if (importanceLevel === 'medium') return '#FFCA45';

  if (importanceLevel === 'low') return '#82B8FE';

  return '#D7E1EE';
};

export const translateSecurityControlImportanceLevelToBgColor = (
  importanceLevel: SecurityControlImportanceLevel
): string => {
  if (importanceLevel === 'high') return '#FDE2E2';

  if (importanceLevel === 'medium') return '#FDFAE2';

  if (importanceLevel === 'low') return '#E2F0FD';

  return '#D7E1EE';
};
