// 3rd
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { z } from 'zod';

// App - Types
import type { KnowledgeSourceIntegration } from '../types/knowledge-source-integration';
import { ZodIntegrationDto, castDtoToKnowledgeSourceIntegration } from './dtos/integration';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { passthroughInvalidItemsArray } from '@/utils/zod';
import { INTEGRATIONS_QUERY_KEYS } from '../config/react-query-key-factory';

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  integrations: passthroughInvalidItemsArray(ZodIntegrationDto).default([]).catch([]),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodGetIntegrationsResponseDto };
export type { ResponseDto as GetIntegrationsResponseDto };

// #######
// Request
// #######

export const getIntegrations = async (): Promise<KnowledgeSourceIntegration[]> => {
  try {
    const res = await apiClient.get(`/Integration/ListIntegrations`);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.integrations.map(castDtoToKnowledgeSourceIntegration);
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return [];
    }

    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseIntegrations = {
  enabled?: boolean;
};

export const useIntegrations = ({ enabled = true }: UseIntegrations = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: INTEGRATIONS_QUERY_KEYS.integrations(),
    queryFn: async () => await getIntegrations(),
    enabled: enabled,
  });

  return {
    integrations: data,
    isFetchingIntegrations: isLoading || isFetching,
    didFetchIntegrationsErrored: isLoadingError,
  };
};
