// 3rd
import { z } from 'zod';

// App - Types
import type {
  EntityUrlReferenceSource,
  EntityUrlReferenceResolutionStatus,
  EntityUrlReference,
} from '../entity-url-reference';

export const EntityUrlReferenceResolutionStatusesDto = [
  'NotResolvedGeneral',
  'MissingEntity',
  'MissingIntegration',
  'Resolved',
  'UNKNOWN',
] as const;

export type EntityUrlReferenceResolutionStatusDto =
  (typeof EntityUrlReferenceResolutionStatusesDto)[number];

export const castEntityUrlReferenceResolutionStatusDtoToEntityUrlReferenceResolutionStatus = (
  dto: EntityUrlReferenceResolutionStatusDto
): EntityUrlReferenceResolutionStatus => {
  if (dto === 'NotResolvedGeneral') return 'not_resolved_general';

  if (dto === 'MissingEntity') return 'missing_entity';

  if (dto === 'MissingIntegration') return 'missing_integration';

  if (dto === 'Resolved') return 'resolved';

  return 'UNKNOWN';
};

export const EntityUrlReferenceSourcesDto = [
  'Confluence',
  'Jira',
  'GitHub',
  'Bitbucket',
  'AzureDevOps',
  'GoogleDrive',
  'Trello',
  'Notion',
  'Miro',
  'PowerPoint',
  'Excel',
  'Word',
  'Drawio',
  'Figma',
  'Lucidchart',
  'Linear',
  'GitLab',
  'Looker',
  'Kibana',
  'Grafana',
  'Tableau',
  'Canva',
  'Smartsheet',
  'Basecamp',
  'Asana',
  'Fibery',
  'Airtable',
  'Postman',
  'ServiceNow',
  'SharePoint',
  'OneDrive',
  'Dropbox',
  'Slack',
  'Mattermost',
  'Aws',
  'MicrosoftAzure',
  'GoogleCloud',
  'Docker',
  'Kubernetes',
  'PagerDuty',
  'Quip',
  'Whimsical',
  'Zoom',
  'Teams',
  'ServiceTitan',
  'Monday',
  'Salesforce',
  'Zendesk',
  'Other',
  'UNKNOWN',
] as const;

export type EntityUrlReferenceSourceDto = (typeof EntityUrlReferenceSourcesDto)[number];

export const castEntityDirectLinkSourceDtoToEntityDirectLinkSource = (
  dto: EntityUrlReferenceSourceDto
): EntityUrlReferenceSource => {
  if (dto === 'Confluence') return 'confluence';

  if (dto === 'Jira') return 'jira';

  if (dto === 'GitHub') return 'github';

  if (dto === 'Bitbucket') return 'bitbucket';

  if (dto === 'AzureDevOps') return 'azure_devops';

  if (dto === 'GoogleDrive') return 'google_drive';

  if (dto === 'Trello') return 'trello';

  if (dto === 'Notion') return 'notion';

  if (dto === 'Miro') return 'miro';

  if (dto === 'PowerPoint') return 'power_point';

  if (dto === 'Excel') return 'excel';

  if (dto === 'Word') return 'word';

  if (dto === 'Drawio') return 'drawio';

  if (dto === 'Figma') return 'figma';

  if (dto === 'Lucidchart') return 'lucidchart';

  if (dto === 'Linear') return 'linear';

  if (dto === 'GitLab') return 'gitlab';

  if (dto === 'Looker') return 'looker';

  if (dto === 'Kibana') return 'kibana';

  if (dto === 'Grafana') return 'grafana';

  if (dto === 'Tableau') return 'tableau';

  if (dto === 'Canva') return 'canva';

  if (dto === 'Smartsheet') return 'smartsheet';

  if (dto === 'Basecamp') return 'basecamp';

  if (dto === 'Asana') return 'asana';

  if (dto === 'Fibery') return 'fibery';

  if (dto === 'Airtable') return 'airtable';

  if (dto === 'Postman') return 'postman';

  if (dto === 'ServiceNow') return 'service_now';

  if (dto === 'SharePoint') return 'share_point';

  if (dto === 'OneDrive') return 'one_drive';

  if (dto === 'Dropbox') return 'dropbox';

  if (dto === 'Slack') return 'slack';

  if (dto === 'Mattermost') return 'mattermost';

  if (dto === 'Aws') return 'aws';

  if (dto === 'MicrosoftAzure') return 'microsoft_azure';

  if (dto === 'GoogleCloud') return 'google_cloud';

  if (dto === 'Docker') return 'docker';

  if (dto === 'Kubernetes') return 'kubernetes';

  if (dto === 'PagerDuty') return 'pager_duty';

  if (dto === 'Quip') return 'quip';

  if (dto === 'Whimsical') return 'whimsical';

  if (dto === 'Zoom') return 'zoom';

  if (dto === 'Teams') return 'teams';

  if (dto === 'ServiceTitan') return 'service_titan';

  if (dto === 'Monday') return 'monday';

  if (dto === 'Salesforce') return 'salesforce';

  if (dto === 'Zendesk') return 'zendesk';

  if (dto === 'Other') return 'other';

  return 'UNKNOWN';
};

export const ZodEntityUrlReferenceDto = z.object({
  destination: z.enum(EntityUrlReferenceSourcesDto),
  rank: z.number(),
  resolutionStatus: z.enum(EntityUrlReferenceResolutionStatusesDto),
  url: z.string(),
});

export type EntityUrlReferenceDto = z.infer<typeof ZodEntityUrlReferenceDto>;

export const castEntityUrlReferenceDtoToEntityUrlReference = (
  dto: EntityUrlReferenceDto
): EntityUrlReference => {
  return {
    source: castEntityDirectLinkSourceDtoToEntityDirectLinkSource(dto.destination),
    rank: dto.rank,
    resolutionStatus: castEntityUrlReferenceResolutionStatusDtoToEntityUrlReferenceResolutionStatus(
      dto.resolutionStatus
    ),
    url: dto.url,
  };
};
