// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const DumbleIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref} fill="none">
      <path
        d="M15.5 7.5H15V5.5C15 5.23478 14.8946 4.98043 14.7071 4.79289C14.5196 4.60536 14.2652 4.5 14 4.5H13V4C13 3.73478 12.8946 3.48043 12.7071 3.29289C12.5196 3.10536 12.2652 3 12 3H10.5C10.2348 3 9.98043 3.10536 9.79289 3.29289C9.60536 3.48043 9.5 3.73478 9.5 4V7.5H6.5V4C6.5 3.73478 6.39464 3.48043 6.20711 3.29289C6.01957 3.10536 5.76522 3 5.5 3H4C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4V4.5H2C1.73478 4.5 1.48043 4.60536 1.29289 4.79289C1.10536 4.98043 1 5.23478 1 5.5V7.5H0.5C0.367392 7.5 0.240215 7.55268 0.146447 7.64645C0.0526784 7.74021 0 7.86739 0 8C0 8.13261 0.0526784 8.25979 0.146447 8.35355C0.240215 8.44732 0.367392 8.5 0.5 8.5H1V10.5C1 10.7652 1.10536 11.0196 1.29289 11.2071C1.48043 11.3946 1.73478 11.5 2 11.5H3V12C3 12.2652 3.10536 12.5196 3.29289 12.7071C3.48043 12.8946 3.73478 13 4 13H5.5C5.76522 13 6.01957 12.8946 6.20711 12.7071C6.39464 12.5196 6.5 12.2652 6.5 12V8.5H9.5V12C9.5 12.2652 9.60536 12.5196 9.79289 12.7071C9.98043 12.8946 10.2348 13 10.5 13H12C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12V11.5H14C14.2652 11.5 14.5196 11.3946 14.7071 11.2071C14.8946 11.0196 15 10.7652 15 10.5V8.5H15.5C15.6326 8.5 15.7598 8.44732 15.8536 8.35355C15.9473 8.25979 16 8.13261 16 8C16 7.86739 15.9473 7.74021 15.8536 7.64645C15.7598 7.55268 15.6326 7.5 15.5 7.5ZM2 10.5V5.5H3V10.5H2ZM5.5 12H4V4H5.5V12ZM12 12H10.5V4H12V10.9888C12 10.9925 12 10.9963 12 11C12 11.0037 12 11.0075 12 11.0112V12ZM14 10.5H13V5.5H14V10.5Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const DumbleIconMetadata = {
  name: 'Dubmle',
  importName: 'DumbleIcon',
  component: <DumbleIcon aria-label="Dubmle Icon" />,
  tags: 'dumble, weight, heavy, strong, solid, hard, tough, data',
};
