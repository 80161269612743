// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { uuid4 } from '@/utils/generators';
import { Icon } from './icon';

export const BitbucketIcon = forwardRef((props: IconProps, ref) => {
  const guid = `bitbucket-icon-${uuid4()}`;

  return (
    <Icon viewBox="0 0 256 231" {...props} ref={ref} fill="none">
      <defs>
        <linearGradient
          id={`gradient-1-${guid}`}
          x1="108.63338%"
          y1="13.818022%"
          x2="46.9265964%"
          y2="78.7761408%"
        >
          <stop stopColor="#0052CC" offset="18%"></stop>

          <stop stopColor="#2684FF" offset="100%"></stop>
        </linearGradient>
      </defs>

      <g fill="none">
        <polygon points="101.272088 152.561281 154.720712 152.561281 167.622105 77.2417255 87.0600784 77.2417255" />

        <path
          d="M8.30813067,0.000683498206 C5.88502974,-0.0305685468 3.57212663,1.01125669 1.98985644,2.84669011 C0.407586248,4.68212353 -0.282086001,7.12328571 0.105843921,9.51533612 L34.9245512,220.888266 C35.8200362,226.227525 40.4199456,230.153012 45.8335925,230.197861 L212.873162,230.197861 C216.936516,230.250159 220.425595,227.319332 221.075449,223.30794 L255.894156,9.55634756 C256.282086,7.16429714 255.592414,4.72313497 254.010144,2.88770154 C252.427873,1.05226812 250.11497,0.0104428869 247.691869,0.0416949319 L8.30813067,0.000683498206 Z M154.924006,152.768274 L101.609142,152.768274 L87.1731177,77.3482475 L167.842608,77.3482475 L154.924006,152.768274 Z"
          fill="#2684FF"
        />

        <path
          d="M244.610824,77.2417255 L167.693776,77.2417255 L154.78548,152.601582 L101.513151,152.601582 L38.6108235,227.264801 C40.6045494,228.988786 43.1464609,229.94745 45.7820986,229.969396 L212.729383,229.969396 C216.789495,230.021652 220.275791,227.093164 220.925126,223.084972 L244.610824,77.2417255 Z"
          fill={`url(#gradient-1-${guid})`}
        />
      </g>
    </Icon>
  );
});

export const BitbucketIconMetadata = {
  name: 'Bitbucket Logo',
  importName: 'BitbucketIcon',
  component: <BitbucketIcon aria-label="Bitbucket Icon" />,
  tags: 'bitbucket, logo, repository, git, version control, source control',
};
