// 3rd
import { delay, http } from 'msw';

// App - Types
import type {
  SecurityFrameworkDto,
  GetSecurityFrameworksResponseDto,
} from '@/features/security-frameworks';
import {
  ZodSecurityFrameworkDto,
  ZodGetSecurityFrameworksResponseDto,
} from '@/features/security-frameworks';

// App - Other
import { API_URL } from '@/config/constants';
import { db } from '../../db';

export const getListFrameworksHandler = http.get(
  `${API_URL}/SecurityFramework/ListSecurityFrameworks`,
  async () => {
    const frameworks = db.framework.getAll();
    const parsedFrameworks: SecurityFrameworkDto[] = frameworks.map((framework) =>
      ZodSecurityFrameworkDto.parse(framework)
    );

    await delay(500);

    const res: GetSecurityFrameworksResponseDto = { securityFrameworks: parsedFrameworks };
    const parsedRes: GetSecurityFrameworksResponseDto =
      ZodGetSecurityFrameworksResponseDto.parse(res);

    return new Response(JSON.stringify(parsedRes), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
