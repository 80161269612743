// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SendSecurityInlineCommentFeedback } from '../../types/security-feedback';
import {
  ZodSendSecurityInlineCommentFeedbackDto,
  castSendSecurityInlineCommentFeedbackToSendSecurityInlineCommentFeedbackDto,
} from './dtos/send-security-inline-comment-feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = ZodSendSecurityInlineCommentFeedbackDto;

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodSendSecurityInlineCommentFeedbackRequestPayloadDto };
export type { RequestPayloadDto as SendSecurityInlineCommentFeedbackRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityFeedbackIds: z.string().array(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodSendSecurityInlineCommentFeedbackResponseDto };
export type { ResponseDto as SendSecurityInlineCommentFeedbackResponseDto };

// #######
// Request
// #######

export const sendSecurityInlineCommentFeedback = async (
  model: SendSecurityInlineCommentFeedback
): Promise<string[]> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      castSendSecurityInlineCommentFeedbackToSendSecurityInlineCommentFeedbackDto(model)
    );
    const res = await apiClient.post(`/SecurityFeedback/CreateInlineComments`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityFeedbackIds;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseSendSecurityInlineCommentFeedback = {
  onStart?: () => void;
  onSuccess?: (payload: SendSecurityInlineCommentFeedback) => void;
  onError?: (error: Error) => void;
};

export const useSendSecurityInlineCommentFeedback = ({
  onStart,
  onSuccess,
  onError,
}: UseSendSecurityInlineCommentFeedback) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (payload: SendSecurityInlineCommentFeedback) => {
      await sendSecurityInlineCommentFeedback(payload);

      return payload;
    },
    onMutate: () => onStart?.(),
    onSuccess: (payload) => onSuccess?.(payload),
    onError: (error) => onError?.(error),
  });

  return {
    sendSecurityInlineCommentFeedback: mutate,
    isSendingSecurityInlineComment: isPending,
    didSendSecurityInlineCommentErrored: isError,
  };
};
