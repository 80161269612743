// 3rd
import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// App - Other
import { detail, detailSB } from './text';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// ############
// POPOVER BASE
// ############

const baseStyle = definePartsStyle({
  content: {
    boxShadow: 'elevation-4',
    w: '385px',
    py: 'md',
    px: 'lg',
    borderRadius: 'md',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.brand.primary',

    _focusVisible: {
      boxShadow: 'elevation-4',
    },

    _active: {
      boxShadow: 'elevation-4',
    },
  },

  header: {
    border: 'none',
    pt: 0,
    pb: '4px',
    px: 0,
    color: 'text.high-tone',
    // color: 'text.brand.primary-high-tone',
    ...detailSB,
  },

  body: {
    py: 0,
    px: 0,
    color: 'text.high-tone',
    ...detail,
  },

  footer: {},

  popper: {
    px: '15px',
  },

  arrow: {},
  closeButton: {},
});

// #####
// SIZES
// #####

const mdSize = definePartsStyle({
  content: {
    w: '400px',
  },
});

// ########
// VARIANTS
// ########

const roundedVariant = definePartsStyle({
  content: {
    borderRadius: '35px',
  },
});

const popoverTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { rounded: roundedVariant },
  sizes: { md: mdSize },
});

export const Popover = popoverTheme;
