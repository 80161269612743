// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ClipboardIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref} fill="none">
      <path
        d="M10.5 9.50003C10.5 9.63264 10.4473 9.75982 10.3536 9.85359C10.2598 9.94735 10.1326 10 10 10H6C5.86739 10 5.74021 9.94735 5.64645 9.85359C5.55268 9.75982 5.5 9.63264 5.5 9.50003C5.5 9.36742 5.55268 9.24025 5.64645 9.14648C5.74021 9.05271 5.86739 9.00003 6 9.00003H10C10.1326 9.00003 10.2598 9.05271 10.3536 9.14648C10.4473 9.24025 10.5 9.36742 10.5 9.50003ZM10 7.00003H6C5.86739 7.00003 5.74021 7.05271 5.64645 7.14648C5.55268 7.24025 5.5 7.36742 5.5 7.50003C5.5 7.63264 5.55268 7.75982 5.64645 7.85358C5.74021 7.94735 5.86739 8.00003 6 8.00003H10C10.1326 8.00003 10.2598 7.94735 10.3536 7.85358C10.4473 7.75982 10.5 7.63264 10.5 7.50003C10.5 7.36742 10.4473 7.24025 10.3536 7.14648C10.2598 7.05271 10.1326 7.00003 10 7.00003ZM13.5 3.00003V13.5C13.5 13.7652 13.3946 14.0196 13.2071 14.2071C13.0196 14.3947 12.7652 14.5 12.5 14.5H3.5C3.23478 14.5 2.98043 14.3947 2.79289 14.2071C2.60536 14.0196 2.5 13.7652 2.5 13.5V3.00003C2.5 2.73482 2.60536 2.48046 2.79289 2.29292C2.98043 2.10539 3.23478 2.00003 3.5 2.00003H5.76625C6.04719 1.68548 6.39139 1.43382 6.77633 1.26151C7.16127 1.08919 7.57826 1.00012 8 1.00012C8.42174 1.00012 8.83873 1.08919 9.22367 1.26151C9.6086 1.43382 9.95281 1.68548 10.2338 2.00003H12.5C12.7652 2.00003 13.0196 2.10539 13.2071 2.29292C13.3946 2.48046 13.5 2.73482 13.5 3.00003ZM6 4.00003H10C10 3.4696 9.78929 2.96089 9.41421 2.58582C9.03914 2.21075 8.53043 2.00003 8 2.00003C7.46957 2.00003 6.96086 2.21075 6.58579 2.58582C6.21071 2.96089 6 3.4696 6 4.00003ZM12.5 3.00003H10.8281C10.9419 3.32117 11 3.65935 11 4.00003V4.50003C11 4.63264 10.9473 4.75982 10.8536 4.85358C10.7598 4.94735 10.6326 5.00003 10.5 5.00003H5.5C5.36739 5.00003 5.24021 4.94735 5.14645 4.85358C5.05268 4.75982 5 4.63264 5 4.50003V4.00003C5.00001 3.65935 5.05814 3.32117 5.17188 3.00003H3.5V13.5H12.5V3.00003Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ClipboardIconMetadata = {
  name: 'Clipboard',
  importName: 'ClipboardIcon',
  component: <ClipboardIcon aria-label="Clipboard Icon" />,
  tags: 'clipboard, copy, text, document, file, board',
};
