// App - Types
import {
  KnowledgeItemCategoriesDto,
  castKnowledgeItemCategoryDtoToKnowledgeItemCategory,
  castKnowledgeItemCategoryToKnowledgeItemCategoryDto,
} from '../../knowledge-item/dtos';
import type { KnowledgeItemCategoryDto } from '../../knowledge-item/dtos';
import {
  PullRequestCategoriesDto,
  castPullRequestCategoryDtoToPullRequestCategory,
  castPullRequestCategoryToPullRequestCategoryDto,
} from '../../pull-request/dtos';
import type { PullRequestCategoryDto } from '../../pull-request/dtos';
import type { ItemCategory } from '../item-category';

export const ItemCategoriesDto = [
  ...KnowledgeItemCategoriesDto,
  ...PullRequestCategoriesDto,
] as const;

export type ItemCategoryDto = (typeof ItemCategoriesDto)[number];

export const castItemCategoryDtoToItemCategory = (dto: ItemCategoryDto): ItemCategory => {
  const knowledgeItemCategory = castKnowledgeItemCategoryDtoToKnowledgeItemCategory(
    dto as KnowledgeItemCategoryDto
  );

  if (knowledgeItemCategory !== 'UNKNOWN') {
    return knowledgeItemCategory;
  }

  const pullRequestCategory = castPullRequestCategoryDtoToPullRequestCategory(
    dto as PullRequestCategoryDto
  );

  if (pullRequestCategory !== 'UNKNOWN') {
    return pullRequestCategory;
  }

  return 'UNKNOWN';
};

export const castItemCategoryToItemCategoryDto = (category: ItemCategory): ItemCategoryDto => {
  const knowledgeItemCategoryDto = castKnowledgeItemCategoryToKnowledgeItemCategoryDto(category);

  if (knowledgeItemCategoryDto !== 'UNKNOWN') {
    return knowledgeItemCategoryDto;
  }

  const pullRequestCategoryDto = castPullRequestCategoryToPullRequestCategoryDto(category);

  if (pullRequestCategoryDto !== 'UNKNOWN') {
    return pullRequestCategoryDto;
  }

  return 'UNKNOWN';
};
