// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import {
  SecurityFrameworkStandardsDto,
  castSecurityFrameworkStandardToSecurityFrameworkStandardDto,
} from '@/types/security-framework/dtos';
import type { SecurityFrameworkStandard } from '@/types/security-framework/security-framework-standard';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  standardSecurityFramework: z.enum(SecurityFrameworkStandardsDto),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodForkSecurityFrameworkStandardRequestPayloadDto };
export type { RequestPayloadDto as ForkSecurityFrameworkStandardRequestPayloadDto };

// #######
// Request
// #######

export const forkSecurityFrameworkStandard = async (
  standard: SecurityFrameworkStandard
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      standardSecurityFramework:
        castSecurityFrameworkStandardToSecurityFrameworkStandardDto(standard),
    });

    return await apiClient.post('/SecurityFramework/CreateSecurityFrameworkFromStandard', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseForkSecurityFrameworkStandard = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useForkSecurityFrameworkStandard = ({
  onStart,
  onSuccess,
  onError,
}: UseForkSecurityFrameworkStandard) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (standard: SecurityFrameworkStandard) =>
      await forkSecurityFrameworkStandard(standard),
    onMutate: () => onStart?.(),
    onSuccess: () => onSuccess?.(),
    onError: (error) => onError?.(error),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      }),
  });

  return {
    forkSecurityFrameworkStandard: mutate,
    isForkingSecurityFrameworkStandard: isPending,
    didForkSecurityFrameworkStandardErrored: isError,
  };
};
