export const SECURITY_FRAMEWORKS_QUERY_KEYS = {
  root: ['security_frameworks'] as const,
  securityFrameworks: () => [...SECURITY_FRAMEWORKS_QUERY_KEYS.root] as const,
};

export const REQUIREMENT_QUERY_KEYS = {
  root: ['requirement'] as const,
  requirement: (requirementId: string) =>
    [
      ...SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      ...REQUIREMENT_QUERY_KEYS.root,
      requirementId,
    ] as const,

  requirementStoryOccurrencesOverview: (requirementId: string, applicationId?: string) =>
    [
      ...REQUIREMENT_QUERY_KEYS.requirement(requirementId),
      ...(applicationId ? ['application', applicationId] : []),
      'story_occurrences_overview',
    ] as const,
};
