// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { CreateGoogleDriveOAuthUrl } from '../types/google-drive-integration';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  redirectUri: z.string(),
  redirectUriPath: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateGoogleDriveOAuthUrlRequestPayloadDto };
export type { RequestPayloadDto as CreateGoogleDriveOAuthUrlRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  url: z.string(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodCreateGoogleDriveOAuthUrlResponseDto };
export type { ResponseDto as CreateGoogleDriveOAuthUrlResponseDto };

// #######
// Request
// #######

export const createGoogleDriveOAuthUrl = async (
  model: CreateGoogleDriveOAuthUrl
): Promise<string> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      redirectUri: model.redirectUri.trim(),
      redirectUriPath: model.redirectUriPath.trim(),
    });
    const res = await apiClient.post(`/Integration/GetGoogleDriveOAuth2LoginUrl`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.url;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateGoogleDriveOAuthUrl = {
  onStart?: () => void;
  onSuccess?: (authUrl: string) => void;
  onError?: (error: Error) => void;
};

export const useCreateGoogleDriveOAuthUrl = ({
  onStart,
  onSuccess,
  onError,
}: UseCreateGoogleDriveOAuthUrl = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: CreateGoogleDriveOAuthUrl) => await createGoogleDriveOAuthUrl(model),
    onMutate: () => onStart?.(),
    onSuccess: (authUrl) => onSuccess?.(authUrl),
    onError: (error) => onError?.(error),
  });

  return {
    createOAuthUrl: mutate,
    isCreatingOAuthUrl: isPending,
    didOAuthUrlCreationErrored: isError,
  };
};
