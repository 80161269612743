// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const CloseIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 12 12" {...props} ref={ref} fill="none">
      <path
        d="M9.64028 9.10965C9.67512 9.14449 9.70276 9.18586 9.72161 9.23138C9.74047 9.2769 9.75017 9.32569 9.75017 9.37496C9.75017 9.42424 9.74047 9.47303 9.72161 9.51855C9.70276 9.56407 9.67512 9.60544 9.64028 9.64028C9.60544 9.67512 9.56407 9.70276 9.51855 9.72161C9.47303 9.74047 9.42424 9.75017 9.37496 9.75017C9.32569 9.75017 9.2769 9.74047 9.23138 9.72161C9.18586 9.70276 9.14449 9.67512 9.10965 9.64028L5.99996 6.53012L2.89028 9.64028C2.81991 9.71064 2.72448 9.75017 2.62496 9.75017C2.52545 9.75017 2.43002 9.71064 2.35965 9.64028C2.28929 9.56991 2.24976 9.47448 2.24976 9.37496C2.24976 9.27545 2.28929 9.18002 2.35965 9.10965L5.46981 5.99996L2.35965 2.89028C2.28929 2.81991 2.24976 2.72448 2.24976 2.62496C2.24976 2.52545 2.28929 2.43002 2.35965 2.35965C2.43002 2.28929 2.52545 2.24976 2.62496 2.24976C2.72448 2.24976 2.81991 2.28929 2.89028 2.35965L5.99996 5.46981L9.10965 2.35965C9.18002 2.28929 9.27545 2.24976 9.37496 2.24976C9.47448 2.24976 9.56991 2.28929 9.64028 2.35965C9.71064 2.43002 9.75017 2.52545 9.75017 2.62496C9.75017 2.72448 9.71064 2.81991 9.64028 2.89028L6.53012 5.99996L9.64028 9.10965Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const CloseIconMetadata = {
  name: 'Close',
  importName: 'CloseIcon',
  component: <CloseIcon aria-label="Close Icon" />,
  tags: 'close, x, remove, delete, cancel, exit',
};
