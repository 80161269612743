// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { CreateJiraIntegration } from '../types/jira-integration';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { INTEGRATIONS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  apiToken: z.string(),
  baseUrl: z.string(),
  userEmail: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateJiraIntegrationRequestPayloadDto };
export type { RequestPayloadDto as CreateJiraIntegrationRequestPayloadDto };

// #######
// Request
// #######

export const createJiraIntegration = async (model: CreateJiraIntegration): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      apiToken: model.token.trim(),
      baseUrl: model.baseUrl.trim(),
      userEmail: model.email.trim(),
    });

    return await apiClient.post(`/Integration/InsertJiraIntegration`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateJiraIntegration = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useCreateJiraIntegration = ({
  onStart,
  onSuccess,
  onError,
}: UseCreateJiraIntegration = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: CreateJiraIntegration) => await createJiraIntegration(model),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: INTEGRATIONS_QUERY_KEYS.integrations(),
      });
    },
    onMutate: () => onStart?.(),
    onSuccess: () => onSuccess?.(),
    onError: (error) => onError?.(error),
  });

  return {
    createIntegration: mutate,
    isCreatingIntegration: isPending,
    didIntegrationCreationErrored: isError,
  };
};
