// App - Types
import type { SecurityFrameworkType } from '../security-framework-type';

export const SecurityFrameworkTypesDto = [
  'Clover',
  'Custom',
  'IndustryStandard',
  'UNKNOWN',
] as const;

export type SecurityFrameworkTypeDto = (typeof SecurityFrameworkTypesDto)[number];

export const castSecurityFrameworkTypeDtoToSecurityFrameworkType = (
  dto: SecurityFrameworkTypeDto
): SecurityFrameworkType => {
  if (dto === 'Clover') return 'clover';

  if (dto === 'Custom') return 'custom';

  if (dto === 'IndustryStandard') return 'industry';

  return 'UNKNOWN';
};

export const castSecurityFrameworkTypeToSecurityFrameworkTypeDto = (
  type: SecurityFrameworkType
): SecurityFrameworkTypeDto => {
  if (type === 'clover') return 'Clover';

  if (type === 'custom') return 'Custom';

  if (type === 'industry') return 'IndustryStandard';

  return 'UNKNOWN';
};
