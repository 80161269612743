// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IllustrationProps } from './illustration';

// App - Other
import Locale from '@/locale/en.json';
import { Illustration } from './illustration';

const locale = Locale.components.atoms.icon.illustrations;

// INSIGHT this illustration is from Shay
export const EmptyStateIllustration = forwardRef((props: IllustrationProps, ref) => {
  return (
    <Illustration
      viewBox="0 0 202 277"
      {...props}
      aria-label={props['aria-label'] || locale['Empty Illustration']}
      ref={ref}
      fill="none"
    >
      <path
        d="M69.4555 24.4768C75.8562 30.9097 80.9348 38.9871 85.4652 46.7743C97.7022 67.798 105.538 91.2563 109.826 115.15C113.341 134.707 114.405 154.634 113.631 174.465C113.583 175.9 115.824 175.013 115.888 173.578C117.162 141.268 113.389 109.426 102.587 78.842C96.493 61.5747 88.2383 44.7589 77.1137 30.1681C75.2113 27.6691 73.2443 25.1378 71.0194 22.8968C70.0037 21.8811 68.4398 23.445 69.4555 24.4607V24.4768Z"
        fill="#E2EAFD"
      />

      <path
        d="M103.49 276.037C150.513 276.037 188.633 272.789 188.633 268.782C188.633 264.775 150.513 261.526 103.49 261.526C56.4668 261.526 18.3469 264.775 18.3469 268.782C18.3469 272.789 56.4668 276.037 103.49 276.037Z"
        fill="#E2EAFD"
      />

      <path
        d="M176.041 21.1395C173.816 23.574 171.833 26.2342 169.866 28.8622C164.449 36.1657 159.596 43.8561 155.098 51.7562C148.02 64.1866 141.91 77.1653 136.589 90.4341C129.479 108.185 123.804 126.5 119.419 145.09C113.905 168.419 110.406 192.2 108.601 216.093C108.343 219.447 108.133 222.8 107.94 226.154C107.859 227.588 110.084 227.572 110.165 226.154C112.664 182.058 120.757 138.076 136.509 96.7219C145.167 74.0053 156.082 51.7723 170.318 31.9899C172.607 28.8138 174.961 25.5893 177.605 22.7034C178.572 21.6554 177.009 20.0754 176.041 21.1395Z"
        fill="#2683FE"
      />

      <path
        d="M116.614 227.04C116.146 219.414 117.774 211.385 119.709 204.066C124.885 184.541 135.396 166.71 148.794 151.716C160.096 139.076 173.413 128.386 187.714 119.31C188.923 118.552 187.811 116.617 186.585 117.391C163.498 132.046 142.748 151.103 129.221 175.11C121.66 188.524 116.307 203.437 114.727 218.802C114.453 221.51 114.211 224.299 114.389 227.04C114.469 228.459 116.694 228.475 116.614 227.04Z"
        fill="#2683FE"
      />

      <path
        d="M39.4192 91.5626C39.9029 91.9012 39.9513 91.9495 40.6284 92.5138C41.886 93.5457 43.1113 94.642 44.3043 95.7706C48.8186 100.027 52.9299 104.719 56.7187 109.62C70.5357 127.564 80.0802 148.588 86.3197 170.273C91.624 188.749 94.526 207.854 95.6707 227.024C95.7513 228.443 97.9762 228.459 97.8956 227.024C96.0577 196.085 89.6409 165.097 76.098 137.076C68.73 121.841 59.2499 107.218 47.0935 95.3514C45.0299 93.3361 42.8856 91.3208 40.5478 89.6279C39.387 88.7895 38.2745 90.7242 39.4192 91.5465V91.5626Z"
        fill="#E2EAFD"
      />

      <path
        d="M17.7505 138.802C17.9601 138.882 17.5087 138.689 17.9279 138.882C18.3309 139.06 18.7179 139.237 19.1209 139.414C20.5236 140.043 21.9101 140.688 23.2805 141.365C28.1334 143.735 32.8896 146.331 37.5329 149.104C51.5111 157.439 64.651 167.451 75.6949 179.447C82.8694 187.25 89.0766 195.94 93.7199 205.501C94.3487 206.79 96.2673 205.662 95.6385 204.372C81.5151 175.4 53.8327 154.054 25.4732 139.979C23.1354 138.818 20.7654 137.673 18.3471 136.673C17.025 136.125 16.4446 138.27 17.7505 138.818V138.802Z"
        fill="#2683FE"
      />

      <path
        d="M52.8171 206.307V233.215C52.8171 253.739 69.6168 270.539 90.1408 270.539H122.015C142.539 270.539 159.339 253.739 159.339 233.215V206.307H52.8171Z"
        fill="#E2EAFD"
      />

      <path d="M159.323 219.93H52.8171V221.704H159.323V219.93Z" fill="white" />

      <path
        d="M148.666 237.455C145.119 237.455 143.345 235.085 141.91 233.183C140.556 231.361 139.701 230.345 138.009 230.345C136.316 230.345 135.461 231.377 134.107 233.183C132.688 235.085 130.915 237.455 127.352 237.455C123.789 237.455 122.031 235.085 120.612 233.183C119.258 231.361 118.404 230.345 116.711 230.345C115.018 230.345 114.163 231.377 112.809 233.183C111.39 235.085 109.617 237.455 106.054 237.455C102.491 237.455 100.733 235.085 99.3145 233.183C97.9602 231.361 97.1057 230.345 95.4129 230.345C93.72 230.345 92.8655 231.377 91.5112 233.183C90.0924 235.085 88.3189 237.455 84.772 237.455C81.225 237.455 79.4515 235.085 78.0328 233.183C76.6785 231.361 75.824 230.345 74.1311 230.345C72.4382 230.345 71.5837 231.377 70.2295 233.183C68.8107 235.085 67.0372 237.455 63.4902 237.455C59.9433 237.455 58.1698 235.085 56.751 233.183C55.3967 231.361 54.5422 230.345 52.8494 230.345V226.798C56.3963 226.798 58.1698 229.168 59.5886 231.071C60.9429 232.893 61.7974 233.909 63.4902 233.909C65.1831 233.909 66.0376 232.877 67.3919 231.071C68.8107 229.168 70.5842 226.798 74.1311 226.798C77.6781 226.798 79.4515 229.168 80.8703 231.071C82.2246 232.893 83.0791 233.909 84.772 233.909C86.4648 233.909 87.3193 232.877 88.6736 231.071C90.0924 229.168 91.8659 226.798 95.4129 226.798C98.9598 226.798 100.733 229.168 102.152 231.071C103.506 232.893 104.361 233.909 106.054 233.909C107.747 233.909 108.601 232.877 109.955 231.071C111.374 229.168 113.148 226.798 116.711 226.798C120.274 226.798 122.031 229.168 123.45 231.071C124.804 232.893 125.659 233.909 127.352 233.909C129.044 233.909 129.899 232.877 131.253 231.071C132.672 229.168 134.446 226.798 138.009 226.798C141.572 226.798 143.329 229.168 144.764 231.071C146.118 232.893 146.973 233.909 148.666 233.909C150.358 233.909 151.213 232.877 152.583 231.071C154.002 229.168 155.776 226.798 159.339 226.798V230.345C157.646 230.345 156.791 231.377 155.421 233.183C154.002 235.085 152.229 237.455 148.666 237.455Z"
        fill="white"
      />

      <path
        opacity="0.1"
        d="M139.444 206.307V233.215C139.444 253.739 122.644 270.539 102.12 270.539H121.999C142.523 270.539 159.323 253.739 159.323 233.215V206.307H139.444Z"
        fill="#482C80"
      />

      <path d="M162.708 201.454H49.4312V211.466H162.708V201.454Z" fill="#E2EAFD" />

      <path
        d="M66.3131 37.6015L68.17 30.0293C68.3471 29.3072 67.9052 28.5782 67.1832 28.4012L59.611 26.5444C57.818 26.1048 56.7208 24.2946 57.1605 22.5018C57.6001 20.7087 59.4103 19.6115 61.2031 20.0512L68.856 21.7067C69.5681 21.8609 70.2737 21.4201 70.4479 20.7126L72.2603 13.3487C72.6999 11.5557 74.5099 10.4582 76.3032 10.8981C78.0964 11.3379 79.1934 13.1479 78.7539 14.9409L76.942 22.3026C76.7677 23.011 77.1893 23.7294 77.8928 23.9226L85.4561 25.9982C87.2492 26.4378 88.3464 28.248 87.9066 30.0409C87.467 31.834 85.6568 32.9312 83.864 32.4914L76.3716 30.4354C75.6404 30.2347 74.8877 30.6773 74.7076 31.4141L72.8068 39.1939C72.3673 40.987 70.557 42.0842 68.764 41.6446C66.9707 41.2048 65.8737 39.3948 66.3133 37.6017L66.3131 37.6015Z"
        fill="#E2EAFD"
      />

      <path
        d="M28.125 87.6872L33.866 87.233C34.4136 87.1898 34.8223 86.7108 34.7789 86.1634L34.3247 80.4224C34.2171 79.063 35.2321 77.8736 36.5914 77.7661C37.9509 77.6585 39.1402 78.6735 39.2477 80.0328L39.8618 85.7837C39.9188 86.3189 40.3916 86.7115 40.9282 86.6696L46.5128 86.2325C47.8722 86.1249 49.0617 87.1398 49.1692 88.4994C49.2767 89.8589 48.2618 91.0481 46.9024 91.1558L41.3195 91.5926C40.7822 91.6348 40.3762 92.097 40.4039 92.6352L40.7025 98.4207C40.8102 99.7802 39.7951 100.97 38.4358 101.077C37.0764 101.185 35.8871 100.17 35.7796 98.8103L35.4836 93.079C35.4548 92.5197 34.9698 92.0945 34.4113 92.1391L28.5145 92.6106C27.155 92.7182 25.9657 91.7032 25.8581 90.3438C25.7506 88.9842 26.7655 87.795 28.1249 87.6874L28.125 87.6872Z"
        fill="#E2EAFD"
      />

      <path
        d="M160.531 24.5867L169.458 19.8234C170.309 19.3692 170.631 18.3108 170.176 17.4597L165.413 8.53312C164.285 6.41941 165.084 3.7912 167.198 2.66349C169.312 1.53543 171.94 2.33477 173.068 4.44839L178.095 13.2786C178.563 14.1004 179.6 14.4006 180.435 13.9563L189.122 9.33034C191.235 8.20228 193.864 9.00128 194.992 11.1153C196.12 13.2294 195.32 15.8572 193.207 16.9853L184.523 21.6097C183.687 22.0549 183.358 23.0845 183.78 23.9317L188.324 33.0393C189.452 35.153 188.653 37.7812 186.539 38.9089C184.426 40.0369 181.798 39.2376 180.67 37.124L176.168 28.102C175.729 27.2215 174.649 26.8773 173.781 27.3414L164.616 32.242C162.502 33.3701 159.874 32.5708 158.746 30.457C157.618 28.343 158.417 25.7151 160.531 24.5871L160.531 24.5867Z"
        fill="#334DFF"
      />

      <path
        d="M181.246 115.503L186.643 115.676C187.158 115.692 187.588 115.288 187.605 114.774L187.777 109.377C187.818 108.099 188.887 107.096 190.165 107.137C191.443 107.177 192.446 108.246 192.405 109.524L192.381 114.947C192.378 115.452 192.778 115.867 193.283 115.883L198.532 116.055C199.81 116.096 200.813 117.165 200.772 118.443C200.731 119.721 199.662 120.724 198.384 120.683L193.137 120.511C192.632 120.495 192.205 120.884 192.175 121.388L191.853 126.81C191.813 128.088 190.743 129.091 189.466 129.05C188.188 129.01 187.185 127.941 187.225 126.663L187.544 121.291C187.575 120.767 187.167 120.32 186.642 120.304L181.098 120.132C179.82 120.091 178.817 119.022 178.858 117.744C178.899 116.466 179.968 115.463 181.246 115.504L181.246 115.503Z"
        fill="#334DFF"
      />

      <path
        d="M9.85295 146.929L11.7194 139.97C11.8975 139.306 11.5038 138.624 10.8402 138.446L3.88122 136.579C2.23334 136.137 1.25571 134.443 1.69772 132.795C2.1396 131.147 3.83396 130.17 5.48163 130.612L12.5192 132.294C13.1741 132.451 13.8347 132.057 14.0098 131.407L15.831 124.64C16.2729 122.992 17.9672 122.014 19.6152 122.456C21.2632 122.898 22.2407 124.592 21.7988 126.24L19.9781 133.006C19.8029 133.657 20.1781 134.329 20.8242 134.521L27.7707 136.59C29.4185 137.032 30.3962 138.726 29.9542 140.374C29.5123 142.022 27.8179 142.999 26.1703 142.557L19.289 140.508C18.6174 140.308 17.9131 140.702 17.7321 141.379L15.8208 148.529C15.379 150.177 13.6846 151.155 12.0367 150.713C10.3887 150.271 9.41121 148.577 9.85308 146.929L9.85295 146.929Z"
        fill="#334DFF"
      />
    </Illustration>
  );
});

export const EmptyStateIllustrationMetadata = {
  name: 'Empty State',
  importName: 'EmptyStateIllustration',
  component: <EmptyStateIllustration w="86px" h="86px" aria-label="Empty State Illustration" />,
  tags: 'empty',
};
