// App - Types
import type { RequirementStatus } from '../requirement-status';

export const RequirementStatusesDto = [
  'CoveredManuallyByAppSec',
  'CoveredManuallyByDeveloper',
  'CoveredByClover',
  'SentToDevelopers',
  'SuggestedByClover',
  'IgnoredByAppSec',
  'IrrelevantByClover',
  'IrrelevantByAppSec',
  'IrrelevantSuggestedByDevApprovedByAppSec',
  'IrrelevantSuggestedByDeveloper',
  'UNKNOWN',
] as const;

export type RequirementStatusDto = (typeof RequirementStatusesDto)[number];

export const castRequirementStatusDtoToRequirementStatus = (
  dto: RequirementStatusDto
): RequirementStatus => {
  if (dto === 'CoveredManuallyByAppSec') return 'covered_manually_by_appsec';

  if (dto === 'CoveredManuallyByDeveloper') return 'covered_manually_by_developer';

  if (dto === 'CoveredByClover') return 'covered_by_clover';

  if (dto === 'SentToDevelopers') return 'sent_to_developers';

  if (dto === 'SuggestedByClover') return 'suggested_by_clover';

  if (dto === 'IgnoredByAppSec') return 'ignored_by_appsec';

  if (dto === 'IrrelevantByClover') return 'irrelevant_by_clover';

  if (dto === 'IrrelevantByAppSec') return 'irrelevant_by_appsec';

  if (dto === 'IrrelevantSuggestedByDevApprovedByAppSec')
    return 'irrelevant_suggested_by_dev_approved_by_appsec';

  if (dto === 'IrrelevantSuggestedByDeveloper') return 'irrelevant_suggested_by_dev';

  return 'UNKNOWN';
};

export const castRequirementStatusToRequirementStatusDto = (
  status: RequirementStatus
): RequirementStatusDto => {
  if (status === 'covered_manually_by_appsec') return 'CoveredManuallyByAppSec';

  if (status === 'covered_manually_by_developer') return 'CoveredManuallyByDeveloper';

  if (status === 'covered_by_clover') return 'CoveredByClover';

  if (status === 'sent_to_developers') return 'SentToDevelopers';

  if (status === 'suggested_by_clover') return 'SuggestedByClover';

  if (status === 'ignored_by_appsec') return 'IgnoredByAppSec';

  if (status === 'irrelevant_by_clover') return 'IrrelevantByClover';

  if (status === 'irrelevant_by_appsec') return 'IrrelevantByAppSec';

  if (status === 'irrelevant_suggested_by_dev_approved_by_appsec')
    return 'IrrelevantSuggestedByDevApprovedByAppSec';

  if (status === 'irrelevant_suggested_by_dev') return 'IrrelevantSuggestedByDeveloper';

  return 'UNKNOWN';
};
