// App - Types
export type { IllustrationProps } from './illustration';

// App - Other
export { Illustration } from './illustration';
export { AllDoneIllustration } from './all-done-illustration';
export { CommentFeedbackIllustration } from './comment-feedback-illustration';
export { CompletedIllustration } from './completed-illustration';
export { ConfigurationIllustration } from './configuration-illustration';
export { EmptyIllustration } from './empty-illustration';
export { EmptyStateIllustration } from './empty-state-illustration';
export { Error404Illustration } from './error-404-illustration';
export { ErrorIllustration } from './error-illustration';
export { ErrorOldIllustration } from './error-old-illustration';
export { FootnoteCommentFeedbackIllustration } from './footnote-comment-feedback-illustration';
export { FreshStartIllustration } from './fresh-start-illustration';
export { InlineCommentFeedbackIllustration } from './inline-comment-feedback-illustration';
export { NoResultsIllustration } from './no-results-illustration';
export { ParagraphFeedbackIllustration } from './paragraph-feedback-illustration';
export { WarningIllustration } from './warning-illustration';
export { WrongIllustration } from './wrong-illustration';

// Metadata
import { AllDoneIllustrationMetadata } from './all-done-illustration';
import { CommentFeedbackIllustrationMetadata } from './comment-feedback-illustration';
import { CompletedIllustrationMetadata } from './completed-illustration';
import { ConfigurationIllustrationMetadata } from './configuration-illustration';
import { EmptyIllustrationMetadata } from './empty-illustration';
import { EmptyStateIllustrationMetadata } from './empty-state-illustration';
import { Error404IllustrationMetadata } from './error-404-illustration';
import { ErrorIllustrationMetadata } from './error-illustration';
import { ErrorOldIllustrationMetadata } from './error-old-illustration';
import { FootnoteCommentFeedbackIllustrationMetadata } from './footnote-comment-feedback-illustration';
import { FreshStartIllustrationMetadata } from './fresh-start-illustration';
import { InlineCommentFeedbackIllustrationMetadata } from './inline-comment-feedback-illustration';
import { NoResultsIllustrationMetadata } from './no-results-illustration';
import { ParagraphFeedbackIllustrationMetadata } from './paragraph-feedback-illustration';
import { WarningIllustrationMetadata } from './warning-illustration';
import { WrongIllustrationMetadata } from './wrong-illustration';

export const illustrationsMetaData = [
  AllDoneIllustrationMetadata,
  CommentFeedbackIllustrationMetadata,
  CompletedIllustrationMetadata,
  ConfigurationIllustrationMetadata,
  EmptyIllustrationMetadata,
  EmptyStateIllustrationMetadata,
  Error404IllustrationMetadata,
  ErrorIllustrationMetadata,
  ErrorOldIllustrationMetadata,
  FootnoteCommentFeedbackIllustrationMetadata,
  FreshStartIllustrationMetadata,
  InlineCommentFeedbackIllustrationMetadata,
  NoResultsIllustrationMetadata,
  ParagraphFeedbackIllustrationMetadata,
  WarningIllustrationMetadata,
  WrongIllustrationMetadata,
];
