// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IllustrationProps } from './illustration';

// App - Other
import Locale from '@/locale/en.json';
import { Illustration } from './illustration';

const locale = Locale.components.atoms.icon.illustrations;

export const FootnoteCommentFeedbackIllustration = forwardRef((props: IllustrationProps, ref) => {
  return (
    <Illustration
      viewBox="0 0 60 54"
      {...props}
      aria-label={props['aria-label'] || locale['Footnote Comment Feedback Illustration']}
      ref={ref}
      fill="none"
    >
      <rect x="0.5" y="0.5" width="59" height="53" rx="3.5" fill="white" />

      <rect x="0.5" y="0.5" width="59" height="53" rx="3.5" stroke="#E2E8F0" />

      <rect x="4" y="4" width="40" height="6" rx="1" fill="#E2E8F0" fillOpacity="0.7" />

      <rect x="4" y="13" width="45" height="6" rx="1" fill="#E2E8F0" fillOpacity="0.7" />

      <rect x="4" y="22" width="50" height="6" rx="1" fill="#E2E8F0" fillOpacity="0.7" />

      <rect x="4" y="31" width="50" height="6" rx="1" fill="#E2E8F0" fillOpacity="0.7" />

      <rect x="4.5" y="46.5" width="51" height="3" rx="0.5" fill="#F2FCFA" stroke="#21A9AB" />

      <rect x="45.5" y="11.5" width="5" height="5" rx="2.5" fill="#F2FCFA" stroke="#21A9AB" />

      <rect y="41" width="60" height="1" fill="#E2E8F0" />
    </Illustration>
  );
});

export const FootnoteCommentFeedbackIllustrationMetadata = {
  name: 'Footnote Comment Feedback',
  importName: 'FootnoteCommentFeedbackIllustration',
  component: (
    <FootnoteCommentFeedbackIllustration
      w="156px"
      h="86px"
      aria-label="Footnote Comment Feedback Illustration"
    />
  ),
  tags: 'feedback, footnote, comment',
};
