// React & Next
import type { ReactNode } from 'react';

// 3rd
import { Tooltip as ChakraTooltip } from '@chakra-ui/react';

export type TooltipProps = {
  children: ReactNode;
  content: ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  isDisabled?: boolean;
  openDelay?: number;
};

export const Tooltip = ({
  content,
  placement = 'top',
  openDelay,
  isDisabled,
  children,
}: TooltipProps) => {
  return (
    <ChakraTooltip
      hasArrow
      label={content}
      placement={placement}
      py="8px"
      px="12px"
      fontSize="12px"
      bg="surface.primary"
      boxShadow="elevation-4"
      color="text.mid-tone"
      borderRadius="4px"
      maxW="320px"
      arrowSize={13}
      isDisabled={isDisabled}
      {...(openDelay ? { openDelay } : {})}
    >
      {children}
    </ChakraTooltip>
  );
};
