// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { uuid4 } from '@/utils/generators';
import { Icon } from './icon';

export const PlusIcon = forwardRef((props: IconProps, ref) => {
  const guid = `plus-icon-${uuid4()}`;

  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref} fill="none">
      <g clipPath={`url(#clip-1-${guid})`}>
        <path d="M2.5 8H13.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />

        <path d="M8 2.5V13.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      </g>

      <defs>
        <clipPath id={`clip-1-${guid}`}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const PlusIconMetadata = {
  name: 'Plus',
  importName: 'PlusIcon',
  component: <PlusIcon aria-label="Plus Icon" />,
  tags: 'plus, add, expand, square, zoom, fit, view',
};
