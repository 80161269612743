// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SendSecurityFootnoteCommentFeedback } from '../../types/security-feedback';
import {
  ZodSendSecurityFootnoteCommentFeedbackDto,
  castSendSecurityFootnoteCommentFeedbackToSendSecurityFootnoteCommentFeedbackDto,
} from './dtos/send-security-footnote-comment-feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = ZodSendSecurityFootnoteCommentFeedbackDto;

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodSendSecurityFootnoteCommentFeedbackRequestPayloadDto };
export type { RequestPayloadDto as SendSecurityFootnoteCommentFeedbackRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityFeedbackIds: z.string().array(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodSendSecurityFootnoteCommentFeedbackResponseDto };
export type { ResponseDto as SendSecurityFootnoteCommentFeedbackResponseDto };

// #######
// Request
// #######

export const sendSecurityFootnoteCommentFeedback = async (
  model: SendSecurityFootnoteCommentFeedback
): Promise<string[]> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      castSendSecurityFootnoteCommentFeedbackToSendSecurityFootnoteCommentFeedbackDto(model)
    );
    const res = await apiClient.post(`/SecurityFeedback/CreateFootnotes`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityFeedbackIds;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseSendSecurityFootnoteCommentFeedback = {
  onStart?: () => void;
  onSuccess?: (payload: SendSecurityFootnoteCommentFeedback) => void;
  onError?: (error: Error) => void;
};

export const useSendSecurityFootnoteCommentFeedback = ({
  onStart,
  onSuccess,
  onError,
}: UseSendSecurityFootnoteCommentFeedback) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (payload: SendSecurityFootnoteCommentFeedback) => {
      await sendSecurityFootnoteCommentFeedback(payload);

      return payload;
    },
    onMutate: () => onStart?.(),
    onSuccess: (payload) => onSuccess?.(payload),
    onError: (error) => onError?.(error),
  });

  return {
    sendSecurityFootnoteCommentFeedback: mutate,
    isSendingSecurityFootnoteComment: isPending,
    didSendSecurityFootnoteCommentErrored: isError,
  };
};
