// App - Types
import type { KnowledgeItemCategory } from '../knowledge-item-category';

export const KnowledgeItemCategoriesDto = [
  'Architecture',
  'Business Document',
  'DevOps',
  'Feature',
  'HR Document',
  'Meeting Notes',
  'Onboarding',
  'Other',
  'Product Requirements Document',
  'Planning Document',
  'Release',
  'R&D Design',
  'R&D General',
  'Roadmap',
  'Security Oriented',
  'Support & Maintenance',
  'Template',
  'UNKNOWN',
] as const;

export type KnowledgeItemCategoryDto = (typeof KnowledgeItemCategoriesDto)[number];

export const castKnowledgeItemCategoryDtoToKnowledgeItemCategory = (
  dto: KnowledgeItemCategoryDto
): KnowledgeItemCategory => {
  if (dto === 'Architecture') return 'architecture';

  if (dto === 'Business Document') return 'business_document';

  if (dto === 'DevOps') return 'devops';

  if (dto === 'Feature') return 'feature';

  if (dto === 'HR Document') return 'hr_document';

  if (dto === 'Meeting Notes') return 'meeting_notes';

  if (dto === 'Onboarding') return 'onboarding';

  if (dto === 'Other') return 'other';

  if (dto === 'Product Requirements Document') return 'product_requirements_document';

  if (dto === 'Planning Document') return 'planning_document';

  if (dto === 'Release') return 'release';

  if (dto === 'R&D Design') return 'rnd_design';

  if (dto === 'R&D General') return 'rnd_general';

  if (dto === 'Roadmap') return 'roadmap';

  if (dto === 'Security Oriented') return 'security_oriented';

  if (dto === 'Support & Maintenance') return 'support_and_maintenance';

  if (dto === 'Template') return 'template';

  return 'UNKNOWN';
};

export const castKnowledgeItemCategoryToKnowledgeItemCategoryDto = (
  category: KnowledgeItemCategory
): KnowledgeItemCategoryDto => {
  if (category === 'architecture') return 'Architecture';

  if (category === 'business_document') return 'Business Document';

  if (category === 'devops') return 'DevOps';

  if (category === 'feature') return 'Feature';

  if (category === 'hr_document') return 'HR Document';

  if (category === 'meeting_notes') return 'Meeting Notes';

  if (category === 'onboarding') return 'Onboarding';

  if (category === 'other') return 'Other';

  if (category === 'product_requirements_document') return 'Product Requirements Document';

  if (category === 'planning_document') return 'Planning Document';

  if (category === 'release') return 'Release';

  if (category === 'rnd_design') return 'R&D Design';

  if (category === 'rnd_general') return 'R&D General';

  if (category === 'roadmap') return 'Roadmap';

  if (category === 'security_oriented') return 'Security Oriented';

  if (category === 'support_and_maintenance') return 'Support & Maintenance';

  if (category === 'template') return 'Template';

  return 'UNKNOWN';
};
