// 3rd
import { Flex, Stack, Textarea, useToken } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

// App - Types
import type { RequirementAntiPattern } from '../../../types/requirement-anti-pattern';

// App - Other
import Locale from '@/locale/en.json';
import { InfoIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';
import { Field } from '@/components/molecules/form';
import { Button } from '@/components/molecules/button';
import { Tooltip } from '@/components/molecules/tooltips';

const locale = Locale.features.frameworks['frameworks-requirement-anti-pattern-form'];

type RequirementAntiPatternFormProps = {
  onSubmit: (antiPattern: RequirementAntiPattern) => void;
  isSubmitting?: boolean;
  didError?: boolean;
};

export const RequirementAntiPatternForm = ({
  onSubmit,
  isSubmitting,
  didError,
}: RequirementAntiPatternFormProps) => {
  const [defaultOutlineColor] = useToken('colors', ['border.primary']);
  const { register, handleSubmit, formState } = useForm<RequirementAntiPattern>();

  return (
    <Stack
      as="form"
      direction="column"
      alignItems="flex-start"
      gap="none"
      position="relative"
      width="100%"
      height="fit-content"
      outline={`1px solid ${defaultOutlineColor}`}
      borderRadius="sm"
      transition="150ms all ease-in-out"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Flex
        alignItems="center"
        gap="md"
        py="8px"
        ps="12px"
        pe="12px"
        w="100%"
        borderBottom="1px"
        borderColor="border.primary"
      >
        <Text variant="detail" color="text.high-tone">
          {locale['New anti-pattern']}
        </Text>

        <Flex grow={1} />

        <Flex alignItems="center" gap="md">
          {didError ? (
            <Tooltip content={locale['Something isn’t right, let’s try again.']}>
              <InfoIcon size="xs" color="text.error" aria-label={locale['Error info tip']} />
            </Tooltip>
          ) : null}

          <Button
            variant="old.solid"
            size="sm"
            isLoading={isSubmitting}
            type="submit"
            aria-label={locale['Create']}
          >
            {locale['Create']}
          </Button>
        </Flex>
      </Flex>

      <Flex py="md" px="md" w="100%">
        <Field label="" htmlFor="content" error={formState.errors['content']?.message}>
          <Textarea
            variant="ghost"
            size="sm"
            bg="surface.secondary"
            rows={3}
            resize="vertical"
            isDisabled={isSubmitting}
            placeholder={locale['Enter anti-pattern']}
            {...register('content', {
              required: 'Required',
            })}
          />
        </Field>
      </Flex>
    </Stack>
  );
};
