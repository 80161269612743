// App - Types
import type { EntityType } from './entity-type';

export const EntityKinds = ['document', 'ticket', 'pull_request', 'UNKNOWN'] as const;

export type EntityKind = (typeof EntityKinds)[number];

const DOCUMENT_TYPES: ReadonlyArray<EntityType> = [
  'azure_devops_wiki_page',
  'confluence_page',
  'notion_page',
  'google_drive',
  'share_point',
] as const;

const TICKET_TYPES: ReadonlyArray<EntityType> = [
  'azure_devops_work_item',
  'jira_issue',
  'trello_card',
  'github_issue',
  'service_now_task',
] as const;

const PULL_REQUEST_TYPES: ReadonlyArray<EntityType> = [
  'azure_devops_pull_request',
  'bitbucket_pull_request',
  'github_pull_request',
] as const;

export const castEntityTypeToEntityKind = (type: EntityType): EntityKind => {
  if (DOCUMENT_TYPES.includes(type)) return 'document';

  if (TICKET_TYPES.includes(type)) return 'ticket';

  if (PULL_REQUEST_TYPES.includes(type)) return 'pull_request';

  return 'UNKNOWN';
};
