// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const KibanaIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 256 328" {...props} ref={ref} fill="none">
      <g>
        <polygon fill="#F04E98" points="256 0 -4.7684239e-17 0 2.20275616e-13 294.78912" />

        <path
          d="M2.27373675e-13,122.88 L2.27373675e-13,294.78912 L122.19392,154.08128 C85.8624,134.27712 44.288,122.88 2.27373675e-13,122.88"
          fill="#343741"
        />

        <path
          d="M148.589568,170.540032 L23.200768,314.944512 L12.131328,327.683072 L250.866688,327.683072 C237.769728,263.130112 200.301568,207.496192 148.589568,170.540032"
          fill="#00BFB3"
        />
      </g>
    </Icon>
  );
});

export const KibanaIconMetadata = {
  name: 'Kibana Logo',
  importName: 'KibanaIcon',
  component: <KibanaIcon aria-label="Kibana Icon" />,
  tags: 'kibana, logo',
};
