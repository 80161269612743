// 3rd
import { z } from 'zod';

// App - Types
import {
  RequirementCountermeasureSourcesDto,
  RequirementCountermeasureSnippetTypesDto,
  castRequirementCountermeasureSourceDtoToRequirementCountermeasureSource,
  castRequirementCountermeasureSnippetTypeDtoToRequirementCountermeasureSnippetType,
} from '@/types/security-framework/requirement/dtos';
import type { RequirementCountermeasure } from '../../../types/requirement-countermeasure';

export const ZodRequirementCountermeasureDto = z.object({
  id: z.string(),
  content: z.string(),
  title: z.string(),
  countermeasureSource: z.enum(RequirementCountermeasureSourcesDto),
  countermeasureSnippetType: z.enum(RequirementCountermeasureSnippetTypesDto),
  securityRequirementsIds: z.string().array(),
});

export type RequirementCountermeasureDto = z.infer<typeof ZodRequirementCountermeasureDto>;

export const castRequirementCountermeasureDtoToRequirementCountermeasure = (
  dto: RequirementCountermeasureDto
): RequirementCountermeasure => {
  return {
    content: dto.content,
    source: castRequirementCountermeasureSourceDtoToRequirementCountermeasureSource(
      dto.countermeasureSource
    ),
    snippetType: castRequirementCountermeasureSnippetTypeDtoToRequirementCountermeasureSnippetType(
      dto.countermeasureSnippetType
    ),
    title: dto.title,
  };
};
