// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { SecurityFrameworkActivityStatusesDto } from '@/types/security-framework/dtos';
import type { SecurityFramework, UpdateSecurityFramework } from '../types/security-framework';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityFrameworkId: z.string(),
  activityStatus: z.enum(SecurityFrameworkActivityStatusesDto).optional(),
  name: z.string().optional(),
  frameworkDescription: z.string().optional(),
  labels: z.string().array().optional(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodUpdateSecurityFrameworkRequestPayloadDto };
export type { RequestPayloadDto as UpdateSecurityFrameworkRequestPayloadDto };

// #######
// Request
// #######

export const updateSecurityFramework = async (
  frameworkId: string,
  model: UpdateSecurityFramework
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      securityFrameworkId: frameworkId,
      ...(model.isActive !== undefined
        ? { activityStatus: model.isActive ? 'Active' : 'Inactive' }
        : {}),
      ...(model.name !== undefined ? { name: model.name } : {}),
      ...(model.description !== undefined ? { frameworkDescription: model.description } : {}),
      ...(model.labels !== undefined ? { labels: model.labels } : {}),
    });

    return await apiClient.post('/SecurityFramework/UpdateSecurityFramework', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseUpdateSecurityFramework = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useUpdateSecurityFramework = ({
  onStart,
  onSuccess,
  onError,
}: UseUpdateSecurityFramework = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({
      id,
      updatedValues,
    }: {
      id: string;
      updatedValues: UpdateSecurityFramework;
    }) => await updateSecurityFramework(id, updatedValues),
    onMutate: () => onStart?.(),
    onSuccess: async (data, model) => {
      await queryClient.cancelQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      });

      const previousFrameworks = queryClient.getQueryData(
        SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks() || []
      ) as SecurityFramework[];

      const updatedFrameworks = previousFrameworks.map((framework) => {
        const { id, updatedValues } = model;

        if (framework.id === id) {
          return {
            ...framework,
            ...updatedValues,
          };
        }

        return framework;
      });

      queryClient.setQueryData(
        SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
        updatedFrameworks
      );

      onSuccess?.();
    },
    onError: (error) => {
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      });

      onError?.(error);
    },
  });

  return {
    updateSecurityFramework: mutate,
    isUpdatingSecurityFramework: isPending,
    didUpdateSecurityFrameworkErrored: isError,
  };
};
