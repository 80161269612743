// App - Types
import type { SecurityFrameworkStandard } from '../security-framework-standard';

export const SecurityFrameworkStandardsDto = [
  'Asvs',
  'AwsFsbp',
  'OwaspCheatSheet',
  'UNKNOWN',
] as const;

export type SecurityFrameworkStandardDto = (typeof SecurityFrameworkStandardsDto)[number];

export const castSecurityFrameworkStandardDtoToSecurityFrameworkStandard = (
  dto: SecurityFrameworkStandardDto
): SecurityFrameworkStandard => {
  if (dto === 'Asvs') return 'asvs';

  if (dto === 'AwsFsbp') return 'aws_fsbp';

  if (dto === 'OwaspCheatSheet') return 'owasp_cheat_sheet';

  return 'UNKNOWN';
};

export const castSecurityFrameworkStandardToSecurityFrameworkStandardDto = (
  standard: SecurityFrameworkStandard
): SecurityFrameworkStandardDto => {
  if (standard === 'asvs') return 'Asvs';

  if (standard === 'aws_fsbp') return 'AwsFsbp';

  if (standard === 'owasp_cheat_sheet') return 'OwaspCheatSheet';

  return 'UNKNOWN';
};
