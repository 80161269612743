// App - Types
import type { EntityKind } from '../entity-kind';

export const EntityKindsDto = ['Documents', 'Tickets', 'UNKNOWN'] as const;

export type EntityKindDto = (typeof EntityKindsDto)[number];

export const castEntityKindDtoToEntityKind = (dto: EntityKindDto): EntityKind => {
  if (dto === 'Documents') return 'document';

  if (dto === 'Tickets') return 'ticket';

  return 'UNKNOWN';
};

export const castEntityKindToEntityKindDto = (kind: EntityKind): EntityKindDto => {
  if (kind === 'document') return 'Documents';

  if (kind === 'ticket') return 'Tickets';

  return 'UNKNOWN';
};
