// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IllustrationProps } from './illustration';

// App - Other
import Locale from '@/locale/en.json';
import { Illustration } from './illustration';

const locale = Locale.components.atoms.icon.illustrations;

// INSIGHT this illustration is from Guy
export const FreshStartIllustration = forwardRef((props: IllustrationProps, ref) => {
  return (
    <Illustration
      viewBox="0 0 256 137"
      {...props}
      aria-label={props['aria-label'] || locale['Fresh Start Illustration']}
      ref={ref}
      fill="none"
    >
      <g id="Mask group">
        <mask
          id="mask0_629_11212"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="256"
          height="137"
        >
          <rect id="Rectangle 306" width="256" height="137" fill="url(#paint0_linear_629_11212)" />
        </mask>

        <g mask="url(#mask0_629_11212)">
          <g id="Group 306" filter="url(#filter0_d_629_11212)">
            <rect id="Rectangle 302" x="32" y="35" width="192" height="85" rx="8" fill="#D8E0E5" />

            <circle id="Ellipse 93" cx="40" cy="43" r="2" fill="white" />

            <circle id="Ellipse 94" cx="46" cy="43" r="2" fill="white" />

            <circle id="Ellipse 95" cx="52" cy="43" r="2" fill="white" />

            <rect id="Rectangle 303" x="202" y="41" width="16" height="4" rx="2" fill="white" />

            <rect id="Rectangle 304" x="100" y="67" width="56" height="4" rx="2" fill="white" />

            <rect id="Rectangle 305" x="80" y="77" width="96" height="4" rx="2" fill="white" />
          </g>
        </g>
      </g>

      <defs>
        <filter
          id="filter0_d_629_11212"
          x="18"
          y="21"
          width="220"
          height="113"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />

          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />

          <feOffset />

          <feGaussianBlur stdDeviation="7" />

          <feComposite in2="hardAlpha" operator="out" />

          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />

          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_629_11212" />

          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_629_11212"
            result="shape"
          />
        </filter>

        <linearGradient
          id="paint0_linear_629_11212"
          x1="128"
          y1="68"
          x2="128"
          y2="107.492"
          gradientUnits="userSpaceOnUse"
        >
          <stop />

          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Illustration>
  );
});

export const FreshStartIllustrationMetadata = {
  name: 'Fresh Start',
  importName: 'FreshStartIllustration',
  component: <FreshStartIllustration w="156px" h="86px" aria-label="Fresh Start Illustration" />,
  tags: 'start, fresh, new',
};
