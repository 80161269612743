// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { CreateGithubAppIntegration } from '../types/github-integration';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { INTEGRATIONS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  installationId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateGithubAppIntegrationRequestPayloadDto };
export type { RequestPayloadDto as CreateGithubAppIntegrationRequestPayloadDto };

// #######
// Request
// #######

export const createGithubAppIntegration = async (
  model: CreateGithubAppIntegration
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      installationId: model.installationId.trim(),
    });

    return await apiClient.post(`/Integration/InsertGithubAdminIntegration`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateGithubAppIntegration = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useCreateGithubAppIntegration = ({
  onStart,
  onSuccess,
  onError,
}: UseCreateGithubAppIntegration) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: CreateGithubAppIntegration) =>
      await createGithubAppIntegration(model),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: INTEGRATIONS_QUERY_KEYS.integrations(),
      });
    },
    onMutate: () => onStart?.(),
    onSuccess: () => onSuccess?.(),
    onError: (error) => onError?.(error),
  });

  return {
    createAppIntegration: mutate,
    isCreatingAppIntegration: isPending,
    didAppIntegrationCreationErrored: isError,
  };
};
