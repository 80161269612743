// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { uuid4 } from '@/utils/generators';
import { Icon } from './icon';

export const ScanIcon = forwardRef((props: IconProps, ref) => {
  const guid = `scan-icon-${uuid4()}`;

  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref} fill="none">
      <g clipPath={`url(#clip-1-${guid})`}>
        <path
          d="M2.5 5.5V3C2.5 2.72386 2.72386 2.5 3 2.5H5.5M2.5 10.5V13C2.5 13.2761 2.72386 13.5 3 13.5H5.5M13.5 10.5V13C13.5 13.2761 13.2761 13.5 13 13.5H10.5M13.5 5.5V3C13.5 2.72386 13.2761 2.5 13 2.5H10.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <defs>
        <clipPath id={`clip-1-${guid}`}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const ScanIconMetadata = {
  name: 'Scan',
  importName: 'ScanIcon',
  component: <ScanIcon aria-label="Scan Icon" />,
  tags: 'scan, fullscreen, expand, square, zoom, fit, view',
};
