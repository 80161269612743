// App - Types
import type { RequirementCountermeasureSource } from '../requirement-countermeasure-source';

export const RequirementCountermeasureSourcesDto = [
  'CloverBuiltin',
  'DetectedPavelRoad',
  'Manual',
  'UNKNOWN',
] as const;

export type RequirementCountermeasureSourceDto =
  (typeof RequirementCountermeasureSourcesDto)[number];

export const castRequirementCountermeasureSourceDtoToRequirementCountermeasureSource = (
  dto: RequirementCountermeasureSourceDto
): RequirementCountermeasureSource => {
  if (dto === 'CloverBuiltin') return 'clover_builtin';

  if (dto === 'DetectedPavelRoad') return 'detected_pavel_road';

  if (dto === 'Manual') return 'manual';

  return 'UNKNOWN';
};

export const castRequirementCountermeasureSourceToRequirementCountermeasureSourceDto = (
  source: RequirementCountermeasureSource
): RequirementCountermeasureSourceDto => {
  if (source === 'clover_builtin') return 'CloverBuiltin';

  if (source === 'detected_pavel_road') return 'DetectedPavelRoad';

  if (source === 'manual') return 'Manual';

  return 'UNKNOWN';
};
