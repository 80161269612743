// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IllustrationProps } from './illustration';

// App - Other
import Locale from '@/locale/en.json';
import { Illustration } from './illustration';

const locale = Locale.components.atoms.icon.illustrations;

// INSIGHT this illustration is from Guy
export const NoResultsIllustration = forwardRef((props: IllustrationProps, ref) => {
  return (
    <Illustration
      viewBox="0 0 156 126"
      {...props}
      aria-label={props['aria-label'] || locale['No Results Illustration']}
      ref={ref}
      fill="none"
    >
      <g id="Group 666">
        <circle id="Ellipse 77" cx="78" cy="63" r="63" fill="#F5F7F9" />

        <rect
          id="Rectangle 296"
          x="24"
          y="64.5"
          width="108"
          height="34.5"
          rx="6"
          fill="#D8E0E5"
          fillOpacity="0.5"
        />

        <rect
          id="Rectangle 295"
          x="15"
          y="49.5"
          width="126"
          height="40.5"
          rx="6"
          fill="#D8E0E5"
          fillOpacity="0.5"
        />

        <g id="Group 294" filter="url(#filter0_d_612_32735)">
          <g id="Group 292">
            <rect
              id="Rectangle 295_2"
              x="6"
              y="31.5"
              width="144"
              height="46.5"
              rx="6"
              fill="#D8E0E5"
            />

            <g id="Group 291">
              <rect
                id="Rectangle 296_2"
                x="46.5"
                y="46.5"
                width="40.5"
                height="6"
                rx="3"
                fill="#64748B"
                fillOpacity="0.25"
              />

              <rect
                id="Rectangle 297"
                x="46.5"
                y="57"
                width="65"
                height="6"
                rx="3"
                fill="#64748B"
                fillOpacity="0.25"
              />
            </g>
          </g>

          <circle id="Ellipse 76" cx="29.25" cy="54.75" r="11.25" fill="#8A94A6" />

          <g id="Vector">
            <path
              d="M25.5698 61.2771C24.0547 61.2771 22.822 60.0799 22.822 58.6083C22.822 57.9135 23.1037 57.3453 23.6828 56.8712C24.1858 56.46 24.8231 56.1915 25.4364 55.9635C25.8426 55.8122 26.3732 55.6247 26.8733 55.514C28.1045 55.2426 28.9586 54.1525 28.9012 52.9278C28.8766 52.4059 28.9228 51.7979 29.0376 51.1204C29.1844 50.2562 29.5452 49.5048 30.0803 48.9482C30.6765 48.3279 31.4456 48 32.3064 48C34.1115 48 35.5804 49.4259 35.5804 51.1797C35.5804 51.9984 35.2436 52.6679 34.5497 53.226C33.9609 53.7001 33.1896 54.0323 32.4503 54.3313C31.8436 54.5766 31.31 54.7359 30.8636 54.8054C29.7002 54.9863 28.8058 55.9027 28.6821 57.0405C28.6747 57.1093 28.665 57.1802 28.6545 57.2519C28.5741 57.7774 28.425 58.3072 28.3035 58.7089C27.7908 60.4121 26.8718 61.2756 25.5706 61.2756L25.5698 61.2771Z"
              fill="white"
            />

            <path
              d="M32.9384 61.5C31.2473 61.5 30.2233 60.1341 29.8768 58.5265C29.7165 57.7825 29.4535 56.5295 29.9118 56.0098C30.473 55.3728 32.0634 55.5031 32.9384 55.5531C34.6264 55.6501 36 56.8842 36 58.5265C36 60.1689 34.6294 61.5 32.9384 61.5Z"
              fill="white"
            />

            <path
              d="M25.0869 54.3863C23.6843 54.1199 22.5 53.2615 22.5 51.8739C22.5 50.4863 23.6582 49.3615 25.0869 49.3615C26.5156 49.3615 27.2922 50.5363 27.6737 51.8739C27.8571 52.5174 28.2252 53.876 27.7826 54.3269C27.3115 54.8068 25.9193 54.5441 25.0869 54.3863Z"
              fill="white"
            />
          </g>
        </g>
      </g>

      <defs>
        <filter
          id="filter0_d_612_32735"
          x="0"
          y="28.5"
          width="156"
          height="58.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />

          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />

          <feOffset dy="3" />

          <feGaussianBlur stdDeviation="3" />

          <feComposite in2="hardAlpha" operator="out" />

          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.392157 0 0 0 0 0.454902 0 0 0 0 0.545098 0 0 0 0.1 0"
          />

          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_612_32735" />

          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_612_32735"
            result="shape"
          />
        </filter>
      </defs>
    </Illustration>
  );
});

export const NoResultsIllustrationMetadata = {
  name: 'No Results',
  importName: 'NoResultsIllustration',
  component: <NoResultsIllustration w="156px" h="86px" aria-label="No Results Illustration" />,
  tags: 'results, empty, no, nothing',
};
