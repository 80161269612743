'use client';

// React & Next
import type { ReactElement } from 'react';

// 3rd
import { Flex, CloseButton, Stack, SlideFade } from '@chakra-ui/react';

// App - Types
import type { Notification, NotificationType } from '@/stores/notifications';

// App - Other
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';
import { useNotifications } from '@/stores/notifications';

export const Notifications = () => {
  const { notifications, dismissNotification } = useNotifications();

  if (notifications.length < 1) return null;

  return (
    <Flex py="md" px="md" position="fixed" bottom="md" right="none" zIndex="notifications">
      <Flex direction="column" gap="md">
        {notifications.map((notification) => (
          <NotificationToast
            key={notification.id}
            notification={notification}
            onDismiss={dismissNotification}
          />
        ))}
      </Flex>
    </Flex>
  );
};

const notificationVariants: Record<NotificationType, { color: string }> = {
  info: {
    color: 'text.brand.secondary-high-tone',
  },

  success: {
    color: 'text.success',
  },

  warning: {
    color: 'text.caution',
  },

  error: {
    color: 'text.error',
  },
};

const notificationIcons: Record<NotificationType, { icon: ReactElement }> = {
  info: {
    icon: (
      <InfoIcon
        size="xs"
        aria-label="Notification icon"
        color="text.brand.secondary-high-tone"
        transform="translateY(1px)"
      />
    ),
  },

  success: {
    icon: (
      <SuccessIcon
        size="xs"
        aria-label="Notification icon"
        color="text.success"
        transform="translateY(1px)"
      />
    ),
  },

  warning: {
    icon: (
      <WarningIcon
        size="xs"
        aria-label="Notification icon"
        color="text.caution"
        transform="translateY(1px)"
      />
    ),
  },

  error: {
    icon: (
      <ErrorIcon
        size="xs"
        aria-label="Notification icon"
        color="text.error"
        transform="translateY(1px)"
      />
    ),
  },
};

type NotificationToastProps = {
  notification: Omit<Notification, 'duration'>;
  onDismiss: (id: string) => void;
};

export const NotificationToast = ({ notification, onDismiss }: NotificationToastProps) => {
  const { id, type, title, message } = notification;

  return (
    <SlideFade in={true} offsetX="150px">
      <Stack w="375px" boxShadow="elevation-5" borderRadius="md" bg="surface.primary">
        <Stack direction="row" spacing="md" justifyContent="space-between" py="lg" px="lg">
          <Stack direction="row" spacing="sm">
            {notificationIcons[type].icon}

            <Stack spacing="xs">
              <Text variant="label-semibold" {...notificationVariants[type]}>
                {title}
              </Text>

              {notification.message && (
                <Text variant="label" color="text.high-tone">
                  {message}
                </Text>
              )}
            </Stack>
          </Stack>

          <CloseButton
            color="text.high-tone"
            transform="translateY(-6px)"
            onClick={() => onDismiss(id)}
          />
        </Stack>
      </Stack>
    </SlideFade>
  );
};
