// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import {
  RequirementCountermeasureSourcesDto,
  RequirementCountermeasureSnippetTypesDto,
  castRequirementCountermeasureSourceToRequirementCountermeasureSourceDto,
  castRequirementCountermeasureSnippetTypeToRequirementCountermeasureSnippetTypeDto,
} from '@/types/security-framework/requirement/dtos';
import type { RequirementCountermeasure } from '../../types/requirement-countermeasure';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityRequirementsIds: z.string().array(),
  content: z.string(),
  title: z.string(),
  countermeasureSnippetType: z.enum(RequirementCountermeasureSnippetTypesDto),
  countermeasureSource: z.enum(RequirementCountermeasureSourcesDto),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateRequirementCountermeasureRequestPayloadDto };
export type { RequestPayloadDto as CreateRequirementCountermeasureRequestPayloadDto };

// #######
// Request
// #######

export const createRequirementCountermeasure = async (
  requirementId: string,
  countermeasure: RequirementCountermeasure
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      securityRequirementsIds: [requirementId],
      content: countermeasure.content,
      title: countermeasure.title,
      countermeasureSnippetType:
        castRequirementCountermeasureSnippetTypeToRequirementCountermeasureSnippetTypeDto(
          countermeasure.snippetType
        ),
      countermeasureSource: castRequirementCountermeasureSourceToRequirementCountermeasureSourceDto(
        countermeasure.source
      ),
    });

    return await apiClient.post('/SecurityFramework/CreateCountermeasure', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type MutationPayload = {
  requirementId: string;
  countermeasure: RequirementCountermeasure;
};

type UseCreateRequirementCountermeasure = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useCreateRequirementCountermeasure = ({
  onStart,
  onSuccess,
  onError,
}: UseCreateRequirementCountermeasure = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: MutationPayload) =>
      await createRequirementCountermeasure(model.requirementId, model.countermeasure),
    onMutate: () => onStart?.(),
    onSuccess: () => onSuccess?.(),
    onError: (error) => onError?.(error),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      }),
  });

  return {
    createRequirementCountermeasure: mutate,
    isCreatingRequirementCountermeasure: isPending,
    didCreateRequirementCountermeasureErrored: isError,
  };
};
