// 3rd
import { Flex, Skeleton, useToken } from '@chakra-ui/react';

// App - Types
import type { RequirementAntiPattern } from '../../../types/requirement-anti-pattern';

// App - Other
import { Text } from '@/components/atoms/typography';

type RequirementAntiPatternCardProps = {
  antiPattern: RequirementAntiPattern;
};

export const RequirementAntiPatternCard = ({ antiPattern }: RequirementAntiPatternCardProps) => {
  const [defaultOutlineColor] = useToken('colors', ['border.primary']);

  return (
    <Flex
      alignItems="center"
      gap="lg"
      height="fit-content"
      outline={`1px solid ${defaultOutlineColor}`}
      borderRadius="sm"
      py="md"
      ps="lg"
      transition="150ms all ease-in-out"
    >
      <Text variant="detail" color="text.high-tone">
        {antiPattern.content}
      </Text>
    </Flex>
  );
};

const Loading = () => {
  const [defaultOutlineColor] = useToken('colors', ['border.primary']);

  return (
    <Flex
      alignItems="center"
      gap="lg"
      height="fit-content"
      outline={`1px solid ${defaultOutlineColor}`}
      borderRadius="sm"
      py="md"
      ps="lg"
      transition="150ms all ease-in-out"
    >
      <Skeleton w="180px" h="22px" />
    </Flex>
  );
};

RequirementAntiPatternCard.Loading = Loading;
