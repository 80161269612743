// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IllustrationProps } from './illustration';

// App - Other
import Locale from '@/locale/en.json';
import { Illustration } from './illustration';

const locale = Locale.components.atoms.icon.illustrations;

// INSIGHT this illustration is from Guy
export const AllDoneIllustration = forwardRef((props: IllustrationProps, ref) => {
  return (
    <Illustration
      viewBox="0 0 104 84"
      {...props}
      aria-label={props['aria-label'] || locale['All Done Illustration']}
      ref={ref}
      fill="none"
    >
      <rect x="16" y="43" width="72" height="23" rx="4" fill="#D8E0E5" fill-opacity="0.5" />

      <rect x="10" y="33" width="84" height="27" rx="4" fill="#D8E0E5" fill-opacity="0.5" />

      <g filter="url(#filter0_d_630_11417)">
        <rect x="4" y="21" width="96" height="31" rx="4" fill="#D8E0E5" />

        <rect x="31" y="31" width="27" height="4" rx="2" fill="#64748B" fill-opacity="0.25" />

        <rect x="31" y="38" width="43.3333" height="4" rx="2" fill="#64748B" fill-opacity="0.25" />

        <circle cx="19.5" cy="36.5" r="7.5" fill="#8A94A6" />

        <path
          d="M17.0465 40.8514C16.0364 40.8514 15.2146 40.0532 15.2146 39.0722C15.2146 38.609 15.4025 38.2302 15.7885 37.9141C16.1239 37.64 16.5487 37.461 16.9576 37.309C17.2284 37.2081 17.5822 37.0832 17.9155 37.0093C18.7364 36.8284 19.3057 36.1017 19.2675 35.2852C19.2511 34.9373 19.2819 34.5319 19.3584 34.0803C19.4563 33.5041 19.6968 33.0032 20.0535 32.6321C20.451 32.2186 20.9637 32 21.5376 32C22.741 32 23.7203 32.9506 23.7203 34.1198C23.7203 34.6656 23.4957 35.112 23.0331 35.484C22.6406 35.8001 22.1264 36.0216 21.6335 36.2208C21.2291 36.3844 20.8733 36.4906 20.5757 36.5369C19.8001 36.6576 19.2039 37.2685 19.1214 38.027C19.1164 38.0729 19.11 38.1202 19.103 38.1679C19.0494 38.5183 18.95 38.8715 18.869 39.1393C18.5272 40.2747 17.9146 40.8504 17.047 40.8504L17.0465 40.8514Z"
          fill="white"
        />

        <path
          d="M21.9589 41C20.8316 41 20.1489 40.0894 19.9178 39.0177C19.811 38.5216 19.6356 37.6863 19.9412 37.3399C20.3153 36.9152 21.3756 37.0021 21.9589 37.0354C23.0843 37.1 24 37.9228 24 39.0177C24 40.1126 23.0863 41 21.9589 41Z"
          fill="white"
        />

        <path
          d="M16.7246 36.2575C15.7895 36.0799 15 35.5076 15 34.5826C15 33.6575 15.7721 32.9077 16.7246 32.9077C17.677 32.9077 18.1948 33.6908 18.4492 34.5826C18.5714 35.0116 18.8168 35.9173 18.5217 36.2179C18.2077 36.5379 17.2796 36.3627 16.7246 36.2575Z"
          fill="white"
        />
      </g>

      <defs>
        <filter
          id="filter0_d_630_11417"
          x="0"
          y="19"
          width="104"
          height="39"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />

          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />

          <feOffset dy="2" />

          <feGaussianBlur stdDeviation="2" />

          <feComposite in2="hardAlpha" operator="out" />

          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.392157 0 0 0 0 0.454902 0 0 0 0 0.545098 0 0 0 0.1 0"
          />

          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_630_11417" />

          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_630_11417"
            result="shape"
          />
        </filter>
      </defs>
    </Illustration>
  );
});

export const AllDoneIllustrationMetadata = {
  name: 'All Done',
  importName: 'AllDoneIllustration',
  component: <AllDoneIllustration w="156px" h="86px" aria-label="All Done Illustration" />,
  tags: 'all, done, completed',
};
