/* eslint-disable @typescript-eslint/no-unused-vars */
// 3rd
import { delay, http } from 'msw';

// App - Types
import {
  CreateRequirementCountermeasureRequestPayloadDto,
  RequirementDto,
  RequirementCountermeasureDto,
} from '@/features/security-frameworks';
import {
  ZodCreateRequirementCountermeasureRequestPayloadDto,
  ZodRequirementDto,
  ZodRequirementCountermeasureDto,
} from '@/features/security-frameworks';

// App - Other
import { API_URL } from '@/config/constants';
import { uuid4 } from '@/utils/generators';
import { db } from '../../db';

export const createCountermeasureHandler = http.post(
  `${API_URL}/SecurityFramework/CreateCountermeasure`,
  async ({ request }) => {
    const payload = await request.json();
    const parsedPayload: CreateRequirementCountermeasureRequestPayloadDto =
      ZodCreateRequirementCountermeasureRequestPayloadDto.parse(payload);

    const requirements = parsedPayload.securityRequirementsIds.map((id) =>
      db.requirement.findFirst({
        where: {
          id: {
            equals: id,
          },
        },
      })
    );
    const parsedRequirement: RequirementDto[] = requirements.map((requirement) =>
      ZodRequirementDto.passthrough().parse(requirement)
    );

    if (!parsedRequirement) {
      return new Response(null, {
        status: 404,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    const countermeasure = db.countermeasure.create({
      id: uuid4(),
      content: parsedPayload.content,
      title: parsedPayload.title,
      countermeasureSource: parsedPayload.countermeasureSource,
      countermeasureSnippetType: parsedPayload.countermeasureSnippetType,
      securityRequirementsIds: parsedPayload.securityRequirementsIds,
    });
    const parsedCountermeasure: RequirementCountermeasureDto =
      ZodRequirementCountermeasureDto.passthrough().parse(countermeasure);

    const updatedRequirements = parsedRequirement.map((requirement) =>
      db.requirement.update({
        where: {
          id: {
            equals: requirement.id,
          },
        },
        data: {
          countermeasures: [...(requirement!.countermeasures || []), countermeasure], // INSIGHT <=== Using here original objects and not parsed ones as Zod strips the prototype of objects by default and there is no elegant workaround.
        },
      })
    );
    const parsedUpdatedRequirement: RequirementDto[] = updatedRequirements.map(
      (updatedRequirement) => ZodRequirementDto.parse(updatedRequirement)
    );
    await delay(1000);

    return new Response(null, {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
