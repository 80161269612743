// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SendMissingInformationSlackMessage } from '../../types/missing-information-feedback';
import {
  ZodSendMissingInformationSlackMessageDto,
  castSendMissingInformationSlackMessageToSendMissingInformationSlackMessageDto,
} from './dtos/send-missing-information-slack-message';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = ZodSendMissingInformationSlackMessageDto;

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodSendMissingInformationSlackMessageRequestPayloadDto };
export type { RequestPayloadDto as SendMissingInformationSlackMessageRequestPayloadDto };

// #######
// Request
// #######

export const sendMissingInformationSlackMessageFeedback = async (
  model: SendMissingInformationSlackMessage
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      castSendMissingInformationSlackMessageToSendMissingInformationSlackMessageDto(model)
    );
    await apiClient.post(`/Slack/SendMissingInformationMessage`, payload);

    return;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseSendMissingInformationSlackMessageFeedback = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useSendMissingInformationSlackMessageFeedback = ({
  onStart,
  onSuccess,
  onError,
}: UseSendMissingInformationSlackMessageFeedback = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (sendMessagePayload: SendMissingInformationSlackMessage) =>
      await sendMissingInformationSlackMessageFeedback(sendMessagePayload),
    onMutate: () => onStart?.(),
    onSuccess: () => onSuccess?.(),
    onError: (error) => onError?.(error),
  });

  return {
    sendMissingInformationSlackMessage: mutate,
    isSendingMissingInformationSlackMessage: isPending,
    didSendMissingInformationSlackMessageErrored: isError,
  };
};
