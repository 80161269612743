// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const FigmaIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 7.678 7.68" {...props} ref={ref} fill="none">
      <path
        d="M2.56 7.68A1.28 1.28 0 0 0 3.84 6.4V5.12H2.56a1.28 1.28 0 0 0 0 2.56z"
        fill="#0acf83"
      />

      <path
        d="M1.28 3.84a1.28 1.28 0 0 1 1.28-1.28h1.28v2.56H2.56a1.28 1.28 0 0 1-1.28-1.28z"
        fill="#a259ff"
      />

      <path
        d="M1.28 1.28A1.28 1.28 0 0 1 2.559 0h1.28v2.56H2.56a1.28 1.28 0 0 1-1.28-1.28z"
        fill="#f24e1e"
      />

      <path d="M3.84 0h1.28a1.28 1.28 0 0 1 0 2.56H3.84z" fill="#ff7262" />

      <path d="M6.4 3.84a1.28 1.28 0 0 1-2.56 0 1.28 1.28 0 0 1 2.56 0z" fill="#1abcfe" />
    </Icon>
  );
});

export const FigmaIconMetadata = {
  name: 'Figma Logo',
  importName: 'FigmaIcon',
  component: <FigmaIcon aria-label="Figma Icon" />,
  tags: 'figma, logo, design, mockup',
};
