// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const AsanaIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 256 237" {...props} ref={ref} fill="none">
      <g>
        <path
          d="M200.324957,125.270044 C169.575962,125.270044 144.649915,150.19729 144.649915,180.947483 C144.649915,211.696478 169.575962,236.623724 200.324957,236.623724 C231.073952,236.623724 256,211.696478 256,180.947483 C256,150.19729 231.073952,125.270044 200.324957,125.270044 L200.324957,125.270044 Z M55.6754021,125.274837 C24.9270063,125.274837 0,150.19729 0,180.947483 C0,211.696478 24.9270063,236.623724 55.6754021,236.623724 C86.425116,236.623724 111.35332,211.696478 111.35332,180.947483 C111.35332,150.19729 86.425116,125.274837 55.6754021,125.274837 L55.6754021,125.274837 Z M183.674444,55.674204 C183.674444,86.425116 158.748396,111.354638 128.000599,111.354638 C97.2505258,111.354638 72.3247177,86.425116 72.3247177,55.674204 C72.3247177,24.9294026 97.2505258,0 128.000599,0 C158.748396,0 183.674444,24.9294026 183.674444,55.674204 L183.674444,55.674204 Z"
          fill="#F06A6A"
        />
      </g>
    </Icon>
  );
});

export const AsanaIconMetadata = {
  name: 'Asana Logo',
  importName: 'AsanaIcon',
  component: <AsanaIcon aria-label="Asana Icon" />,
  tags: 'asana, logo',
};
