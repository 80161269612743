import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@frontegg/nextjs/app/ClientFronteggProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@frontegg/nextjs/common/FronteggRouterBase.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/molecules/notifications/notifications.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AxiosProvider"] */ "/vercel/path0/src/providers/axios.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChakraProvider"] */ "/vercel/path0/src/providers/chakra.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DatadogProvider"] */ "/vercel/path0/src/providers/datadog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundaryProvider"] */ "/vercel/path0/src/providers/error-boundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MSWProvider"] */ "/vercel/path0/src/providers/msw.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsProvider"] */ "/vercel/path0/src/providers/nuqs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryProvider"] */ "/vercel/path0/src/providers/react-query.tsx");
