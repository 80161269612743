// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import {
  SecurityControlImportanceLevelsDto,
  castSecurityControlImportanceLevelToSecurityControlImportanceLevelDto,
} from '@/types/security-framework/dtos';
import type { CreateSecurityControl } from '../types/security-control';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  controlGroup: z.string().optional(),
  controlName: z.string(),
  description: z.string(),
  importanceScore: z.enum(SecurityControlImportanceLevelsDto),
  securityFrameworkId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateSecurityControlRequestPayloadDto };
export type { RequestPayloadDto as CreateSecurityControlRequestPayloadDto };

// #######
// Request
// #######

export const createSecurityControl = async (
  frameworkId: string,
  model: CreateSecurityControl
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      controlName: model.name,
      description: model.description,
      importanceScore: castSecurityControlImportanceLevelToSecurityControlImportanceLevelDto(
        model.importance
      ),
      securityFrameworkId: frameworkId,
    });

    return await apiClient.post('/SecurityFramework/CreateSecurityControl', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateSecurityControl = {
  frameworkId: string;
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useCreateSecurityControl = ({
  frameworkId,
  onStart,
  onSuccess,
  onError,
}: UseCreateSecurityControl) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: CreateSecurityControl) =>
      await createSecurityControl(frameworkId, model),
    onMutate: () => onStart?.(),
    onSuccess: () => onSuccess?.(),
    onError: (error) => onError?.(error),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      }),
  });

  return {
    createSecurityControl: mutate,
    isCreatingSecurityControl: isPending,
    didCreateSecurityControlErrored: isError,
  };
};
