// App - Types
import type { RequirementSeverity } from '@/types/security-framework/requirement';

export const translateRequirementSeverityToColor = (severity: RequirementSeverity): string => {
  if (severity === 'high') return '#EC8C7E';

  if (severity === 'medium') return '#FFCA45';

  if (severity === 'low') return '#82B8FE';

  return '#D7E1EE';
};

export const translateRequirementSeverityToColorNEW = (severity: RequirementSeverity): string => {
  if (severity === 'high') return '#E68376';

  if (severity === 'medium') return '#FC9405';

  if (severity === 'low') return '#699AC7';

  return '#D7E1EE';
};

export const translateRequirementSeverityToBgColor = (severity: RequirementSeverity): string => {
  if (severity === 'high') return '#FDE2E2';

  if (severity === 'medium') return '#FDFAE2';

  if (severity === 'low') return '#E2F0FD';

  return '#D7E1EE';
};

export const translateRequirementSeverityToBgColorNEW = (severity: RequirementSeverity): string => {
  if (severity === 'high') return '#F9E0DD';

  if (severity === 'medium') return '#FFF4E6';

  if (severity === 'low') return '#F0F5F9';

  return '#F8FAFC';
};

export const translateRequirementSeverityToBgContrastColorNEW = (
  severity: RequirementSeverity
): string => {
  if (severity === 'high') return '#F6D2CE';

  if (severity === 'medium') return '#FFE6C4';

  if (severity === 'low') return '#DCE7F2';

  return '#D7E1EE';
};
