// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const LucidchartIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 64 64" {...props} ref={ref} fill="#de8b30">
      <path d="M63.727 55.613c0-4.558-3.647-8.387-8.387-8.387s-8.387 3.647-8.387 8.387S50.598 64 55.34 64s8.387-3.83 8.387-8.387zM55.52 16.775c-4.558 0-8.387-3.647-8.387-8.387S50.78 0 55.52 0s8.387 3.647 8.387 8.387-3.83 8.387-8.387 8.387zM40.205 55.613c0 4.558-3.647 8.387-8.387 8.387-4.558 0-8.387-3.647-8.387-8.387 0-8.387-6.93-15.316-15.316-15.316C3.738 40.296.1 36.65.1 32.1s3.647-8.387 8.387-8.387c8.387 0 15.316-6.93 15.316-15.316C23.613 3.83 27.442.182 32 .182s8.205 3.647 8.205 8.387-3.647 8.387-8.387 8.387c-8.387 0-15.316 6.93-15.316 15.316S23.248 47.6 31.818 47.6c4.74-.365 8.387 3.464 8.387 8.023z" />
    </Icon>
  );
});

export const LucidchartIconMetadata = {
  name: 'Lucidchart Logo',
  importName: 'LucidchartIcon',
  component: <LucidchartIcon aria-label="Lucidchart Icon" />,
  tags: 'lucidchart, logo',
};
