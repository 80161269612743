// 3rd
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { ZodEntityIdDto, castEntityIdToEntityIdDto } from '@/types/entity/dtos';
import type { EntityId } from '@/types/entity';
import { isValidEntityId } from '@/types/entity';
import type { Feedback } from '../types/feedback';
import { ZodFeedbackDto, castFeedbackDtoToFeedback } from './dtos/feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { FEEDBACK_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  entityId: ZodEntityIdDto,
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodGetFeedbackRequestPayloadDto };
export type { RequestPayloadDto as GetFeedbackRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityFeedbacks: z
    .array(ZodFeedbackDto)
    .catch((e) => {
      console.error('get feedback response parsing errored! see: ', e);

      return [];
    })
    .default([]),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodGetFeedbackResponseDto };
export type { ResponseDto as GetFeedbackResponseDto };

// #######
// Request
// #######

export const getFeedback = async (entityId: EntityId): Promise<Feedback[]> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      entityId: castEntityIdToEntityIdDto(entityId),
    });
    const res = await apiClient.post(`/SecurityFeedback/GetDocumentSecurityFeedbacks`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityFeedbacks.map((feedbackDto) =>
      castFeedbackDtoToFeedback(feedbackDto, entityId)
    );
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return [];
    }

    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseFeedback = {
  entityId?: EntityId;
  enabled?: boolean;
};

export const useFeedback = ({ entityId, enabled = true }: UseFeedback = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: FEEDBACK_QUERY_KEYS.entityFeedback(entityId!),
    queryFn: async () => await getFeedback(entityId!),
    enabled: enabled && !!entityId && isValidEntityId(entityId),
  });

  return {
    feedback: data,
    isFetchingFeedback: isFetching,
    isLoadingFeedback: isLoading,
    didFetchFeedbackErrored: isLoadingError,
  };
};
