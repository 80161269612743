// App - Types
import type { KnowledgeSource } from '../integration/knowledge-source';

export const EntityTypes = [
  'azure_devops_pull_request',
  'azure_devops_wiki_page',
  'azure_devops_work_item',
  'bitbucket_pull_request',
  'confluence_page',
  'github_issue',
  'github_pull_request',
  'google_drive',
  'jira_issue',
  'notion_page',
  'share_point',
  'trello_card',
  'service_now_task',
  'UNKNOWN',
] as const;

export type EntityType = (typeof EntityTypes)[number];

export const castEntityTypeToKnowledgeSource = (type: EntityType): KnowledgeSource => {
  if (type === 'azure_devops_wiki_page') return 'azure_devops_wiki_page';

  if (type === 'azure_devops_work_item') return 'azure_devops_work_item';

  if (type === 'azure_devops_pull_request') return 'azure_devops_repository';

  if (type === 'confluence_page') return 'confluence';

  if (type === 'github_issue' || type === 'github_pull_request') return 'github';

  if (type === 'google_drive') return 'google_drive';

  if (type === 'jira_issue') return 'jira';

  if (type === 'notion_page') return 'notion';

  if (type === 'share_point') return 'share_point';

  if (type === 'trello_card') return 'trello';

  if (type === 'service_now_task') return 'service_now';

  return 'UNKNOWN';
};

export const attemptToCastKnowledgeSourceToEntityType = (source: KnowledgeSource): EntityType => {
  if (source === 'azure_devops_wiki_page') return 'azure_devops_wiki_page';

  if (source === 'azure_devops_work_item') return 'azure_devops_work_item';

  if (source === 'azure_devops_repository') return 'azure_devops_pull_request';

  if (source === 'confluence') return 'confluence_page';

  if (source === 'github') return 'github_pull_request';

  if (source === 'google_drive') return 'google_drive';

  if (source === 'jira') return 'jira_issue';

  if (source === 'notion') return 'notion_page';

  if (source === 'share_point') return 'share_point';

  if (source === 'trello') return 'trello_card';

  if (source === 'service_now') return 'service_now_task';

  return 'UNKNOWN';
};
