// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { RequirementActivityStatusesDto } from '@/types/security-framework/requirement/dtos';
import {
  RequirementSeveritiesDto,
  castRequirementSeverityToRequirementSeverityDto,
} from '@/types/security-framework/requirement/dtos/requirement-severity';
import type { SecurityFramework } from '../../types/security-framework';
import type { UpdateRequirement } from '../../types/requirement';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  requirementId: z.string(),
  activityStatus: z.enum(RequirementActivityStatusesDto).optional(),
  content: z.string().optional(),
  description: z.string().optional(),
  severity: z.enum(RequirementSeveritiesDto).optional(),
  cwes: z.string().array().optional(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodUpdateRequirementRequestPayloadDto };
export type { RequestPayloadDto as UpdateRequirementRequestPayloadDto };

// #######
// Request
// #######

export const updateRequirement = async (model: UpdateRequirement): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      requirementId: model.id,
      ...(model.isActive !== undefined
        ? { activityStatus: model.isActive ? 'Active' : 'Inactive' }
        : {}),
      ...(model.content ? { content: model.content } : {}),
      ...(model.severity
        ? { severity: castRequirementSeverityToRequirementSeverityDto(model.severity) }
        : {}),
      ...(model.description ? { description: model.description } : {}),
      ...(model.cwes ? { cwes: model.cwes } : {}),
    });

    return await apiClient.post('/SecurityFramework/UpdateSecurityRequirement', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseUpdateRequirement = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useUpdateRequirement = ({
  onStart,
  onSuccess,
  onError,
}: UseUpdateRequirement = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: UpdateRequirement) => await updateRequirement(model),
    onMutate: async (model) => {
      await queryClient.cancelQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      });

      const previousFrameworks = queryClient.getQueryData(
        SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks() || []
      ) as SecurityFramework[];

      const updatedFrameworks = previousFrameworks.map((framework) => {
        const updatedControls = framework.controls.map((control) => {
          const updatedRequirements = control.requirements.map((req) => {
            const { id, ...updatedProps } = model;

            if (req.id === id) {
              return {
                ...req,
                ...updatedProps,
              };
            }

            return req;
          });

          return {
            ...control,
            requirements: updatedRequirements,
          };
        });

        return {
          ...framework,
          controls: updatedControls,
        };
      });

      queryClient.setQueryData(
        SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
        updatedFrameworks
      );

      onStart?.();

      return { previousFrameworks, updatedFrameworks };
    },
    onSuccess: () => onSuccess?.(),
    onError: (error, model, context) => {
      queryClient.setQueryData(
        SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
        context!.previousFrameworks
      );

      onError?.(error);
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      }),
  });

  return {
    updateRequirement: mutate,
    isUpdating: isPending,
    didUpdateErrored: isError,
  };
};
