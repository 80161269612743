// App - Types
import type { SecurityControlImportanceLevel } from '@/types/security-framework';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators['security-framework']['importance-level'];

export const translateSecurityControlImportanceLevel = (
  importanceLevel: SecurityControlImportanceLevel
): string => {
  if (importanceLevel === 'high') return locale['High'];

  if (importanceLevel === 'medium') return locale['Medium'];

  if (importanceLevel === 'low') return locale['Low'];

  return locale['Unknown'];
};
