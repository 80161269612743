// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const MiroIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 256 256" {...props} ref={ref} fill="none">
      <g>
        <path
          d="M0,64 C0,28.65376 28.65376,0 64,0 L192,0 C227.3464,0 256,28.65376 256,64 L256,192 C256,227.3464 227.3464,256 192,256 L64,256 C28.65376,256 0,227.3464 0,192 L0,64 Z"
          fill="#FFDD33"
        />

        <polygon
          fill="#000000"
          points="170.1952 48.8 146.956 48.8 166.3216 82.8256 123.7168 48.8 100.4784 48.8 121.7808 90.388 77.23904 48.8 54 48.8 77.23904 101.7368 54 207.6 77.23904 207.6 121.7808 94.1744 100.4784 207.6 123.7168 207.6 166.3216 86.612 146.956 207.6 170.1952 207.6 212.8 75.26344"
        />
      </g>
    </Icon>
  );
});

export const MiroIconMetadata = {
  name: 'Miro Logo',
  importName: 'MiroIcon',
  component: <MiroIcon aria-label="Miro Icon" />,
  tags: 'miro, logo, diagram',
};
