// 3rd
import { z } from 'zod';

// App - Types
import { ZodEntityIdDto, castEntityIdToEntityIdDto } from '@/types/entity/dtos';
import { isAllowedToInsertParagraphFeedbackForSource } from '../../../types/feedback';
import type { InsertSecurityParagraphFeedback } from '../../../types/security-feedback';

export const ZodInsertSecurityParagraphFeedbackDto = z.object({
  securityRequirementIds: z.string().array(),
  securityStoryThreatIds: z.string().array(),
  securityStoryId: z.string(),
  entityId: ZodEntityIdDto,
});

export type InsertSecurityParagraphFeedbackDto = z.infer<
  typeof ZodInsertSecurityParagraphFeedbackDto
>;

export const castInsertSecurityParagraphFeedbackToInsertSecurityParagraphFeedbackDto = (
  storyId: string,
  payload: InsertSecurityParagraphFeedback
): InsertSecurityParagraphFeedbackDto => {
  if (!isAllowedToInsertParagraphFeedbackForSource(payload.recipientEntityId.source))
    throw new Error(
      `Feedback creation is not allowed for this source => ${payload.recipientEntityId.source}.`
    );

  return {
    entityId: castEntityIdToEntityIdDto(payload.recipientEntityId),
    securityStoryId: storyId,
    securityRequirementIds: payload.requirementIds || [],
    securityStoryThreatIds: payload.threatIds || [],
  };
};
