export const RequirementActivityStatusesDto = [
  'Active',
  'Inactive',
  'Deleted',
  'Deprecated',
] as const;

export type RequirementActivityStatusDto = (typeof RequirementActivityStatusesDto)[number];

export const castRequirementActivityStatusDtoToBoolean = (
  dto: RequirementActivityStatusDto
): boolean => {
  return dto === 'Active';
};
