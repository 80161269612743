// 3rd
import { z } from 'zod';

// App - Types
import { ZodEntityIdDto, castEntityIdToEntityIdDto } from '@/types/entity/dtos';
import { isAllowedToSendCommentFeedbackForSource } from '../../../types/feedback';
import type { SendSecurityCommentFeedback } from '../../../types/security-feedback';

export const ZodSendSecurityCommentFeedbackDto = z.object({
  securityRequirementIds: z.string().array(),
  securityStoryThreatIds: z.string().array(),
  securityStoryId: z.string(),
  entityId: ZodEntityIdDto,
});

export type SendSecurityCommentFeedbackDto = z.infer<typeof ZodSendSecurityCommentFeedbackDto>;

export const castSendSecurityCommentFeedbackToSendSecurityCommentFeedbackDto = (
  storyId: string,
  payload: SendSecurityCommentFeedback
): SendSecurityCommentFeedbackDto => {
  if (!isAllowedToSendCommentFeedbackForSource(payload.recipientEntityId.source))
    throw new Error(
      `Feedback creation is not allowed for this source => ${payload.recipientEntityId.source}.`
    );

  return {
    entityId: castEntityIdToEntityIdDto(payload.recipientEntityId),
    securityStoryId: storyId,
    securityRequirementIds: payload.requirementIds || [],
    securityStoryThreatIds: payload.threatIds || [],
  };
};
