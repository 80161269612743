// 3rd
import { primaryKey } from '@mswjs/data';

export const feedbackModels = {
  securityFeedback: {
    entityId: Object,
    content: String,
    id: primaryKey(String),
    lastModified: String,
  },
};
