// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { uuid4 } from '@/utils/generators';
import { Icon } from './icon';

export const FiberyIcon = forwardRef((props: IconProps, ref) => {
  const guid = `fibery-icon-${uuid4()}`;

  return (
    <Icon viewBox="0 0 20 20" {...props} ref={ref} fill="none">
      <path
        d="M4.35012 17.4731C3.92724 17.4721 3.51262 17.3547 3.15066 17.1336C2.7887 16.9125 2.49304 16.596 2.29535 16.218C2.09766 15.8399 2.00539 15.4147 2.02842 14.9877C2.05146 14.5607 2.18892 14.1481 2.4261 13.7941L11.0796 0.950198C11.4387 0.462375 11.9702 0.133648 12.5633 0.0326368C13.1563 -0.0683749 13.765 0.0661353 14.2624 0.408089C14.7597 0.750043 15.1072 1.27301 15.2323 1.86783C15.3574 2.46265 15.2505 3.08335 14.9338 3.60035L6.28032 16.4443C6.0661 16.7621 5.77819 17.022 5.44172 17.2014C5.10526 17.3807 4.73048 17.474 4.35012 17.4731Z"
        fill={`url(#gradient-1-${guid})`}
      />

      <path
        d="M4.35012 17.4731C3.92724 17.4721 3.51262 17.3547 3.15066 17.1336C2.7887 16.9125 2.49304 16.596 2.29535 16.218C2.09766 15.8399 2.00539 15.4147 2.02842 14.9877C2.05146 14.5607 2.18892 14.1481 2.4261 13.7941L11.0796 0.950198C11.4387 0.462375 11.9702 0.133648 12.5633 0.0326368C13.1563 -0.0683749 13.765 0.0661353 14.2624 0.408089C14.7597 0.750043 15.1072 1.27301 15.2323 1.86783C15.3574 2.46265 15.2505 3.08335 14.9338 3.60035L6.28032 16.4443C6.0661 16.7621 5.77819 17.022 5.44172 17.2014C5.10526 17.3807 4.73048 17.474 4.35012 17.4731Z"
        fill={`url(#gradient-2-${guid})`}
      />

      <path
        d="M9.01926 2.41076L7.89933 1.19029C7.47839 0.731151 6.89431 0.459907 6.27559 0.436227C5.65687 0.412546 5.05418 0.638368 4.60012 1.06402C4.14606 1.48966 3.87781 2.08027 3.85439 2.7059C3.83097 3.33154 4.0543 3.94096 4.47524 4.4001L5.05787 5.03476L7.36941 4.85954L9.01926 2.41076Z"
        fill={`url(#gradient-3-${guid})`}
      />

      <path
        d="M9.01926 2.41076L7.89933 1.19029C7.47839 0.731151 6.89431 0.459907 6.27559 0.436227C5.65687 0.412546 5.05418 0.638368 4.60012 1.06402C4.14606 1.48966 3.87781 2.08027 3.85439 2.7059C3.83097 3.33154 4.0543 3.94096 4.47524 4.4001L5.05787 5.03476L7.36941 4.85954L9.01926 2.41076Z"
        fill={`url(#gradient-4-${guid})`}
      />

      <path
        d="M14.9412 5.1869L17.4844 4.99412C17.6137 4.98433 17.7432 4.98548 17.8717 4.99739C18.0058 5.00932 18.1394 5.03304 18.2711 5.06873C18.8687 5.2307 19.3782 5.62608 19.6875 6.16791C19.9968 6.70974 20.0806 7.35362 19.9205 7.95792C19.7603 8.56221 19.3693 9.07742 18.8334 9.39019L7.75395 15.8545L14.9412 5.1869Z"
        fill={`url(#gradient-5-${guid})`}
      />

      <path
        d="M14.9412 5.1869L17.4844 4.99412C17.6137 4.98433 17.7432 4.98548 17.8717 4.99739C18.0058 5.00932 18.1394 5.03304 18.2711 5.06873C18.8687 5.2307 19.3782 5.62608 19.6875 6.16791C19.9968 6.70974 20.0806 7.35362 19.9205 7.95792C19.7603 8.56221 19.3693 9.07742 18.8334 9.39019L7.75395 15.8545L14.9412 5.1869Z"
        fill={`url(#gradient-6-${guid})`}
      />

      <path
        d="M10.7176 20.0001C10.2183 20.001 9.73185 19.8398 9.32977 19.5405C8.92768 19.2411 8.63115 18.8192 8.48372 18.3368L7.99997 16.7489L10.539 15.2675L12.7046 16.128L12.9546 16.9462C13.1366 17.5441 13.0765 18.1905 12.7874 18.7436C12.4984 19.2966 12.004 19.711 11.4129 19.8957C11.1876 19.9653 10.9532 20.0005 10.7176 20.0001Z"
        fill={`url(#gradient-7-${guid})`}
      />

      <path
        d="M10.7176 20.0001C10.2183 20.001 9.73185 19.8398 9.32977 19.5405C8.92768 19.2411 8.63115 18.8192 8.48372 18.3368L7.99997 16.7489L10.539 15.2675L12.7046 16.128L12.9546 16.9462C13.1366 17.5441 13.0765 18.1905 12.7874 18.7436C12.4984 19.2966 12.004 19.711 11.4129 19.8957C11.1876 19.9653 10.9532 20.0005 10.7176 20.0001Z"
        fill={`url(#gradient-8-${guid})`}
      />

      <path
        d="M16.6996 11.6731L11.5238 14.6929L15.7863 16.3866C16.0583 16.4924 16.3475 16.5453 16.6389 16.5425V16.541C17.182 16.5407 17.708 16.3487 18.1262 15.9982C18.5444 15.6477 18.8285 15.1607 18.9295 14.6211C19.0305 14.0814 18.9422 13.5231 18.6796 13.0423C18.4171 12.5615 17.9969 12.1884 17.4914 11.9874L16.6996 11.6731Z"
        fill={`url(#gradient-9-${guid})`}
      />
      <path
        d="M16.6996 11.6731L11.5238 14.6929L15.7863 16.3866C16.0583 16.4924 16.3475 16.5453 16.6389 16.5425V16.541C17.182 16.5407 17.708 16.3487 18.1262 15.9982C18.5444 15.6477 18.8285 15.1607 18.9295 14.6211C19.0305 14.0814 18.9422 13.5231 18.6796 13.0423C18.4171 12.5615 17.9969 12.1884 17.4914 11.9874L16.6996 11.6731Z"
        fill={`url(#gradient-10-${guid})`}
      />

      <path
        d="M6.72947 5.80936L3.37028 10.7952L2.51153 10.8603C2.45294 10.865 2.39436 10.8665 2.33577 10.8665C1.73154 10.8674 1.15056 10.6311 0.71518 10.2074C0.279796 9.78379 0.0239803 9.20583 0.00160189 8.59526C-0.0207765 7.9847 0.192028 7.38917 0.595205 6.93409C0.998382 6.47901 1.56047 6.19989 2.1631 6.1555L6.72947 5.80936Z"
        fill={`url(#gradient-11-${guid})`}
      />

      <path
        d="M6.72947 5.80936L3.37028 10.7952L2.51153 10.8603C2.45294 10.865 2.39436 10.8665 2.33577 10.8665C1.73154 10.8674 1.15056 10.6311 0.71518 10.2074C0.279796 9.78379 0.0239803 9.20583 0.00160189 8.59526C-0.0207765 7.9847 0.192028 7.38917 0.595205 6.93409C0.998382 6.47901 1.56047 6.19989 2.1631 6.1555L6.72947 5.80936Z"
        fill={`url(#gradient-12-${guid})`}
      />

      <defs>
        <linearGradient
          id={`gradient-1-${guid}`}
          x1="10"
          y1="-0.000137329"
          x2="10"
          y2="20.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F2F4" />

          <stop offset="1" stopColor="#E5E8EB" />
        </linearGradient>

        <linearGradient
          id={`gradient-2-${guid}`}
          x1="10"
          y1="-0.000137329"
          x2="10"
          y2="20.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7FE8D4" />

          <stop offset="1" stopColor="#7FE8D4" />
        </linearGradient>

        <linearGradient
          id={`gradient-3-${guid}`}
          x1="10"
          y1="-0.000137329"
          x2="10"
          y2="20.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F2F4" />

          <stop offset="1" stopColor="#E5E8EB" />
        </linearGradient>

        <linearGradient
          id={`gradient-4-${guid}`}
          x1="10"
          y1="-0.000137329"
          x2="10"
          y2="20.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7FE8D4" />

          <stop offset="1" stopColor="#7FE8D4" />
        </linearGradient>

        <linearGradient
          id={`gradient-5-${guid}`}
          x1="10"
          y1="-0.000137329"
          x2="10"
          y2="20.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F2F4" />

          <stop offset="1" stopColor="#E5E8EB" />
        </linearGradient>

        <linearGradient
          id={`gradient-6-${guid}`}
          x1="10"
          y1="-0.000137329"
          x2="10"
          y2="20.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7FE8D4" />

          <stop offset="1" stopColor="#7FE8D4" />
        </linearGradient>

        <linearGradient
          id={`gradient-7-${guid}`}
          x1="10"
          y1="-0.000137329"
          x2="10"
          y2="20.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F2F4" />

          <stop offset="1" stopColor="#E5E8EB" />
        </linearGradient>

        <linearGradient
          id={`gradient-8-${guid}`}
          x1="10"
          y1="-0.000137329"
          x2="10"
          y2="20.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7FE8D4" />

          <stop offset="1" stopColor="#7FE8D4" />
        </linearGradient>

        <linearGradient
          id={`gradient-9-${guid}`}
          x1="10"
          y1="-0.000137329"
          x2="10"
          y2="20.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F2F4" />

          <stop offset="1" stopColor="#E5E8EB" />
        </linearGradient>

        <linearGradient
          id={`gradient-10-${guid}`}
          x1="10"
          y1="-0.000137329"
          x2="10"
          y2="20.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7FE8D4" />

          <stop offset="1" stopColor="#7FE8D4" />
        </linearGradient>

        <linearGradient
          id={`gradient-11-${guid}`}
          x1="10"
          y1="-0.000137329"
          x2="10"
          y2="20.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F2F4" />

          <stop offset="1" stopColor="#E5E8EB" />
        </linearGradient>

        <linearGradient
          id={`gradient-12-${guid}`}
          x1="10"
          y1="-0.000137329"
          x2="10"
          y2="20.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7FE8D4" />

          <stop offset="1" stopColor="#7FE8D4" />
        </linearGradient>
      </defs>
    </Icon>
  );
});

export const FiberyIconMetadata = {
  name: 'Fibery Logo',
  importName: 'FiberyIcon',
  component: <FiberyIcon aria-label="Fibery Icon" />,
  tags: 'fibery, logo',
};
