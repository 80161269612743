// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { InsertSecurityParagraphFeedback } from '../../types/security-feedback';
import {
  ZodInsertSecurityParagraphFeedbackDto,
  castInsertSecurityParagraphFeedbackToInsertSecurityParagraphFeedbackDto,
} from './dtos/insert-security-paragraph-feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = ZodInsertSecurityParagraphFeedbackDto;

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodInsertSecurityParagraphFeedbackRequestPayloadDto };
export type { RequestPayloadDto as InsertSecurityParagraphFeedbackRequestPayloadDto };

// #######
// Request
// #######

export const insertSecurityParagraphFeedback = async (
  storyId: string,
  model: InsertSecurityParagraphFeedback
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      castInsertSecurityParagraphFeedbackToInsertSecurityParagraphFeedbackDto(storyId, model)
    );

    return await apiClient.post(`/SecurityFeedback/AppendParagraphFeedback`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseInsertSecurityParagraphFeedback = {
  storyId: string;
  onStart?: () => void;
  onSuccess?: (payload: InsertSecurityParagraphFeedback) => void;
  onError?: (error: Error) => void;
};

export const useInsertSecurityParagraphFeedback = ({
  storyId,
  onStart,
  onSuccess,
  onError,
}: UseInsertSecurityParagraphFeedback) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (insertParagraphFeedbackPayload: InsertSecurityParagraphFeedback) => {
      await insertSecurityParagraphFeedback(storyId, insertParagraphFeedbackPayload);

      return insertParagraphFeedbackPayload;
    },
    onMutate: () => onStart?.(),
    onSuccess: (payload) => onSuccess?.(payload),
    onError: (error) => onError?.(error),
  });

  return {
    insertSecurityParagraph: mutate,
    isInsertingSecurityParagraph: isPending,
    didInsertSecurityParagraphErrored: isError,
  };
};
