// App - Types
import type { RequirementAntiPatternType } from '@/types/security-framework/requirement';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators['security-framework']['anti-pattern-type'];

export const translateRequirementAntiPatternType = (type: RequirementAntiPatternType): string => {
  if (type === 'architecture') return locale['Architecture'];

  if (type === 'code') return locale['Code'];

  if (type === 'configuration') return locale['Configuration'];

  if (type === 'documentation') return locale['Documentation'];

  return locale['Unknown'];
};
