// 3rd
import { z } from 'zod';

// App - Types
import {
  SecurityFrameworkTypesDto,
  SecurityFrameworkSourcesDto,
  SecurityFrameworkActivityStatusesDto,
  castSecurityFrameworkActivityStatusDtoToBoolean,
  castSecurityFrameworkTypeDtoToSecurityFrameworkType,
  castSecurityFrameworkSourceDtoToSecurityFrameworkSource,
} from '@/types/security-framework/dtos';
import type { SecurityFramework } from '../../types/security-framework';
import { ZodSecurityControlDto, castSecurityControlDtoToSecurityControl } from './security-control';

export const ZodSecurityFrameworkDto = z.object({
  id: z.string(),
  name: z.string(),
  frameworkDescription: z.string().optional(),
  activityStatus: z.enum(SecurityFrameworkActivityStatusesDto),
  securityControls: z.array(ZodSecurityControlDto),
  labels: z.array(z.string()),
  source: z.enum(SecurityFrameworkSourcesDto),
  type: z.enum(SecurityFrameworkTypesDto),
  isDefault: z.boolean(),
});

export type SecurityFrameworkDto = z.infer<typeof ZodSecurityFrameworkDto>;

export const castSecurityFrameworkDtoToSecurityFramework = (
  dto: SecurityFrameworkDto
): SecurityFramework => {
  return {
    id: dto.id,
    name: dto.name,
    source: castSecurityFrameworkSourceDtoToSecurityFrameworkSource(dto.source),
    type: castSecurityFrameworkTypeDtoToSecurityFrameworkType(dto.type),
    labels: dto.labels,
    description: dto.frameworkDescription,
    isActive: castSecurityFrameworkActivityStatusDtoToBoolean(dto.activityStatus),
    controls: dto.securityControls.map((control) =>
      castSecurityControlDtoToSecurityControl(control, dto.name)
    ),
  };
};
