// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SendSecurityCommentFeedback } from '../../types/security-feedback';
import {
  ZodSendSecurityCommentFeedbackDto,
  castSendSecurityCommentFeedbackToSendSecurityCommentFeedbackDto,
} from './dtos/send-security-comment-feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { FEEDBACK_QUERY_KEYS } from '../../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = ZodSendSecurityCommentFeedbackDto;

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodSendSecurityCommentFeedbackRequestPayloadDto };
export type { RequestPayloadDto as SendSecurityCommentFeedbackRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityFeedbackId: z.string(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodSendSecurityCommentFeedbackResponseDto };
export type { ResponseDto as SendSecurityCommentFeedbackResponseDto };

// #######
// Request
// #######

export const sendSecurityCommentFeedback = async (
  storyId: string,
  model: SendSecurityCommentFeedback
): Promise<string> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      castSendSecurityCommentFeedbackToSendSecurityCommentFeedbackDto(storyId, model)
    );
    const res = await apiClient.post(`/SecurityFeedback/CreateSecurityFeedback`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityFeedbackId;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseSendSecurityCommentFeedback = {
  storyId: string;
  onStart?: () => void;
  onSuccess?: (payload: SendSecurityCommentFeedback) => void;
  onError?: (error: Error) => void;
};

export const useSendSecurityCommentFeedback = ({
  storyId,
  onStart,
  onSuccess,
  onError,
}: UseSendSecurityCommentFeedback) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (sendCommentFeedbackPayload: SendSecurityCommentFeedback) => {
      await sendSecurityCommentFeedback(storyId, sendCommentFeedbackPayload);

      await queryClient.invalidateQueries({
        queryKey: FEEDBACK_QUERY_KEYS.entityFeedback(sendCommentFeedbackPayload.recipientEntityId),
      });

      return sendCommentFeedbackPayload;
    },
    onMutate: () => onStart?.(),
    onSuccess: (payload) => onSuccess?.(payload),
    onError: (error) => onError?.(error),
  });

  return {
    sendSecurityComment: mutate,
    isSendingSecurityComment: isPending,
    didSendSecurityCommentErrored: isError,
  };
};
