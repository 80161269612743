// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IllustrationProps } from './illustration';

// App - Other
import Locale from '@/locale/en.json';
import { Illustration } from './illustration';

const locale = Locale.components.atoms.icon.illustrations;

export const InlineCommentFeedbackIllustration = forwardRef((props: IllustrationProps, ref) => {
  return (
    <Illustration
      viewBox="0 0 60 54"
      {...props}
      aria-label={props['aria-label'] || locale['Inline Comment Feedback Illustration']}
      ref={ref}
      fill="none"
    >
      <rect x="0.5" y="0.5" width="59" height="53" rx="3.5" fill="white" />

      <rect x="0.5" y="0.5" width="59" height="53" rx="3.5" stroke="#E2E8F0" />

      <rect x="4" y="4" width="52" height="6" rx="1" fill="#E2E8F0" fillOpacity="0.7" />

      <rect x="4" y="12" width="25" height="6" rx="1" fill="#E2E8F0" fillOpacity="0.7" />

      <rect x="4" y="20" width="53" height="6" rx="1" fill="#E2E8F0" fillOpacity="0.7" />

      <mask id="path-5-inside-1_17_14231" fill="white">
        <path d="M4 29C4 28.4477 4.44772 28 5 28H53C53.5523 28 54 28.4477 54 29V37C54 37.5523 53.5523 38 53 38H5C4.44771 38 4 37.5523 4 37V29Z" />
      </mask>

      <path
        d="M4 29C4 28.4477 4.44772 28 5 28H53C53.5523 28 54 28.4477 54 29V37C54 37.5523 53.5523 38 53 38H5C4.44771 38 4 37.5523 4 37V29Z"
        fill="#F2FCFA"
      />

      <path
        d="M4 28H54H4ZM54 37C54 38.1046 53.1046 39 52 39H6C4.89543 39 4 38.1046 4 37C4 37 4.44772 37 5 37H53C53.5523 37 54 37 54 37ZM4 38V28V38ZM54 28V38V28Z"
        fill="#21A9AB"
        mask="url(#path-5-inside-1_17_14231)"
      />

      <rect x="4" y="40" width="50" height="6" rx="1" fill="#E2E8F0" fillOpacity="0.7" />
    </Illustration>
  );
});

export const InlineCommentFeedbackIllustrationMetadata = {
  name: 'Inline Comment Feedback',
  importName: 'InlineCommentFeedbackIllustration',
  component: (
    <InlineCommentFeedbackIllustration
      w="156px"
      h="86px"
      aria-label="Inline Comment Feedback Illustration"
    />
  ),
  tags: 'feedback, inline, comment',
};
