// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const CircleOutlineIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 256 256" {...props} ref={ref}>
      <path
        d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const CircleOutlineIconMetadata = {
  name: 'Circle Outline',
  importName: 'CircleOutlineIcon',
  component: <CircleOutlineIcon aria-label="Circle Outline Icon" />,
  tags: 'circle, round, dot, point, spot, mark, spot, speck, speckle, shapes, polygon, design',
};
