// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const SmartsheetIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 1000 1000" {...props} ref={ref} fill="none">
      <path
        fill="#163258"
        d="M332.24,437s28.1-42.57,66.42-42.57C456.57,392.7,504.26,501.7,512.77,517c64.72-149.87,172-276.76,258.88-361.06H235.16V775.06c0,23.84-2.55,46-6.81,69,69.83-11.92,138.81-28.95,205.23-52.79,162.65-60.47,226.51-124.33,226.51-124.33l4.26-4.26,2.56,5.11c10.22,25.55,25.54,48.54,40.87,71.53,22.14,28.1,34.07,30.66,42.58,28.1,9.37-2.55,14.48-18.73,17-43.43,2.56-25.54,4.26-80.9,4.26-149.87V203.65c-155.84,200.12-272.5,499.87-272.5,499.87S421.66,436.13,332.24,437Z"
      />
    </Icon>
  );
});

export const SmartsheetIconMetadata = {
  name: 'Smartsheet Logo',
  importName: 'SmartsheetIcon',
  component: <SmartsheetIcon aria-label="Smartsheet Icon" />,
  tags: 'smartsheet, logo',
};
