// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { CreateTrelloIntegration } from '../types/trello-integration';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { INTEGRATIONS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  userEmail: z.string(),
  apiKey: z.string(),
  token: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateTrelloIntegrationRequestPayloadDto };
export type { RequestPayloadDto as CreateTrelloIntegrationRequestPayloadDto };

// #######
// Request
// #######

export const createTrelloIntegration = async (
  model: CreateTrelloIntegration,
  userEmail: string
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      userEmail: userEmail.trim(),
      apiKey: model.apiKey.trim(),
      token: model.token.trim(),
    });

    return await apiClient.post(`/Integration/InsertTrelloIntegration`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateTrelloIntegration = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useCreateTrelloIntegration = ({
  onStart,
  onSuccess,
  onError,
}: UseCreateTrelloIntegration = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({
      model,
      userEmail,
    }: {
      model: CreateTrelloIntegration;
      userEmail: string;
    }) => await createTrelloIntegration(model, userEmail),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: INTEGRATIONS_QUERY_KEYS.integrations(),
      });
    },
    onMutate: () => onStart?.(),
    onSuccess: () => onSuccess?.(),
    onError: (error) => onError?.(error),
  });

  return {
    createIntegration: mutate,
    isCreatingIntegration: isPending,
    didIntegrationCreationErrored: isError,
  };
};
