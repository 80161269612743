// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  url: z.string(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodCreateGithubAppOAuthUrlResponseDto };
export type { ResponseDto as CreateGithubAppOAuthUrlResponseDto };

// #######
// Request
// #######

export const createGithubAppOAuthUrl = async (): Promise<string> => {
  try {
    const res = await apiClient.get(`/Integration/GetGithubAppInstallUrl`);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.url;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateGithubAppOAuthUrl = {
  onStart?: () => void;
  onSuccess?: (authUrl: string) => void;
  onError?: (error: Error) => void;
};

export const useCreateGithubAppOAuthUrl = ({
  onStart,
  onSuccess,
  onError,
}: UseCreateGithubAppOAuthUrl = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async () => await createGithubAppOAuthUrl(),
    onMutate: () => onStart?.(),
    onSuccess: (authUrl) => onSuccess?.(authUrl),
    onError: (error) => onError?.(error),
  });

  return {
    createAppOAuthUrl: mutate,
    isCreatingAppOAuthUrl: isPending,
    didAppOAuthUrlCreationErrored: isError,
  };
};
