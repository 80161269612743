export const RequirementCountermeasureSnippetTypes = [
  'architecture',
  'code',
  'configuration',
  'documentation',
  'other',
  'UNKNOWN',
] as const;

export type RequirementCountermeasureSnippetType =
  (typeof RequirementCountermeasureSnippetTypes)[number];
