// 3rd
import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

// ############
// TOOLTIP BASE
// ############

const baseStyle = defineStyle({
  '--popper-arrow-bg': 'var(--chakra-colors-surface-primary)',
  '--popper-arrow-shadow-color': 'rgba(0, 0, 0, 0.01)',

  bg: 'surface.primary',
  boxShadow: 'elevation-4',
  borderRadius: '4px',
  color: 'text.mid-tone',
});

// #####
// SIZES
// #####

const smSize = defineStyle({
  fontSize: '8px',
  py: '4px',
  px: '8px',
  maxW: '220px',
});

const mdSize = defineStyle({
  fontSize: '12px',
  py: '8px',
  px: '12px',
  maxW: '320px',
});

const lgSize = defineStyle({
  fontSize: '16px',
  py: '8px',
  px: '16px',
  maxW: '370px',
});

// ########
// VARIANTS
// ########

const defaultVariant = defineStyle({});

const tooltipTheme = defineStyleConfig({
  baseStyle,
  sizes: { sm: smSize, md: mdSize, lg: lgSize },
  variants: {
    default: defaultVariant,
  },
});

export const Tooltip = tooltipTheme;
