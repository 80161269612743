// 3rd
import { Flex, Menu, MenuButton, MenuItem, MenuList, Skeleton } from '@chakra-ui/react';

// App - Types
import type { KnowledgeSourceIntegration } from '../../types/knowledge-source-integration';

// App - Other
import Locale from '@/locale/en.json';
import { DotsVerticalIcon } from '@/components/atoms/icon';
import { EllipsisText, Text } from '@/components/atoms/typography';
import { IconBox } from '@/components/molecules/icon-box';
import { IconButton } from '@/components/molecules/button';
import { Tag } from '@/components/molecules/tag';
import {
  translateIntegrationStatus,
  translateKnowledgeSource,
  KnowledgeSourceIcon,
} from '@/components/translators/integration';

const locale = Locale.features.integrations['integrated-knowledge-source-card'];

type IntegratedKnowledgeSourceCardProps = {
  integration: KnowledgeSourceIntegration;
  treatGoogleDriveAsOneDrive?: boolean;
  onUpdate?: (isCtrlKeyPressed: boolean) => void;
};

export const IntegratedKnowledgeSourceCard = ({
  integration,
  onUpdate,
  treatGoogleDriveAsOneDrive,
}: IntegratedKnowledgeSourceCardProps) => {
  return (
    <Flex
      direction="column"
      gap="sm"
      bg="surface.primary"
      minW="fit-content"
      width="100%"
      // height="100%"
      height="96px"
      border="1px"
      borderColor="border.primary"
      borderRadius="md"
      overflow="hidden"
      py="lg"
      px="lg"
      position="relative"
    >
      {onUpdate ? (
        <Menu>
          <MenuButton
            as={IconButton}
            size="sm"
            icon={
              <DotsVerticalIcon
                style={{ width: '14px', height: '14px' }}
                aria-label={locale['Integration options']}
              />
            }
            aria-label={locale['Integration options']}
            position="absolute"
            right="6px"
            top="sm"
          />

          <MenuList>
            <MenuItem onClick={(e) => onUpdate(e.metaKey || e.ctrlKey)}>
              <Text variant="detail">{locale['Edit']}</Text>
            </MenuItem>
          </MenuList>
        </Menu>
      ) : null}

      <Flex alignItems="start" gap="md">
        <IconBox
          bg="surface.brand.invert-primary-low-tone"
          w="4xl"
          h="4xl"
          minW="4xl"
          minH="4xl"
          borderRadius="md"
        >
          <KnowledgeSourceIcon
            source={integration.source}
            treatGoogleDriveAsOneDrive={treatGoogleDriveAsOneDrive}
            size="sm"
          />
        </IconBox>

        <Flex direction="column">
          <Flex alignItems="center" gap="sm">
            <Text variant="detail-semibold" color="text.primary">
              {translateKnowledgeSource(integration.source, treatGoogleDriveAsOneDrive)}
            </Text>

            {integration.status !== 'valid' && integration.status !== 'UNKNOWN' ? (
              <Tag
                variant={
                  integration.status === 'pending_installation'
                    ? 'secondary'
                    : integration.status === 'installation_removed'
                      ? 'error'
                      : undefined
                }
                size="sm"
              >
                {translateIntegrationStatus(integration.status)}
              </Tag>
            ) : null}
          </Flex>

          <EllipsisText variant="detail" color="text.secondary" maxW="170px">
            {integration.displayName}
          </EllipsisText>
        </Flex>
      </Flex>

      <Text variant="detail">
        {locale['Last scanned:']}{' '}
        {integration.lastScraped ? integration.lastScraped.toFormat('dd/LL/yy HH:mm') : '-'}
      </Text>
    </Flex>
  );
};

const Loading = () => {
  return <Skeleton borderRadius="md" w="100%" h="96px" />;
};

IntegratedKnowledgeSourceCard.Loading = Loading;
