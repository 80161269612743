// App - Types
import type { IntegrationStatus } from '../integration-status';

export const IntegrationStatusesDto = [
  'CriticalInvalid',
  'InstallationRemoved',
  'InvalidAuthentication',
  'InvalidAuthorization',
  'InvalidDataIntegrity',
  'PendingInstallation',
  'Valid',
  'Invalid',
  'UNKNOWN',
] as const;

export type IntegrationStatusDto = (typeof IntegrationStatusesDto)[number];

export const castIntegrationStatusDtoToIntegrationStatus = (
  dto: IntegrationStatusDto
): IntegrationStatus => {
  if (dto === 'InstallationRemoved') return 'installation_removed';

  if (dto === 'PendingInstallation') return 'pending_installation';

  if (dto === 'Valid') return 'valid';

  // INSIGHT this is temp - until we are ready to present the new statuses in the UI (22.12.2024)
  if (
    [
      'Invalid',
      'CriticalInvalid',
      'InvalidAuthentication',
      'InvalidAuthorization',
      'InvalidDataIntegrity',
    ].includes(dto)
  ) {
    return 'valid';
  }

  return 'UNKNOWN';
};

export const castIntegrationStatusToIntegrationStatusDto = (
  status: IntegrationStatus
): IntegrationStatusDto => {
  if (status === 'installation_removed') return 'InstallationRemoved';

  if (status === 'pending_installation') return 'PendingInstallation';

  if (status === 'valid') return 'Valid';

  return 'UNKNOWN';
};
