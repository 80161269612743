// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { uuid4 } from '@/utils/generators';
import { Icon } from './icon';

export const MicrosoftExcelIcon = forwardRef((props: IconProps, ref) => {
  const guid = `microsoft-excel-icon-${uuid4()}`;

  return (
    <Icon viewBox="0 0 32 32" {...props} ref={ref} fill="none">
      <defs>
        <linearGradient
          id={`gradient-1-${guid}`}
          x1="4.494"
          y1="-2092.086"
          x2="13.832"
          y2="-2075.914"
          gradientTransform="translate(0 2100)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#18884f" />

          <stop offset="0.5" stopColor="#117e43" />

          <stop offset="1" stopColor="#0b6631" />
        </linearGradient>
      </defs>

      <path
        d="M19.581,15.35,8.512,13.4V27.809A1.192,1.192,0,0,0,9.705,29h19.1A1.192,1.192,0,0,0,30,27.809h0V22.5Z"
        style={{
          fill: '#185c37',
        }}
      />

      <path
        d="M19.581,3H9.705A1.192,1.192,0,0,0,8.512,4.191h0V9.5L19.581,16l5.861,1.95L30,16V9.5Z"
        style={{
          fill: '#21a366',
        }}
      />

      <path
        d="M8.512,9.5H19.581V16H8.512Z"
        style={{
          fill: '#107c41',
        }}
      />

      <path
        d="M16.434,8.2H8.512V24.45h7.922a1.2,1.2,0,0,0,1.194-1.191V9.391A1.2,1.2,0,0,0,16.434,8.2Z"
        style={{
          opacity: 0.1,
          isolation: 'isolate',
        }}
      />

      <path
        d="M15.783,8.85H8.512V25.1h7.271a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.783,8.85Z"
        style={{
          opacity: 0.2,
          isolation: 'isolate',
        }}
      />

      <path
        d="M15.783,8.85H8.512V23.8h7.271a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.783,8.85Z"
        style={{
          opacity: 0.2,
          isolation: 'isolate',
        }}
      />

      <path
        d="M15.132,8.85H8.512V23.8h6.62a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.132,8.85Z"
        style={{
          opacity: 0.2,
          isolation: 'isolate',
        }}
      />

      <path
        d="M3.194,8.85H15.132a1.193,1.193,0,0,1,1.194,1.191V21.959a1.193,1.193,0,0,1-1.194,1.191H3.194A1.192,1.192,0,0,1,2,21.959V10.041A1.192,1.192,0,0,1,3.194,8.85Z"
        style={{
          fill: `url(#gradient-1-${guid})`,
        }}
      />

      <path
        d="M5.7,19.873l2.511-3.884-2.3-3.862H7.758L9.013,14.6c.116.234.2.408.238.524h.017c.082-.188.169-.369.26-.546l1.342-2.447h1.7l-2.359,3.84,2.419,3.905H10.821l-1.45-2.711A2.355,2.355,0,0,1,9.2,16.8H9.176a1.688,1.688,0,0,1-.168.351L7.515,19.873Z"
        style={{
          fill: '#fff',
        }}
      />

      <path
        d="M28.806,3H19.581V9.5H30V4.191A1.192,1.192,0,0,0,28.806,3Z"
        style={{
          fill: '#33c481',
        }}
      />

      <path
        d="M19.581,16H30v6.5H19.581Z"
        style={{
          fill: '#107c41',
        }}
      />
    </Icon>
  );
});

export const MicrosoftExcelIconMetadata = {
  name: 'Microsoft Excel Logo',
  importName: 'MicrosoftExcelIcon',
  component: <MicrosoftExcelIcon aria-label="MicrosoftExcel Icon" />,
  tags: 'excel, microsoft, logo, spreadsheet, office',
};
