// 3rd
import type { DateTime } from 'luxon';

// App - Types
import type { KnowledgeSource } from '@/types/integration';
import type { EntityId } from '@/types/entity';

export type Feedback = {
  id: string;
  entityId: EntityId;
  content: string;
  lastModified: DateTime;
};

export const AllowedSourcesForCommentFeedback: ReadonlyArray<KnowledgeSource> = [
  'confluence',
  'google_drive',
  'jira',
  'azure_devops_wiki_page',
  'azure_devops_work_item',
  'share_point',
  'notion',
] as const;

export const isAllowedToSendCommentFeedbackForSource = (
  source: KnowledgeSource,
  mimeType?: string
): boolean => {
  return (
    AllowedSourcesForCommentFeedback.includes(source) && (!mimeType || mimeType !== 'Database')
  );
};

export const AllowedSourcesForInlineCommentFeedback: ReadonlyArray<KnowledgeSource> = [
  'confluence',
  'share_point',
] as const;

export const isAllowedToSendInlineCommentFeedbackForSource = (source: KnowledgeSource): boolean => {
  return AllowedSourcesForInlineCommentFeedback.includes(source);
};

export const AllowedSourcesForFootnoteCommentFeedback: ReadonlyArray<KnowledgeSource> = [
  'google_drive',
] as const;

export const isAllowedToSendFootnoteCommentFeedbackForSource = (
  source: KnowledgeSource
): boolean => {
  return AllowedSourcesForFootnoteCommentFeedback.includes(source);
};

export const AllowedSourcesForParagraphInsertionFeedback: ReadonlyArray<KnowledgeSource> = [
  'confluence',
  'google_drive',
  'share_point',
] as const;

export const isAllowedToInsertParagraphFeedbackForSource = (
  source: KnowledgeSource,
  mimeType?: string
): boolean => {
  return (
    AllowedSourcesForParagraphInsertionFeedback.includes(source) &&
    (!mimeType ||
      mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
  );
};

export const AllowedSourcesForCreateTicketFeedback: ReadonlyArray<KnowledgeSource> = [
  'jira',
] as const;

export const isAllowedToCreateTicketFeedbackForSource = (source: KnowledgeSource): boolean => {
  return AllowedSourcesForCreateTicketFeedback.includes(source);
};
