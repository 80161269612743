// App - Types
import type { FeedbackDto } from '@/features/feedback';

type EnrichedFeedbackDto = FeedbackDto & {
  entityId: {
    collectionId: string;
    documentId: string;
  };
};

export const securityFeedbackMockData: EnrichedFeedbackDto[] = [];
