// 3rd
import { z } from 'zod';

// App - Types
import type {
  EntityContributingUser,
  EntityContributingUserRole,
} from '../entity-contributing-user';

export const EntityContributingUserRolesDto = [
  'Assignee',
  'Author',
  'Commenter',
  'Closer',
  'Editor',
  'Organizer',
  'Reporter',
  'Reviewer',
  'Viewer',
  'UNKNOWN',
] as const;

export type EntityContributingUserRoleDto = (typeof EntityContributingUserRolesDto)[number];

export const castEntityContributingUserRoleDtoToEntityContributingUserRole = (
  dto: EntityContributingUserRoleDto
): EntityContributingUserRole => {
  if (dto === 'Assignee') return 'assignee';

  if (dto === 'Author') return 'author';

  if (dto === 'Commenter') return 'commenter';

  if (dto === 'Closer') return 'closer';

  if (dto === 'Editor') return 'editor';

  if (dto === 'Organizer') return 'organizer';

  if (dto === 'Reporter') return 'reporter';

  if (dto === 'Reviewer') return 'reviewer';

  if (dto === 'Viewer') return 'viewer';

  return 'UNKNOWN';
};

export const ZodEntityContributingUserDto = z.object({
  email: z.string().optional(),
  name: z.string().optional(),
  roles: z.array(z.enum(EntityContributingUserRolesDto)),
  sourceId: z.string(),
});

export type EntityContributingUserDto = z.infer<typeof ZodEntityContributingUserDto>;

export const castEntityContributingUserDtoToEntityContributingUser = (
  dto: EntityContributingUserDto
): EntityContributingUser => {
  const roles = dto.roles.map(castEntityContributingUserRoleDtoToEntityContributingUserRole);

  return {
    email: dto.email,
    name: dto.name,
    roles,
    sourceId: dto.sourceId,
  };
};
