// 3rd
import { z } from 'zod';

// App - Types
import {
  RequirementSeveritiesDto,
  RequirementActivityStatusesDto,
  castRequirementActivityStatusDtoToBoolean,
  castRequirementSeverityDtoToRequirementSeverity,
} from '@/types/security-framework/requirement/dtos';
import type { Requirement } from '../../../types/requirement';
import {
  ZodRequirementAntiPatternDto,
  castRequirementAntiPatternDtoToRequirementAntiPattern,
} from './requirement-anti-pattern';
import {
  ZodRequirementCountermeasureDto,
  castRequirementCountermeasureDtoToRequirementCountermeasure,
} from './requirement-countermeasure';

export const ZodRequirementDto = z.object({
  id: z.string(),
  categoryName: z.string().optional(),
  content: z.string(),
  description: z.string().optional(),
  requirementIdNumber: z.string(),
  cwes: z.string().array(),
  severity: z.enum(RequirementSeveritiesDto),
  activityStatus: z.enum(RequirementActivityStatusesDto),
  countermeasures: z.array(ZodRequirementCountermeasureDto).optional(),
  antiPatterns: z.array(ZodRequirementAntiPatternDto).optional(),
});

export type RequirementDto = z.infer<typeof ZodRequirementDto>;

export const castRequirementDtoToRequirement = (
  dto: RequirementDto,
  framework: string = '',
  control: string = ''
): Requirement => ({
  framework,
  control,
  category: dto.categoryName,
  content: dto.content,
  description: dto.description,
  id: dto.id,
  isActive: castRequirementActivityStatusDtoToBoolean(dto.activityStatus),
  countermeasures: (dto.countermeasures || []).map(
    castRequirementCountermeasureDtoToRequirementCountermeasure
  ),
  antiPatterns: (dto.antiPatterns || []).map(castRequirementAntiPatternDtoToRequirementAntiPattern),
  requirementIdInFramework: dto.requirementIdNumber,
  severity: castRequirementSeverityDtoToRequirementSeverity(dto.severity),
  cwes: dto.cwes,
});
