'use client';

// React & Next
import type { ReactNode } from 'react';

// 3rd
import { NuqsAdapter } from 'nuqs/adapters/next/app';

type NuqsProviderProps = {
  children: ReactNode;
};

export const NuqsProvider = ({ children }: NuqsProviderProps) => {
  return <NuqsAdapter>{children}</NuqsAdapter>;
};
