// 3rd
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { z } from 'zod';

// App - Types
import type { CommunicationSourceIntegration } from '../types/communication-source-integration';
import {
  ZodSlackIntegrationDto,
  castSlackDtoToCommunicationSourceIntegration,
} from './dtos/slack-integration';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { passthroughInvalidItemsArray } from '@/utils/zod';
import { INTEGRATIONS_QUERY_KEYS } from '../config/react-query-key-factory';

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  integrations: passthroughInvalidItemsArray(ZodSlackIntegrationDto).default([]).catch([]),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodGetSlackIntegrationsResponseDto };
export type { ResponseDto as GetSlackIntegrationsResponseDto };

// #######
// Request
// #######

export const getSlackIntegrations = async (): Promise<CommunicationSourceIntegration[]> => {
  try {
    const res = await apiClient.get(`/Integration/ListSlackIntegrations`);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.integrations.map(castSlackDtoToCommunicationSourceIntegration);
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return [];
    }

    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseSlackIntegrations = {
  enabled?: boolean;
};

export const useSlackIntegrations = ({ enabled = true }: UseSlackIntegrations = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: INTEGRATIONS_QUERY_KEYS.slackIntegrations(),
    queryFn: async () => await getSlackIntegrations(),
    enabled: enabled,
  });

  return {
    slackIntegrations: data,
    isFetchingSlackIntegrations: isLoading || isFetching,
    didFetchSlackIntegrationsErrored: isLoadingError,
  };
};
