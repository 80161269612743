// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SecurityFramework } from '../types/security-framework';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityFrameworkId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodDeleteSecurityFrameworkRequestPayloadDto };
export type { RequestPayloadDto as DeleteSecurityFrameworkRequestPayloadDto };

// #######
// Request
// #######

export const deleteSecurityFramework = async (frameworkId: string): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      securityFrameworkId: frameworkId,
    });

    return await apiClient.post('/SecurityFramework/DeleteSecurityFramework', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseDeleteSecurityFramework = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useDeleteSecurityFramework = ({
  onStart,
  onSuccess,
  onError,
}: UseDeleteSecurityFramework = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (frameworkId: string) => await deleteSecurityFramework(frameworkId),
    onMutate: () => {
      onStart?.();
    },
    onSuccess: async (data, deletedFrameworkId) => {
      await queryClient.cancelQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      });

      const previousFrameworks = queryClient.getQueryData(
        SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks() || []
      ) as SecurityFramework[];
      const newFrameworks = previousFrameworks.filter((framework) => {
        return framework.id !== deletedFrameworkId;
      });

      queryClient.setQueryData(SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(), newFrameworks);

      onSuccess?.();
    },
    onError: (error) => {
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      });

      onError?.(error);
    },
  });

  return {
    deleteSecurityFramework: mutate,
    isDeletingSecurityFramework: isPending,
    didDeleteSecurityFrameworkErrored: isError,
  };
};
