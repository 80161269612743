// App - Types
import type { KnowledgeItemPriority } from '../knowledge-item-priority';

export const KnowledgeItemPrioritiesDto = ['High', 'Medium', 'Low', 'UNKNOWN'] as const;

export type KnowledgeItemPriorityDto = (typeof KnowledgeItemPrioritiesDto)[number];

export const castKnowledgeItemPriorityDtoToKnowledgeItemPriority = (
  dto: KnowledgeItemPriorityDto
): KnowledgeItemPriority => {
  if (dto === 'High') return 'high';

  if (dto === 'Medium') return 'medium';

  if (dto === 'Low') return 'low';

  return 'UNKNOWN';
};

export const castKnowledgeItemPriorityToKnowledgeItemPriorityDto = (
  priority: KnowledgeItemPriority
): KnowledgeItemPriorityDto => {
  if (priority === 'high') return 'High';

  if (priority === 'medium') return 'Medium';

  if (priority === 'low') return 'Low';

  return 'UNKNOWN';
};
