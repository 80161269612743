// 3rd
import { z } from 'zod';

// App - Types
import {
  RequirementAntiPatternTypesDto,
  castRequirementAntiPatternTypeDtoToRequirementAntiPatternType,
} from '@/types/security-framework/requirement/dtos';
import type { RequirementAntiPattern } from '../../../types/requirement-anti-pattern';

export const ZodRequirementAntiPatternDto = z.object({
  id: z.string(),
  content: z.string(),
  type: z.enum(RequirementAntiPatternTypesDto),
});

export type RequirementAntiPatternDto = z.infer<typeof ZodRequirementAntiPatternDto>;

export const castRequirementAntiPatternDtoToRequirementAntiPattern = (
  dto: RequirementAntiPatternDto
): RequirementAntiPattern => ({
  id: dto.id,
  content: dto.content,
  type: castRequirementAntiPatternTypeDtoToRequirementAntiPatternType(dto.type),
});
