// App - Types
import type { PullRequestCategory } from '../pull-request-category';

export const PullRequestCategoriesDto = [
  'Bug Fix',
  'Code Quality',
  'Documentation',
  'Feature',
  'Infrastructure',
  'Maintenance',
  'Security Oriented',
  'Other',
  'UNKNOWN',
] as const;

export type PullRequestCategoryDto = (typeof PullRequestCategoriesDto)[number];

export const castPullRequestCategoryDtoToPullRequestCategory = (
  dto: PullRequestCategoryDto
): PullRequestCategory => {
  if (dto === 'Bug Fix') return 'bug_fix';

  if (dto === 'Code Quality') return 'code_quality';

  if (dto === 'Documentation') return 'documentation';

  if (dto === 'Feature') return 'feature';

  if (dto === 'Infrastructure') return 'infrastructure';

  if (dto === 'Maintenance') return 'maintenance';

  if (dto === 'Security Oriented') return 'security_oriented';

  if (dto === 'Other') return 'other';

  return 'UNKNOWN';
};

export const castPullRequestCategoryToPullRequestCategoryDto = (
  category: PullRequestCategory
): PullRequestCategoryDto => {
  if (category === 'bug_fix') return 'Bug Fix';

  if (category === 'code_quality') return 'Code Quality';

  if (category === 'documentation') return 'Documentation';

  if (category === 'feature') return 'Feature';

  if (category === 'infrastructure') return 'Infrastructure';

  if (category === 'maintenance') return 'Maintenance';

  if (category === 'security_oriented') return 'Security Oriented';

  if (category === 'other') return 'Other';

  return 'UNKNOWN';
};
