// 3rd
import { z } from 'zod';

// App - Types
import {
  AllowedSourcesForInlineCommentFeedback,
  isAllowedToSendInlineCommentFeedbackForSource,
} from '../../../types/feedback';
import type { SendSecurityInlineCommentFeedback } from '../../../types/security-feedback';

export const ZodSendSecurityInlineCommentFeedbackDto = z.object({
  securityRequirementIds: z.string().array(),
  securityStoryThreatIds: z.string().array(),
});

export type SendSecurityInlineCommentFeedbackDto = z.infer<
  typeof ZodSendSecurityInlineCommentFeedbackDto
>;

export const castSendSecurityInlineCommentFeedbackToSendSecurityInlineCommentFeedbackDto = (
  payload: SendSecurityInlineCommentFeedback
): SendSecurityInlineCommentFeedbackDto => {
  const validThreats = (payload.threats || []).some((t) =>
    isAllowedToSendInlineCommentFeedbackForSource(t.relatedToSource)
  );
  const validRequirements = (payload.requirements || []).some((r) =>
    isAllowedToSendInlineCommentFeedbackForSource(r.relatedToSource)
  );

  if (!validThreats && !validRequirements)
    throw new Error(
      `Feedback creation is allowed for the following sources => ${AllowedSourcesForInlineCommentFeedback.join(', ')}.`
    );

  return {
    securityRequirementIds: (payload.requirements || []).flatMap((req) =>
      isAllowedToSendInlineCommentFeedbackForSource(req.relatedToSource) ? [req.id] : []
    ),
    securityStoryThreatIds: (payload.threats || []).flatMap((threat) =>
      isAllowedToSendInlineCommentFeedbackForSource(threat.relatedToSource) ? [threat.id] : []
    ),
  };
};
