// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { uuid4 } from '@/utils/generators';
import { Icon } from './icon';

export const ImageFileIcon = forwardRef((props: IconProps, ref) => {
  const guid = `image-file-icon-${uuid4()}`;

  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref} fill="none">
      <g clipPath={`url(#clip-1-${guid})`}>
        <path
          d="M9.5 14L6.5 9.5L4.7725 12.0913L3.75 10.5L1.5 14H9.5Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          d="M9.5 2V5.5H13"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          d="M12 14H12.5C12.6326 14 12.7598 13.9473 12.8536 13.8536C12.9473 13.7598 13 13.6326 13 13.5V5.5L9.5 2H3.5C3.36739 2 3.24021 2.05268 3.14645 2.14645C3.05268 2.24021 3 2.36739 3 2.5V8"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <defs>
        <clipPath id={`clip-1-${guid}`}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const ImageFileIconMetadata = {
  name: 'Image File',
  importName: 'ImageFileIcon',
  component: <ImageFileIcon aria-label="Image File Icon" />,
  tags: 'image, file, picture, photo, jpg, png, gif',
};
