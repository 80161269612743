// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const QuipIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 150 150" {...props} ref={ref} fill="none">
      <path d="M0 0h150v150H0z" fill="none" />

      <path
        d="M116.6 28.81a61.59 61.59 0 0 0-87.06 87.07 61.87 61.87 0 0 0 44.89 19.42h56.86a4.74 4.74 0 0 0 4.74-4.74V73.7a61.84 61.84 0 0 0-19.43-44.89zM53.11 46.64h42.64c2.62 0 5.74 3.12 5.74 5.74s-3.12 5.74-5.74 5.74H53.11c-2.62 0-5.74-3.12-5.74-5.74s3.12-5.74 5.74-5.74zm42.64 54.12H53.11c-2.62 0-5.74-3.12-5.74-5.74s3.12-5.74 5.74-5.74h42.64c2.62 0 5.74 3.12 5.74 5.74s-3.12 5.74-5.74 5.74zM110 79.44H38.89c-2.5-.08-5.58-3.09-5.58-5.59A5.57 5.57 0 0 1 38.89 68H110c2.61 0 5.74 3.12 5.74 5.74s-3.16 5.7-5.74 5.7z"
        fill="#c34027"
      />

      <path
        d="M114.71 73.7a4.74 4.74 0 0 1-4.71 4.74H38.89a4.74 4.74 0 1 1 0-9.48H110a4.74 4.74 0 0 1 4.74 4.74m-19 26.06H53.11a4.74 4.74 0 0 1-.31-9.48h43a4.74 4.74 0 0 1 0 9.48M53.11 47.64h42.64a4.74 4.74 0 0 1 0 9.48H53.11a4.74 4.74 0 0 1 0-9.48m57-11.92a51.63 51.63 0 0 0-35.64-14.14c-1.12 0-2.24 0-3.37.11a52.12 52.12 0 0 0 3.33 104.13h52.12V73.7a51.58 51.58 0 0 0-16.43-38"
        fill="#f27557"
        fillRule="evenodd"
      />
    </Icon>
  );
});

export const QuipIconMetadata = {
  name: 'Quip Logo',
  importName: 'QuipIcon',
  component: <QuipIcon aria-label="Quip Icon" />,
  tags: 'quip, logo',
};
