// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { uuid4 } from '@/utils/generators';
import { Icon } from './icon';

export const MinusIcon = forwardRef((props: IconProps, ref) => {
  const guid = `minus-icon-${uuid4()}`;

  return (
    <Icon viewBox="0 0 16 16" {...props} ref={ref} fill="none">
      <g clipPath={`url(#clip-1-${guid})`}>
        <path d="M2.5 8H13.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      </g>

      <defs>
        <clipPath id={`clip-1-${guid}`}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export const MinusIconMetadata = {
  name: 'Minus',
  importName: 'MinusIcon',
  component: <MinusIcon aria-label="Minus Icon" />,
  tags: 'minus, minimize, remove, zoom, fit, view',
};
