// 3rd
import { z } from 'zod';

// App - Types
import type { EntityId } from '../entity-id';
import { castEntityTypeToKnowledgeSource } from '../entity-type';
import {
  EntityTypesDto,
  castEntityTypeDtoToEntityType,
  castEntityTypeToEntityTypeDto,
} from './entity-type';

export const ZodEntityIdDto = z.object({
  entityType: z.enum(EntityTypesDto),
  collectionId: z.string(),
  itemId: z.string(),
});

export type EntityIdDto = z.infer<typeof ZodEntityIdDto>;

export const castEntityIdDtoToEntityId = (dto: EntityIdDto): EntityId => {
  const type = castEntityTypeDtoToEntityType(dto.entityType);
  const source = castEntityTypeToKnowledgeSource(type);

  return {
    source,
    type,
    collectionId: dto.collectionId,
    itemId: dto.itemId,
  };
};

export const castEntityIdToEntityIdDto = (id: EntityId): EntityIdDto => ({
  entityType: castEntityTypeToEntityTypeDto(id.type),
  collectionId: id.collectionId,
  itemId: id.itemId,
});
