// App - Types
import type { EntityId } from '@/types/entity';

export const FEEDBACK_QUERY_KEYS = {
  root: ['feedback'] as const,
  feedback: () => [...FEEDBACK_QUERY_KEYS.root] as const,

  entityFeedback: (entityId: EntityId) =>
    [...FEEDBACK_QUERY_KEYS.feedback(), 'for_entity_id', entityId] as const,
};
