export const SecurityFrameworkActivityStatusesDto = [
  'Active',
  'Inactive',
  'Deleted',
  'Deprecated',
] as const;

export type SecurityFrameworkActivityStatusDto =
  (typeof SecurityFrameworkActivityStatusesDto)[number];

export const castSecurityFrameworkActivityStatusDtoToBoolean = (
  dto: SecurityFrameworkActivityStatusDto
): boolean => {
  return dto === 'Active';
};
