// App - Types
import type { KnowledgeSource } from '@/types/integration';

export const INVENTORY_ROUTES = {
  root: '/inventory',

  inventory: () => `${INVENTORY_ROUTES.root}`,
  workspace: (source: KnowledgeSource, collectionId: string) =>
    `${INVENTORY_ROUTES.inventory()}/${source}/${collectionId}`,
};

// export const INVENTORY_QUERY_PARAMS = {
//   create: 'create',
// };
