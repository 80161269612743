// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const WhimsicalIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 360 248" {...props} ref={ref} fill="none">
      <path
        fill="#A665D2"
        d="M162.4,80.5c0.5-0.1,0.9-0.2,1.4-0.2l0.5,0.2C163.7,80.5,163,80.5,162.4,80.5L162.4,80.5z"
      />

      <circle fill="#7A12CD" cx="180" cy="124" r="90.8" />

      <path
        fill="#FFFEFF"
        d="M193.7,80.6c1.3-0.3,2.7-0.1,3.8,0.6c1.8,1.5,3.3,3.2,5,4.8c2.3,2.3,4.6,4.6,6.9,6.9c1.3,1,2.2,2.3,3.5,3.4
	c1.2,1.6,2.9,2.7,4.1,4.2c1.1,1,2.2,2,3.1,3.1c2.5,2.2,4.5,4.8,7.1,6.9c1.5,1.8,3.2,3.4,4.8,5c1.8,1.8,3.6,3.6,5.4,5.5
	c0.2,0.5,0.5,1.1,0.7,1.6c0,0.6,0,1.3,0,1.9c-0.3,0.9-0.8,1.7-1.4,2.4c-12.8,12.8-25.6,25.6-38.5,38.5c-0.7,0.5-1.4,0.9-2.2,1.2
	c-0.7,0-1.3,0-2,0c-0.8-0.3-1.5-0.7-2.2-1.2c-2.8-2.8-5.7-5.7-8.5-8.5c-0.9-0.9-1.8-1.9-2.8-2.8c-0.5-0.5-0.9-1-1.4-1.4l-0.2-0.2
	c-2.1-2.1-4.1-4.2-6.2-6.2c-0.2-0.2-0.4-0.4-0.6-0.6c-3.6-3.6-7.1-7.1-10.7-10.7c-2.6-2.7-5.3-5.3-8-8c-0.7-0.7-1.2-1.5-1.4-2.4
	c0-0.6,0-1.1,0-1.7c0.4-1.8,1.9-3.1,3.1-4.3c0.9-1,1.9-1.9,2.8-2.8c2.2-2.1,4.2-4.4,6.4-6.5c2.7-2.5,5.1-5.1,7.7-7.7
	c0.2-0.2,0.4-0.3,0.6-0.4c2.1-2.1,4.3-4.1,6.2-6.3l0.3-0.3c2.3-2.5,4.8-4.8,7.2-7.2c2.4-2.2,4.4-4.9,7.2-6.5"
      />

      <path
        fill="#E3CCF4"
        d="M159.4,82.6c0.8-0.9,1.8-1.6,3-2c0.6,0,1.3,0,1.9,0c1.8,0.5,2.9,1.9,4.1,3.1c2.2,2.2,4.4,4.4,6.6,6.6
	c-0.8,0.6-1.5-1.3-2.3-0.6c-0.8,0.7-1.5,1.5-2.2,2.3c-0.4,0.4-1,0.5-1.2,1c-0.3,0.8-1.2,1-1.8,1.6c-0.1,0.3,0.4,0.3,0.5,0.5l-0.1,1
	c-1.2-1.1-2.3-2.4-3.6-3.5c-0.5-0.2-1.4-0.4-1.8,0c-9.8,9.5-19.3,19.3-29,28.9c-0.4,0.5-1.1,0.8-1.1,1.5c-0.2,0.9,0.1,1.9,0.8,2.6
	c1.8,1.8,3.6,3.6,5.4,5.4c4.3,4.3,8.6,8.6,12.9,12.9c3.3,3.3,6.6,6.5,9.8,9.8c0.6,0.8,1.6,1.2,2.6,1.1c1.6-0.6,2.6-2.2,3.9-3.3
	c0.2,0.3,0,0.7,0,1.1c-0.5,0.1-1.1,0.5-0.9,1.1c0.3,0.8-1,0-1.3,0.6c-0.1,0.4,0.3,0.6,0.4,0.9c-0.8,0.1-1.9-0.5-2.3,0.4
	c-0.5,0.2-0.8-0.3-1.2-0.4c-1.3,0.4-0.9-1.2-1.8-1.3c-0.9-0.4-1.1-1.7-2.2-1.9c-0.8-0.2-0.6-1.3-1.2-1.6c-0.5-0.3-1-0.8-1.3-1.4
	c-0.2-0.6-1,0.3-1.1-0.5c-0.3-1.2-1.4-1.7-2-2.6c-0.4-0.4-1.2-0.3-1.4-1c-0.1-0.5-0.5-0.9-1-1.1c-0.5-0.7-1-1.6-2-1.6
	c-0.7,0.1-0.6-0.8-0.6-1.3c-1.1-0.3-1.3-1.5-2.2-2c-0.4,0.1-1-0.1-1.3,0.4c0,0.2,0.1,0.5-0.1,0.8c-0.2-0.1-0.4-0.2-0.6-0.3
	c-1.2,1.3-2.6,2.6-3.8,3.9c-0.1,1.2,1.3,1.8,2,2.8c0.1,0.5,0.6,0.3,0.9,0.3c0.3,0.6,0.6,1.3,1,1.9c0.9,0.5,1.4,1.4,2.2,1.8
	c0.6,1.1,1.5,2.1,2.6,2.8c0.3,0.2,0.7,0.5,0.9,0.9c0.5,0.2,1.2,0,0.8,1c0.3,0.4,0.7,0.8,1.1,1.2c1-0.4,0.9,0.4,1,0.8
	c0.3,0.5,1.5-0.1,1.1,1.1c0.6,0.9,1.7,1.3,2.2,2.2c0.9,0.5,1.2,1.5,2,2c0.8,0.5,1.5,1.2,2,2c0.6,0.4,1.1,0.9,1.6,1.4
	c1,0,2.1,0.2,3.1,0.4c0.8-0.2,1-1.3,1.7-1.6c0.9-0.3,1.2-1.3,2-1.8c0.4-0.2,0.2-0.8,0.5-1.1l1.1,0.2c-0.7,1.1-1.8,1.7-2.6,2.8
	c-0.8,1-1.9,1.7-3.1,2.1h-1.8c-0.9-0.3-1.7-0.8-2.4-1.3c-1.1-1.3-2.5-2.3-3.6-3.7c-0.1-0.2-0.3-0.3-0.5-0.4
	c-1.2-1.3-2.5-2.6-3.8-3.8c-1.1-1.3-2.6-2.4-3.7-3.7c-1.2-1.5-2.9-2.5-4-4.1c-0.3-0.4-0.8-0.6-1.1-1c-2.2-2.1-4.2-4.6-6.6-6.5
	c-1.2-1.5-2.8-2.8-4.2-4.2c-1.3-1.7-3-2.9-4.4-4.4c-0.6-0.5-1-1.2-1.6-1.6c-0.1-0.2-0.3-0.5-0.5-0.6c-1.5-1.2-2.6-2.9-4.2-4.1
	c-0.7-0.6-1.2-1.4-1.5-2.3c0-0.7,0.1-1.4,0-2.1c0.2-0.8,0.6-1.5,1.1-2.2C134.2,107.8,146.8,95.2,159.4,82.6z"
      />

      <path
        fill="#D9C4EB"
        d="M172.7,89.7c0.8-0.7,1.6,1.2,2.3,0.6c0.5,0.4,0.9,0.9,1.4,1.4l0,0.2h-0.2c-0.4-0.4-0.8-0.7-1.2-1
	c-0.8,0-1.1,0.8-1.5,1.3c-0.5,0.7-1.1,1.3-1.8,1.7c-0.8,0.9-1.6,1.8-2.4,2.6c0,0.3,0,0.6-0.1,0.8c-0.4-0.4-0.8-0.8-1.2-1.2l0.1-1
	c-0.1-0.2-0.6-0.2-0.5-0.5c0.6-0.5,1.5-0.8,1.8-1.6c0.2-0.5,0.8-0.6,1.2-1C171.2,91.1,171.9,90.4,172.7,89.7L172.7,89.7z"
      />

      <path
        fill="#D0BCE0"
        d="M173.4,92.2c0.4-0.5,0.8-1.3,1.5-1.3c0.4,0.3,0.9,0.6,1.2,1c-1.1,0.1-1.5,1.1-2.3,1.6c-0.7,0.6-1.3,1.2-1.9,1.9
	c-0.7,0.8-1.8,1.4-2,2.6c-0.3-0.2-0.6-0.5-0.8-0.7c0.1-0.3,0.1-0.6,0.1-0.8c0.9-0.8,1.6-1.7,2.4-2.6C172.4,93.4,173,92.9,173.4,92.2
	L173.4,92.2z"
      />

      <path
        fill="#D7B0F8"
        d="M176.4,91.7c1,0.8,1.8,2,3,2.7l-0.3,0.3c-0.4-0.3-0.7-0.7-1.1-1c-0.1-0.1-0.1-0.1-0.2-0.2
	c-0.2-0.2-0.4-0.4-0.6-0.6l-0.4-0.4l-0.4-0.6L176.4,91.7L176.4,91.7z"
      />

      <path
        fill="#C7B5D7"
        d="M173.9,93.5c0.8-0.5,1.2-1.5,2.3-1.6h0.2l0.4,0.6c-0.3,0.1-0.7,0.2-1,0.3c-1.5,1.8-3.2,3.2-4.7,4.9
	c-0.2,0.3-0.3,0.6-0.4,0.9c-0.2-0.2-0.5-0.4-0.8-0.5V98c0.2-1.2,1.3-1.8,2-2.6C172.5,94.7,173.2,94,173.9,93.5L173.9,93.5z"
      />

      <path fill="#BB85E0" d="M162.4,92.5c0.5-0.4,1.4-0.2,1.8,0C163.6,92.5,163,92.5,162.4,92.5z" />

      <path
        fill="#BFAECD"
        d="M175.8,92.8c0.3-0.1,0.6-0.2,1-0.3l0.4,0.4c-0.5,0.2-1,0.3-1.2,0.9c-1.4,1.8-3.3,3.1-4.8,4.9
	c0,0.2,0,0.4,0.1,0.6c-0.2-0.2-0.4-0.4-0.5-0.6c0.1-0.3,0.2-0.6,0.4-0.9C172.6,96,174.4,94.5,175.8,92.8L175.8,92.8z"
      />

      <path
        fill="#B7A7C5"
        d="M176,93.7c0.2-0.5,0.8-0.7,1.2-0.9c0.2,0.2,0.4,0.4,0.6,0.6c-2,0.8-3,3-4.8,4.2c-0.5,0.6-1.4,1.1-1.3,2
	l-0.4-0.4c0-0.2-0.1-0.4-0.1-0.6C172.7,96.8,174.6,95.5,176,93.7L176,93.7z"
      />

      <path
        fill="#AF9FBC"
        d="M173,97.6c1.8-1.2,2.8-3.3,4.8-4.2c0.1,0.1,0.1,0.1,0.2,0.2c-1,1.1-2.1,2.2-3.1,3.2c-0.9,0.6-1.4,1.7-2.3,2.3
	c-0.3,0.2-0.2,0.6-0.3,1c-0.2-0.2-0.4-0.4-0.6-0.6C171.6,98.7,172.5,98.3,173,97.6L173,97.6z"
      />

      <path
        fill="#A79AB2"
        d="M174.9,96.9c1-1.1,2.1-2.1,3.1-3.2c0.4,0.3,0.7,0.7,1.1,1c-1.9,2.2-4.2,4.2-6.2,6.3c-0.1-0.3-0.3-0.5-0.5-0.8
	c0.1-0.3,0-0.8,0.3-1C173.5,98.5,174,97.5,174.9,96.9L174.9,96.9z"
      />

      <path
        fill="#702EA5"
        d="M170,98.1c0.3,0.1,0.5,0.3,0.8,0.5c0.2,0.2,0.4,0.4,0.5,0.6l0.4,0.4c0.2,0.2,0.4,0.4,0.6,0.6
	c0.2,0.2,0.4,0.5,0.5,0.8c-0.2,0.2-0.4,0.3-0.6,0.4c0-0.2,0.1-0.4,0.1-0.6C171.6,99.9,170.3,99.3,170,98.1z"
      />

      <path
        fill="#B981E5"
        d="M120.2,122.8l0.2-0.2c0,0.7,0,1.4,0,2.1c0-0.1-0.1-0.3-0.2-0.4C120.2,123.9,120.2,123.1,120.2,122.8
	L120.2,122.8z"
      />

      <path
        fill="#A063D0"
        d="M238.2,122.6c0.2,0.6,0.2,1.3,0,1.9C238.2,123.8,238.2,123.2,238.2,122.6z"
      />

      <path
        fill="#8030C6"
        d="M120.2,124.2c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0C120.2,123.1,120.2,123.9,120.2,124.2z"
      />

      <path
        fill="#A569DC"
        d="M152,122.7l0.1,0.1c0,0.6,0,1.1,0,1.7C152,123.9,152,123.3,152,122.7L152,122.7z"
      />

      <path
        fill="#E3CFF2"
        d="M144.4,139.2c0.2-0.5,0.8-0.3,1.3-0.4c0.9,0.5,1.1,1.8,2.2,2c-0.1,0.5-0.1,1.4,0.6,1.3c1-0.1,1.5,0.9,2,1.6
	c0.5,0.2,0.9,0.6,1,1.1c0.1,0.7,1,0.6,1.4,1c0.7,0.9,1.8,1.4,2,2.6c0.1,0.7,0.9-0.1,1.1,0.5c0.3,0.6,0.7,1,1.3,1.4
	c0.7,0.3,0.4,1.4,1.2,1.6c1,0.2,1.2,1.5,2.2,1.9c0.9,0.1,0.5,1.7,1.8,1.3c0.4,0.1,0.8,0.6,1.2,0.4c0.4-0.9,1.5-0.3,2.3-0.4
	c-0.1-0.3-0.5-0.6-0.4-0.9c0.3-0.6,1.6,0.2,1.3-0.6c-0.3-0.6,0.4-0.9,0.9-1.1c-0.1,0.7,0.7,0.9,1.1,1.4c0.8,1.2,1.9,2.2,3.1,2.9
	c0.4,0.3,0.5,0.9,0.7,1.3c0.9-0.1,1.5-0.9,2.2-1.4l0.3,0.2c-1.8,1.5-3.3,3.4-5,4.8l-1.1-0.2c-0.3,0.3-0.1,0.9-0.5,1.1
	c-0.8,0.5-1.1,1.5-2,1.8c-0.7,0.4-0.9,1.4-1.7,1.6c-1-0.2-2.1-0.3-3.1-0.4c-0.5-0.5-1-1-1.6-1.4c-0.5-0.8-1.2-1.5-2-2
	c-0.8-0.5-1.2-1.6-2-2c-0.5-0.9-1.6-1.4-2.2-2.2c0.4-1.2-0.7-0.6-1.1-1.1c-0.2-0.5,0-1.3-1-0.8c-0.4-0.3-0.8-0.7-1.2-1.2
	c0.4-1-0.4-0.9-0.8-1c-0.3-0.3-0.6-0.6-0.9-0.9c-1.1-0.7-2-1.7-2.6-2.8c-0.9-0.4-1.4-1.3-2.2-1.8c-0.4-0.6-0.7-1.2-1-1.9
	c-0.4,0-0.8,0.2-0.9-0.3c-0.6-0.9-2-1.5-2-2.8c1.2-1.3,2.6-2.6,3.8-3.9c0.2,0.1,0.4,0.2,0.6,0.3
	C144.6,139.8,144.5,139.5,144.4,139.2L144.4,139.2z"
      />

      <path
        fill="#68219F"
        d="M172.2,145.6c0.2,0.2,0.4,0.4,0.6,0.6l-0.3,0.4c-0.3,0.3-0.6,0.5-0.8,0.8l-0.2,0.2c-0.2,0.2-0.4,0.4-0.6,0.6
	l-0.4,0.4c-0.2,0.2-0.4,0.4-0.6,0.6s-0.6,0.5-0.8,0.8l-0.2,0.1c0-0.4,0.2-0.7,0.4-1c1-1,2-1.9,2.9-2.9
	C172.2,145.9,172.2,145.7,172.2,145.6L172.2,145.6z"
      />

      <path
        fill="#A195AB"
        d="M172.8,146.2c2.1,2.1,4.2,4.2,6.2,6.2l-0.6,0.5c-0.8-0.5-1.4-1.3-2.1-1.9c-1.3-1-2-2.4-3.3-3.4l-0.5-1.2
	C172.5,146.5,172.8,146.2,172.8,146.2z"
      />

      <path
        fill="#A999B5"
        d="M171.7,147.4c0.3-0.3,0.5-0.6,0.8-0.8l0.5,1.2c1.2,1,2,2.4,3.3,3.4c0.7,0.6,1.3,1.3,2.1,1.9l-0.4,0.4
	c-0.7-0.4-1.2-0.8-1.7-1.4C174.6,150.5,173.4,148.7,171.7,147.4z"
      />

      <path
        fill="#B0A2BC"
        d="M171.5,147.5l0.2-0.2c1.7,1.4,2.9,3.2,4.6,4.6c0.5,0.6,1,1.1,1.7,1.4l-0.6,0.6c-1.7-1.7-3.6-3.3-5.2-5.2
	C171.9,148.4,171.6,148,171.5,147.5z"
      />

      <path
        fill="#B9A8C5"
        d="M170.9,148.1c0.2-0.2,0.4-0.4,0.6-0.6c0.1,0.5,0.3,0.9,0.7,1.2c1.6,1.9,3.5,3.4,5.2,5.2l-0.3,0.4l-0.1,0.1
	c-2.1-1.6-3.8-3.7-5.7-5.5C171.1,148.6,171,148.4,170.9,148.1z"
      />

      <path
        fill="#C1B0CE"
        d="M170.5,148.5l0.4-0.4c0.1,0.2,0.2,0.5,0.4,0.7c2,1.8,3.6,4,5.7,5.5c-0.2,0.2-0.4,0.3-0.6,0.5
	c-1.9-1.8-3.8-3.6-5.6-5.5C170.5,149.2,170.6,148.8,170.5,148.5z"
      />

      <path
        fill="#C9B7D7"
        d="M169.9,149.1c0.2-0.2,0.4-0.4,0.6-0.6c0.1,0.3,0,0.6,0.2,0.8c1.8,1.9,3.7,3.7,5.6,5.5
	c-0.5,0.3-1.1,0.1-1.6,0.2c-1.3-2-3.4-3.4-5-5.2C169.9,149.6,170,149.4,169.9,149.1L169.9,149.1z"
      />

      <path
        fill="#D0BDE0"
        d="M169.1,150c0.3-0.3,0.5-0.6,0.8-0.8c0.1,0.2-0.1,0.4-0.1,0.7c1.6,1.8,3.7,3.2,5,5.2c0.5,0,1.1,0.1,1.6-0.2
	c0.2-0.2,0.4-0.3,0.6-0.5l0.1-0.1l0.1,0.4c-0.4,0.4-0.8,0.8-1.2,1.2l-0.1-0.4c-0.5,0.3-0.9,0.7-1.3,1c-1.1-1.4-2.6-2.3-3.7-3.6
	C170.4,151.9,169,151.3,169.1,150L169.1,150z"
      />

      <path
        fill="#D9C6E9"
        d="M168.9,150.1l0.2-0.1c-0.1,1.3,1.3,1.9,1.8,2.9c1.1,1.4,2.6,2.3,3.7,3.6c0.5-0.3,0.9-0.7,1.4-1l0.1,0.4
	c-0.3,0.4-0.6,0.7-1,1l-0.3-0.2c-0.7,0.5-1.2,1.3-2.2,1.4c-0.2-0.4-0.2-1-0.7-1.3c-1.3-0.7-2.3-1.7-3.1-2.9
	c-0.4-0.5-1.2-0.7-1.1-1.4c0-0.4,0.3-0.8,0-1.1C168.1,150.9,168.5,150.5,168.9,150.1L168.9,150.1z"
      />

      <path
        fill="#CBA2E7"
        d="M178.4,152.9l0.6-0.5l0.2,0.2c-0.7,0.7-1.3,1.4-2,2.1l-0.1-0.4l0.3-0.4l0.6-0.6L178.4,152.9L178.4,152.9z"
      />

      <path fill="#7B38B1" d="M162.4,166.6h1.8C163.7,166.8,163,166.8,162.4,166.6z" />

      <path
        fill="#7B41AC"
        d="M194.1,166.6c0.7,0,1.3,0,2,0l-0.2,0.2h-1.7L194.1,166.6L194.1,166.6z"
      />

      <path fill="#580897" d="M194.3,166.8h1.7C195.5,167.1,194.9,167.1,194.3,166.8L194.3,166.8z" />

      <rect x="80" y="24" fill="none" width="200" height="200" />
    </Icon>
  );
});

export const WhimsicalIconMetadata = {
  name: 'Whimsical Logo',
  importName: 'WhimsicalIcon',
  component: <WhimsicalIcon aria-label="Whimsical Icon" />,
  tags: 'whimsical, logo',
};
