export const RequirementStatuses = [
  'covered_manually_by_appsec',
  'covered_manually_by_developer',
  'covered_by_clover',
  'sent_to_developers',
  'suggested_by_clover',
  'ignored_by_appsec',
  'irrelevant_by_clover',
  'irrelevant_by_appsec',
  'irrelevant_suggested_by_dev_approved_by_appsec',
  'irrelevant_suggested_by_dev',
  'UNKNOWN',
] as const;

export type RequirementStatus = (typeof RequirementStatuses)[number];

export const AppSecAllowedStatuses: RequirementStatus[] = [
  'covered_manually_by_appsec',
  'irrelevant_by_appsec',
  'suggested_by_clover',
];

export const RequirementStatusGroupKeys = [
  'open',
  'sent_to_dev',
  'dev_reviewed',
  'covered',
  'irrelevant',
] as const;

export type RequirementStatusGroupKey = (typeof RequirementStatusGroupKeys)[number];

export const RequirementStatusGroups: Record<RequirementStatusGroupKey, RequirementStatus[]> = {
  open: ['suggested_by_clover'],
  sent_to_dev: ['sent_to_developers'],
  dev_reviewed: ['irrelevant_suggested_by_dev'],
  covered: ['covered_manually_by_appsec', 'covered_manually_by_developer', 'covered_by_clover'],
  irrelevant: [
    'irrelevant_suggested_by_dev_approved_by_appsec',
    'irrelevant_by_clover',
    'irrelevant_by_appsec',
    'ignored_by_appsec',
  ],
};

export const RequirementStatusGroupsEntries = Object.entries(RequirementStatusGroups);

export const isRequirementCovered = (status: RequirementStatus) => {
  return RequirementStatusGroups.covered.includes(status);
};

export const isRequirementIrrelevant = (status: RequirementStatus) => {
  return RequirementStatusGroups.irrelevant.includes(status);
};

export const getRequirementStatusGroupForStatus = (
  status: RequirementStatus
): RequirementStatusGroupKey => {
  const statusGroup = RequirementStatusGroupsEntries.find(([, statuses]) =>
    statuses.includes(status)
  );

  return statusGroup ? (statusGroup[0] as RequirementStatusGroupKey) : 'open';
};
