// App - Types
import type { RequirementCountermeasureSnippetType } from '../requirement-countermeasure-snippet-type';

export const RequirementCountermeasureSnippetTypesDto = [
  'Architecture',
  'Code',
  'Configuration',
  'Documentation',
  'Other',
  'UNKNOWN',
] as const;

export type RequirementCountermeasureSnippetTypeDto =
  (typeof RequirementCountermeasureSnippetTypesDto)[number];

export const castRequirementCountermeasureSnippetTypeDtoToRequirementCountermeasureSnippetType = (
  dto: RequirementCountermeasureSnippetTypeDto
): RequirementCountermeasureSnippetType => {
  if (dto === 'Architecture') return 'architecture';

  if (dto === 'Code') return 'code';

  if (dto === 'Configuration') return 'configuration';

  if (dto === 'Documentation') return 'documentation';

  if (dto === 'Other') return 'other';

  return 'UNKNOWN';
};

export const castRequirementCountermeasureSnippetTypeToRequirementCountermeasureSnippetTypeDto = (
  type: RequirementCountermeasureSnippetType
): RequirementCountermeasureSnippetTypeDto => {
  if (type === 'architecture') return 'Architecture';

  if (type === 'code') return 'Code';

  if (type === 'configuration') return 'Configuration';

  if (type === 'documentation') return 'Documentation';

  if (type === 'other') return 'Other';

  return 'UNKNOWN';
};
