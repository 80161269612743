// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { CreateSecurityTicketFeedback } from '../../types/security-feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  applicationId: z.string(),
  securityStoryId: z.string(),
  securityRequirementIds: z.string().array(),
  securityStoryThreatIds: z.string().array(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateSecurityJiraTicketFeedbackRequestPayloadDto };
export type { RequestPayloadDto as CreateSecurityJiraTicketFeedbackRequestPayloadDto };

// #######
// Request
// #######

export const createSecurityJiraTicketFeedback = async (
  applicationId: string,
  storyId: string,
  ticketPayload: CreateSecurityTicketFeedback
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      applicationId,
      securityStoryId: storyId,
      securityRequirementIds: ticketPayload.requirementIds || [],
      securityStoryThreatIds: ticketPayload.threatIds || [],
    });

    return await apiClient.post(`/Application/CreateJiraIssue`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateSecurityJiraTicketFeedback = {
  applicationId: string;
  storyId: string;
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useCreateSecurityJiraTicketFeedback = ({
  applicationId,
  storyId,
  onStart,
  onSuccess,
  onError,
}: UseCreateSecurityJiraTicketFeedback) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (payload: CreateSecurityTicketFeedback) =>
      await createSecurityJiraTicketFeedback(applicationId, storyId, payload),
    onMutate: () => onStart?.(),
    onSuccess: () => onSuccess?.(),
    onError: (error) => onError?.(error),
  });

  return {
    createSecurityJiraTicket: mutate,
    isCreatingSecurityJiraTicket: isPending,
    didCreateSecurityJiraTicketErrored: isError,
  };
};
