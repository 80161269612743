// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const HitrustIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 192.756 192.756" {...props} ref={ref}>
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          d="M59.26 120.537c5.71 0 9.883-5.051 9.883-10.762V72.218H59.26v35.801c0 .877-.879 1.756-1.757 1.756 0 0-.659 0-.659-.879L26.315 76.392c-2.636-2.416-5.93-4.173-9.225-4.173h-.659c-5.93 0-10.762 5.052-10.762 10.762v37.557h10.762V83.859s0-.878.659-.878h1.757l30.529 33.385c1.538 2.416 4.832 3.295 8.126 4.172h1.758v-.001zM104.725 101.43h31.188c1.758 0 3.295 1.537 3.295 4.174 0 2.416-1.537 4.172-3.295 4.172h-47.88c-1.537 0-2.416-.879-2.416-2.635V72.218h-9.883v34.043c0 7.688 5.71 14.275 13.178 14.275h47.88c7.467 0 13.178-6.588 13.178-14.275v-1.537c0-7.469-6.59-13.398-13.178-13.398h-30.529c-3.295-.22-3.953-2.196-4.174-4.173v-.879c0-1.537 1.758-3.294 4.174-3.294h50.295v37.557h9.885V82.98h20.645V72.218h-82.361c-7.469 0-13.179 6.589-13.179 13.398v2.416c-.002 7.468 5.708 13.398 13.177 13.398z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth=".001"
          strokeMiterlimit="2.613"
        />
      </g>
    </Icon>
  );
});

export const HitrustIconMetadata = {
  name: 'Hitrust Logo',
  importName: 'HitrustIcon',
  component: <HitrustIcon aria-label="HITRUST Logo Icon" />,
  tags: 'hitrust, security, frameworks, industry, standards, compliance, logo, brand, company, org, organization',
};
