// 3rd
import { z } from 'zod';

// App - Types
import {
  KnowledgeSourcesDto,
  castKnowledgeSourceDtoToKnowledgeSource,
} from '@/types/integration/dtos';
import type { Collection } from '../../types/collection';

export const ZodCollectionDto = z.object({
  applications: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
      })
    )
    .optional(),
  id: z.string(),
  collectionId: z.string(),
  collectionTitle: z.string(),
  knowledgeSourceConfigurationId: z.string(),
  knowledgeSourceType: z.enum(KnowledgeSourcesDto),
});

export type CollectionDto = z.infer<typeof ZodCollectionDto>;

export const castCollectionDtoToCollection = (dto: CollectionDto): Collection => {
  return {
    id: dto.id,
    collectionId: dto.collectionId,
    knowledgeSourceConfigurationId: dto.knowledgeSourceConfigurationId,
    source: castKnowledgeSourceDtoToKnowledgeSource(dto.knowledgeSourceType),
    name: dto.collectionTitle,
    applications: dto.applications?.map((app) => ({
      id: app.id,
      name: app.name,
    })),
  };
};
