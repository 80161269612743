// 3rd
import { z } from 'zod';

// App - Types
import type { KeyOf } from '../../keyof';
import type { KnowledgeItemLabels } from '../knowledge-item-labels';

export const KnowledgeItemLabelsConfigurationDto = [
  'AccessControl',
  'AiRelatedFeature',
  'Api',
  'Architecture',
  'BusinessLogic',
  'Cryptography',
  'DataChanges',
  'DesignDecisions',
  'Infrastructure',
  'UserFlows',
  'Pii',
  'SensitiveInformation',
  'ThirdPartyComponent',
  'UNKNOWN',
] as const;

export type KnowledgeItemLabelConfigurationDto =
  (typeof KnowledgeItemLabelsConfigurationDto)[number];

export const castKnowledgeItemLabelConfigurationDtoToKeyOfKnowledgeItemLabels = (
  dto: KnowledgeItemLabelConfigurationDto
): KeyOf<KnowledgeItemLabels> => {
  if (dto === 'AccessControl') return 'accessControl';

  if (dto === 'AiRelatedFeature') return 'aiRelatedFeature';

  if (dto === 'Api') return 'api';

  if (dto === 'Architecture') return 'architecture';

  if (dto === 'BusinessLogic') return 'businessLogic';

  if (dto === 'Cryptography') return 'cryptography';

  if (dto === 'DataChanges') return 'dataChanges';

  // if (dto === 'DesignDecisions') return 'designDecisions';

  if (dto === 'Infrastructure') return 'infrastructure';

  if (dto === 'UserFlows') return 'userFlows';

  if (dto === 'Pii') return 'pii';

  if (dto === 'SensitiveInformation') return 'sensitiveInformation';

  if (dto === 'ThirdPartyComponent') return 'thirdPartyComponent';

  return 'api';
};

export const castKeyOfKnowledgeItemLabelsToKnowledgeItemLabelConfigurationDto = (
  key: KeyOf<KnowledgeItemLabels>
): KnowledgeItemLabelConfigurationDto => {
  if (key === 'accessControl') return 'AccessControl';

  if (key === 'aiRelatedFeature') return 'AiRelatedFeature';

  if (key === 'api') return 'Api';

  if (key === 'architecture') return 'Architecture';

  if (key === 'businessLogic') return 'BusinessLogic';

  if (key === 'cryptography') return 'Cryptography';

  if (key === 'dataChanges') return 'DataChanges';

  // if (key === 'designDecisions') return 'DesignDecisions';

  if (key === 'infrastructure') return 'Infrastructure';

  if (key === 'userFlows') return 'UserFlows';

  if (key === 'pii') return 'Pii';

  if (key === 'sensitiveInformation') return 'SensitiveInformation';

  if (key === 'thirdPartyComponent') return 'ThirdPartyComponent';

  return 'UNKNOWN';
};

export const ZodKnowledgeItemLabelsDto = z.object({
  ['api']: z
    .object({
      external: z.string(),
      specificApis: z
        .array(
          z.object({
            apiName: z.string(),
            changeType: z.string(),
            type: z.string(),
          })
        )
        .default([]),
      changeDetails: z.string(),
    })
    .nullable()
    .optional(),
  ['architecture']: z.string().nullable().optional(),
  ['businessLogic']: z.string().nullable().optional(),
  ['cryptography']: z
    .object({
      changeDetails: z.string(),
      cryptoType: z.string(),
    })
    .nullable()
    .optional(),
  // ['designDecisions']: z.string().nullable().optional(),
  ['infrastructure']: z
    .object({
      changeType: z.string(),
      changeDetails: z.string(),
      isMajorChange: z.boolean(),
      technology: z.string(),
    })
    .nullable()
    .optional(),
  ['pii']: z.string().optional().nullable(),
  ['sensitiveInformation']: z.string().nullable().optional(),
  ['dataChanges']: z
    .object({
      typeOfData: z.string(),
      changeDetails: z.string(),
      typeOfChange: z.string(),
    })
    .nullable()
    .optional(),
  ['accessControl']: z
    .object({
      authorization: z.boolean(),
      authentication: z.boolean(),
      changeDetails: z.string(),
    })
    .nullable()
    .optional(),
  ['userFlows']: z
    .object({
      changeDetails: z.string(),
      flowChangeType: z.string(),
    })
    .nullable()
    .optional(),
  ['thirdPartyComponent']: z
    .object({
      type: z.string(),
      changeDetails: z.string(),
    })
    .nullable()
    .optional(),
  ['aiRelatedFeature']: z
    .object({
      modelProvider: z.string(),
      modelInformation: z.string(),
      changeDetails: z.string(),
    })
    .nullable()
    .optional(),
});

export type KnowledgeItemLabelsDto = z.infer<typeof ZodKnowledgeItemLabelsDto>;

export const castKnowledgeItemLabelsDtoToKnowledgeItemLabels = (
  dto: KnowledgeItemLabelsDto
): KnowledgeItemLabels => {
  return Object.entries(dto).reduce((acc, [_key, value]) => {
    if (!value) return acc;

    const key = _key as KeyOf<KnowledgeItemLabels>;

    switch (key) {
      case 'api':
        const apiData = (value as KnowledgeItemLabelsDto['api'])!;

        acc.api = {
          external: apiData.external,
          changeDetails: apiData.changeDetails,
          specificApis: apiData.specificApis.map((api) => ({
            apiName: api.apiName,
            changeType: api.changeType,
            type: api.type,
          })),
        };

        break;

      case 'infrastructure':
        const infrastructureData = (value as KnowledgeItemLabelsDto['infrastructure'])!;

        acc.infrastructure = {
          changeType: infrastructureData.changeType,
          changeDetails: infrastructureData.changeDetails,
          isMajorChange: infrastructureData.isMajorChange,
          technology: infrastructureData.technology,
        };

        break;

      case 'cryptography':
        const cryptographyData = (value as KnowledgeItemLabelsDto['cryptography'])!;

        acc.cryptography = {
          changeDetails: cryptographyData.changeDetails,
          cryptoType: cryptographyData.cryptoType,
        };

        break;

      case 'dataChanges':
        const dataChangesData = (value as KnowledgeItemLabelsDto['dataChanges'])!;

        acc.dataChanges = {
          typeOfData: dataChangesData.typeOfData,
          changeDetails: dataChangesData.changeDetails,
          typeOfChange: dataChangesData.typeOfChange,
        };

        break;

      case 'accessControl':
        const accessControlData = (value as KnowledgeItemLabelsDto['accessControl'])!;

        acc.accessControl = {
          authorization: accessControlData.authorization,
          authentication: accessControlData.authentication,
          changeDetails: accessControlData.changeDetails,
        };

        break;

      case 'userFlows':
        const userFlowsData = (value as KnowledgeItemLabelsDto['userFlows'])!;

        acc.userFlows = {
          changeDetails: userFlowsData.changeDetails,
          flowChangeType: userFlowsData.flowChangeType,
        };

        break;

      case 'thirdPartyComponent':
        const thirdPartyComponentData = (value as KnowledgeItemLabelsDto['thirdPartyComponent'])!;

        acc.thirdPartyComponent = {
          type: thirdPartyComponentData.type,
          changeDetails: thirdPartyComponentData.changeDetails,
        };

        break;

      case 'aiRelatedFeature':
        const aiRelatedFeatureData = (value as KnowledgeItemLabelsDto['aiRelatedFeature'])!;

        acc.aiRelatedFeature = {
          modelProvider: aiRelatedFeatureData.modelProvider,
          modelInformation: aiRelatedFeatureData.modelInformation,
          changeDetails: aiRelatedFeatureData.changeDetails,
        };

        break;

      default:
        acc[key] = value as string;

        break;
    }

    return acc;
  }, {} as KnowledgeItemLabels);
};
