// App - Types
import type { KnowledgeItemDepthLevel } from '../knowledge-item-depth-level';

export const KnowledgeItemDepthLevelsDto = ['Comprehensive', 'Detailed', 'Basic', 'All'] as const;

export type KnowledgeItemDepthLevelDto = (typeof KnowledgeItemDepthLevelsDto)[number];

export const castKnowledgeItemDepthLevelDtoToKnowledgeItemDepthLevel = (
  dto: KnowledgeItemDepthLevelDto
): KnowledgeItemDepthLevel => {
  if (dto === 'Comprehensive') return 'comprehensive';

  if (dto === 'Detailed') return 'detailed';

  if (dto === 'Basic') return 'basic';

  return 'UNKNOWN';
};

export const castKnowledgeItemDepthLevelToKnowledgeItemDepthLevelDto = (
  depthLevel: KnowledgeItemDepthLevel
): KnowledgeItemDepthLevelDto => {
  if (depthLevel === 'comprehensive') return 'Comprehensive';

  if (depthLevel === 'detailed') return 'Detailed';

  if (depthLevel === 'basic') return 'Basic';

  return 'All';
};

export const castScoreToKnowledgeItemDepthLevelDto = (
  score: number
): KnowledgeItemDepthLevelDto => {
  if (!score || score === -1) return 'All';

  if (score <= 3) return 'Basic';

  if (score <= 6) return 'Detailed';

  return 'Comprehensive';
};

export const castScoreToKnowledgeItemDepthLevel = (score: number): KnowledgeItemDepthLevel => {
  if (!score || score === -1) return 'UNKNOWN';

  if (score <= 3) return 'basic';

  if (score <= 6) return 'detailed';

  return 'comprehensive';
};
