// 3rd
import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// App - Other
import { body } from '@/config/theme/components/text';
import { title } from '@/config/theme/components/heading';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  overlay: {},

  dialogContainer: {},

  dialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: 'md',
    bg: `surface.primary`,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.primary',
    boxShadow: 'elevation-4',
  },

  closeButton: {},

  header: {
    display: 'flex',
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
  },

  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    alignSelf: 'stretch',
  },
});

const mdSize = definePartsStyle({
  overlay: {},

  dialogContainer: {},

  dialog: {
    minW: '260px',
    maxW: '576px',
    maxH: '540px', // TODO check if we need this <=
    py: '2xl',
    px: '2xl',
    gap: 'lg',
  },

  closeButton: {},

  header: {
    p: 'none',
    ...title,
  },

  body: {
    p: 'none',
    ...body,
  },

  footer: {
    p: 'none',
    gap: 'sm',
  },
});

const lgSize = definePartsStyle({
  overlay: {},

  dialogContainer: {},

  dialog: {
    minW: '260px',
    maxW: '672px',
    py: '2xl',
    px: '2xl',
    gap: 'lg',
  },

  closeButton: {},

  header: {
    p: 'none',
    ...title,
  },

  body: {
    p: 'none',
    ...body,
  },

  footer: {
    p: 'none',
    gap: 'sm',
  },
});

const xlSize = definePartsStyle({
  overlay: {},

  dialogContainer: {},

  dialog: {
    minW: '460px',
    maxW: '772px',
    py: '2xl',
    px: '2xl',
    gap: 'lg',
  },

  closeButton: {},

  header: {
    p: 'none',
    ...title,
  },

  body: {
    p: 'none',
    ...body,
  },

  footer: {
    p: 'none',
    gap: 'sm',
  },
});

const twoXLSize = definePartsStyle({
  overlay: {},

  dialogContainer: {},

  dialog: {
    minW: '460px',
    maxW: '922px',
    py: '2xl',
    px: '2xl',
    gap: 'lg',
  },

  closeButton: {},

  header: {
    p: 'none',
    ...title,
  },

  body: {
    p: 'none',
    ...body,
  },

  footer: {
    p: 'none',
    gap: 'sm',
  },
});

const threeXLSize = definePartsStyle({
  overlay: {},

  dialogContainer: {},

  dialog: {
    minW: '460px',
    maxW: '1250px',
    py: '2xl',
    px: '2xl',
    gap: 'lg',
  },

  closeButton: {},

  header: {
    p: 'none',
    ...title,
  },

  body: {
    p: 'none',
    ...body,
  },

  footer: {
    p: 'none',
    gap: 'sm',
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    md: mdSize,
    lg: lgSize,
    xl: xlSize,
    '2xl': twoXLSize,
    '3xl': threeXLSize,
  },
});

export const Modal = modalTheme;
