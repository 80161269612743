// 3rd
import { z } from 'zod';

// App - Types
import {
  SecurityControlImportanceLevelsDto,
  castSecurityControlImportanceLevelDtoToSecurityControlImportanceLevel,
} from '@/types/security-framework/dtos';
import type { SecurityControl } from '../../types/security-control';
import {
  ZodRequirementDto,
  castRequirementDtoToRequirement,
} from '../requirements/dtos/requirement';

export const ZodSecurityControlDto = z.object({
  id: z.string(),
  controlGroup: z.string().optional(),
  controlName: z.string(),
  description: z.string(),
  importanceScore: z.enum(SecurityControlImportanceLevelsDto),
  securityRequirements: z.array(ZodRequirementDto),
});

export type SecurityControlDto = z.infer<typeof ZodSecurityControlDto>;

export const castSecurityControlDtoToSecurityControl = (
  dto: SecurityControlDto,
  frameworkName: string = ''
): SecurityControl => ({
  id: dto.id,
  name: dto.controlName,
  description: dto.description,
  importance: castSecurityControlImportanceLevelDtoToSecurityControlImportanceLevel(
    dto.importanceScore
  ),
  requirements: dto.securityRequirements.map((req) =>
    castRequirementDtoToRequirement(req, frameworkName, dto.controlName)
  ),
});
