// 3rd
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SecurityFramework } from '../types/security-framework';
import {
  ZodSecurityFrameworkDto,
  castSecurityFrameworkDtoToSecurityFramework,
} from './dtos/security-framework';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityFrameworks: z
    .array(ZodSecurityFrameworkDto)
    .default([])
    .catch((e) => {
      console.error('requirements frameworks parsing errored! see:', e);

      return [];
    }),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodGetSecurityFrameworksResponseDto };
export type { ResponseDto as GetSecurityFrameworksResponseDto };

// #######
// Request
// #######

export const getSecurityFrameworks = async (): Promise<SecurityFramework[]> => {
  try {
    const res = await apiClient.get('/SecurityFramework/ListSecurityFrameworks');
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityFrameworks.map(castSecurityFrameworkDtoToSecurityFramework);
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return [];
    }

    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseSecurityFrameworks = {
  enabled?: boolean;
};

export const useSecurityFrameworks = ({ enabled = true }: UseSecurityFrameworks = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
    queryFn: async () => await getSecurityFrameworks(),
    enabled: enabled,
  });

  return {
    securityFrameworks: data,
    isLoadingSecurityFrameworks: isLoading,
    isFetchingSecurityFrameworks: isFetching,
    didFetchSecurityFrameworksErrored: isLoadingError,
  };
};
