// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  securityFrameworkId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCloneSecurityFrameworkRequestPayloadDto };
export type { RequestPayloadDto as CloneSecurityFrameworkRequestPayloadDto };

// #######
// Request
// #######

export const cloneSecurityFramework = async (frameworkId: string): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      securityFrameworkId: frameworkId,
    });

    return await apiClient.post('/SecurityFramework/DuplicateSecurityFramework', payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCloneSecurityFramework = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useCloneSecurityFramework = ({
  onStart,
  onSuccess,
  onError,
}: UseCloneSecurityFramework = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (frameworkId: string) => await cloneSecurityFramework(frameworkId),
    onMutate: () => {
      onStart?.();
    },
    onSuccess: async () => {
      onSuccess?.();
    },
    onError: (error) => {
      onError?.(error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      });
    },
  });

  return {
    cloneSecurityFramework: mutate,
    isCloningSecurityFramework: isPending,
    didCloneSecurityFrameworkErrored: isError,
  };
};
