// 3rd
import { z } from 'zod';

// App - Types
import {
  AllowedSourcesForFootnoteCommentFeedback,
  isAllowedToSendFootnoteCommentFeedbackForSource,
} from '../../../types/feedback';
import type { SendSecurityFootnoteCommentFeedback } from '../../../types/security-feedback';

export const ZodSendSecurityFootnoteCommentFeedbackDto = z.object({
  securityRequirementIds: z.string().array(),
  securityStoryThreatIds: z.string().array(),
});

export type SendSecurityFootnoteCommentFeedbackDto = z.infer<
  typeof ZodSendSecurityFootnoteCommentFeedbackDto
>;

export const castSendSecurityFootnoteCommentFeedbackToSendSecurityFootnoteCommentFeedbackDto = (
  payload: SendSecurityFootnoteCommentFeedback
): SendSecurityFootnoteCommentFeedbackDto => {
  const validThreats = (payload.threats || []).some((t) =>
    isAllowedToSendFootnoteCommentFeedbackForSource(t.relatedToSource)
  );
  const validRequirements = (payload.requirements || []).some((r) =>
    isAllowedToSendFootnoteCommentFeedbackForSource(r.relatedToSource)
  );

  if (!validThreats && !validRequirements)
    throw new Error(
      `Feedback creation is allowed for the following sources => ${AllowedSourcesForFootnoteCommentFeedback.join(', ')}.`
    );

  return {
    securityRequirementIds: (payload.requirements || []).flatMap((req) =>
      isAllowedToSendFootnoteCommentFeedbackForSource(req.relatedToSource) ? [req.id] : []
    ),
    securityStoryThreatIds: (payload.threats || []).flatMap((threat) =>
      isAllowedToSendFootnoteCommentFeedbackForSource(threat.relatedToSource) ? [threat.id] : []
    ),
  };
};
