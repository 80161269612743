// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ExternalLinkIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 12 12" {...props} ref={ref} fill="none">
      <path
        d="M10.5 4.875C10.5 4.97446 10.4605 5.06984 10.3902 5.14016C10.3198 5.21049 10.2245 5.25 10.125 5.25C10.0255 5.25 9.93016 5.21049 9.85983 5.14016C9.78951 5.06984 9.75 4.97446 9.75 4.875V2.78062L6.64078 5.89031C6.57042 5.96068 6.47498 6.00021 6.37547 6.00021C6.27596 6.00021 6.18052 5.96068 6.11016 5.89031C6.03979 5.81995 6.00026 5.72451 6.00026 5.625C6.00026 5.52549 6.03979 5.43005 6.11016 5.35969L9.21937 2.25H7.125C7.02554 2.25 6.93016 2.21049 6.85983 2.14016C6.78951 2.06984 6.75 1.97446 6.75 1.875C6.75 1.77554 6.78951 1.68016 6.85983 1.60984C6.93016 1.53951 7.02554 1.5 7.125 1.5H10.125C10.2245 1.5 10.3198 1.53951 10.3902 1.60984C10.4605 1.68016 10.5 1.77554 10.5 1.875V4.875ZM8.625 6C8.52554 6 8.43016 6.03951 8.35983 6.10983C8.28951 6.18016 8.25 6.27554 8.25 6.375V9.75H2.25V3.75H5.625C5.72446 3.75 5.81984 3.71049 5.89016 3.64016C5.96049 3.56984 6 3.47446 6 3.375C6 3.27554 5.96049 3.18016 5.89016 3.10984C5.81984 3.03951 5.72446 3 5.625 3H2.25C2.05109 3 1.86032 3.07902 1.71967 3.21967C1.57902 3.36032 1.5 3.55109 1.5 3.75V9.75C1.5 9.94891 1.57902 10.1397 1.71967 10.2803C1.86032 10.421 2.05109 10.5 2.25 10.5H8.25C8.44891 10.5 8.63968 10.421 8.78033 10.2803C8.92098 10.1397 9 9.94891 9 9.75V6.375C9 6.27554 8.96049 6.18016 8.89017 6.10983C8.81984 6.03951 8.72446 6 8.625 6Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const ExternalLinkIconMetadata = {
  name: 'External Link',
  importName: 'ExternalLinkIcon',
  component: <ExternalLinkIcon aria-label="External Link Icon" />,
  tags: 'external, link, open, new, tab, navigate, go, site, url',
};
