// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const ServiceNowIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 1570 1403" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        fill="#62d84e"
        d="m1228.4 138.9c129.2 88.9 228.9 214.3 286.3 360.2 57.5 145.8 70 305.5 36 458.5-34 153-112.9 292.4-226.7 400.3-13.3 12.9-28.8 23.4-45.8 30.8-17 7.5-35.2 11.9-53.7 12.9-18.5 1.1-37.1-1.1-54.8-6.6-17.7-5.4-34.3-13.9-49.1-25.2-48.2-35.9-101.8-63.8-158.8-82.6-57.1-18.9-116.7-28.5-176.8-28.5-60.1 0-119.8 9.6-176.8 28.5-57 18.8-110.7 46.7-158.9 82.6-14.6 11.2-31 19.8-48.6 25.3-17.6 5.5-36 7.8-54.4 6.8-18.4-0.9-36.5-5.1-53.4-12.4-16.9-7.3-32.4-17.5-45.8-30.2-114.6-108.3-194.1-248.5-228.1-402.5-34-154-20.9-314.6 37.6-461 58.5-146.5 159.6-272 290.3-360.3 130.7-88.3 284.9-135.4 442.7-135 156.8 1.3 309.6 49.6 438.8 138.4zm-291.8 1014c48.2-19.2 92-48 128.7-84.6 36.7-36.7 65.5-80.4 84.7-128.6 19.2-48.1 28.4-99.7 27-151.5 0-103.9-41.3-203.5-114.8-277-73.5-73.5-173.2-114.8-277.2-114.8-104 0-203.7 41.3-277.2 114.8-73.5 73.5-114.8 173.1-114.8 277-1.4 51.8 7.8 103.4 27 151.5 19.2 48.2 48 91.9 84.7 128.6 36.7 36.6 80.5 65.4 128.6 84.6 48.2 19.2 99.8 28.4 151.7 27 51.8 1.4 103.4-7.8 151.6-27z"
      />
    </Icon>
  );
});

export const ServiceNowIconMetadata = {
  name: 'ServiceNow',
  importName: 'ServiceNowIcon',
  component: <ServiceNowIcon aria-label="ServiceNow Icon" />,
  tags: 'service, now, servicenow, ticket, incident, change, problem, management, it, itil, itsm, service, desk, support, workflow, automation, platform, cloud, saas, software, as, a, service, enterprise, solution, company, org, organization, logo, brand',
};
